import { KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Box } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Button, MaskField } from 'components'
import { WhatsAppWebText } from './whatsapp-form-styles'
import { registerTrack } from 'helpers'

interface IWhatsappFormProps {
  direction: 'row' | 'column'
  message?: string
  registerTrackPayload?: any
}

function WhatsappForm({
  message = '',
  direction,
  registerTrackPayload,
}: IWhatsappFormProps) {
  const { control, handleSubmit } = useForm()

  const onSubmit = (data: any) => {
    registerTrack('Enviar Whatsapp', registerTrackPayload)

    const sanitizePhone = data.telephone.replace(/\D/g, '')

    window.open(
      `https://api.whatsapp.com/send?text=${message}&phone=55'${sanitizePhone}`
    )
  }

  const handleOpenWhatsApp = () => {
    registerTrack('Clica Whatsapp Web', registerTrackPayload)

    window.open(`https://api.whatsapp.com/send?text=${message}`)
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Stack
        spacing={1}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
        direction={direction}
        alignItems='flex-end'
      >
        <MaskField
          mask='(00) 00000-0000'
          name='telephone'
          control={control}
          placeholder='Digite o celular do titular da conta'
        />
        <Button
          color='secondary'
          type='submit'
          sx={{
            height: '42px',
            ...(direction === 'row' && { width: '200px' }),
          }}
        >
          Enviar
        </Button>
      </Stack>
      <Stack direction='row' mt={2}>
        <WhatsAppWebText>
          ou abrir o{' '}
          <u onClick={handleOpenWhatsApp} style={{ cursor: 'pointer' }}>
            WhatsApp web
          </u>
        </WhatsAppWebText>
        <OpenInNewIcon
          onClick={handleOpenWhatsApp}
          sx={{ cursor: 'pointer', color: '#9D9CB5' }}
        />
      </Stack>
    </Box>
  )
}

export default WhatsappForm

import { TextField } from '@mui/material'
import { Controller, Control } from 'react-hook-form'

interface QuestionTextFieldProps {
  name: string
  control: Control<any>
  label?: string
  type?: string
  disabled?: boolean
  placeholder?: string
  invalidChars?: string[]
  callbackBlur?: () => void
}

function QuestionTextField({
  name,
  control,
  label = '',
  type,
  disabled,
  placeholder = 'Digite aqui',
  invalidChars = [],
  callbackBlur = () => {},
}: QuestionTextFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { invalid, error } }) => {
        const { onChange, onBlur } = field
        return (
          <TextField
            {...field}
            onChange={
              Boolean(invalidChars.length)
                ? (value) => {
                    const sanitizedValue = invalidChars.reduce(
                      (acc: string, invalidChar: string) =>
                        acc.replaceAll(invalidChar, ''),
                      value.target.value
                    )
                    return onChange(sanitizedValue)
                  }
                : onChange
            }
            variant='standard'
            color='secondary'
            fullWidth
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            error={invalid}
            helperText={error?.message}
            onBlur={() => {
              callbackBlur()
              onBlur()
            }}
          />
        )
      }}
    />
  )
}

export default QuestionTextField

import { Box, Typography, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)`
  padding: 0 40px;
`

export const TitleText = styled(Typography)`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #353f48;
`

export const FilterTextField = styled(TextField)({
  width: '100%',
  marginBottom: '25px',
  '& fieldset': {
    borderRadius: '4px',
    border: '1px solid #ECECEC',
  },
  '& input::placeholder': {
    color: '#BDBDBD',
  },
})

import { useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import {
  Button
} from 'components'
import { InputField, SelectField } from 'components/inputs'
import { useSignUpClinicContext } from 'contexts'
import { generateOptions } from 'helpers'
import { professionalCouncils } from 'helpers/constants'
import { BackButton } from './sign-up-clinic-steps-styles'

function PersonalInfoStep(): JSX.Element {
  const { control, watch } = useFormContext()
  const { goBackStep } = useSignUpClinicContext()

  const professionalCouncilsOptions = useMemo(
    () => generateOptions(professionalCouncils),
    []
  )

  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const cpf = watch('cpf')
  const birthdate = watch('birthdate')
  const professionalCouncil = watch('professionalCouncil')
  const register = watch('register')

  const canProceed = () => {
    return !firstName || !lastName || !cpf || !birthdate || !professionalCouncil || !register
  }

  return (
    <>
      <Grid item xs={12} mb={2}>
        <InputField name='firstName' control={control} label='Nome' />
      </Grid>
      <Grid item xs={12} mb={2}>
        <InputField name='lastName' control={control} label='Sobrenome' />
      </Grid>
      <Grid item xs={12} md={5} mb={2}>
        <InputField
          mask='000.000.000-00'
          name='cpf'
          control={control}
          label='CPF'
        />
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={5} mb={2}>
        <InputField
          mask='00/00/0000'
          name='birthdate'
          control={control}
          label='Data de Nascimento'
        />
      </Grid>
      <Grid item xs={12} md={5} mb={2}>
        <SelectField
          label='Conselho profissional'
          name='professionalCouncil'
          control={control}
          options={professionalCouncilsOptions}
        />
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={5} mb={7}>
        <InputField
          name='register'
          control={control}
          label='Número de inscrição'
        />
      </Grid>
      <Box my={2} display='flex' flexGrow={1}>
        <BackButton onClick={goBackStep}>Voltar</BackButton>
      </Box>
      <Button
        type='submit'
        disabled={canProceed()}
        style={{ width: '50%' }}
      >
        Cadastrar-se
      </Button>
    </>
  )
}

export default PersonalInfoStep

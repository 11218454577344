export const defaultMenuButtonStyle = {
  height: '59px',
  textTransform: 'none',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '144%',
  boxShadow: 'none',
  background: '#F4F5F6',
  color: '#657787',
  textAlign: 'left',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#F4F5F6',
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: '#d6d6d6',
  },
} as const

import { useState, useEffect, KeyboardEvent } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { SubmitHandler, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { LogoLayout, SignInHeader, StaticLink, Modal, TermsOfUse } from 'components'
import { signUpClinicSteps } from 'components/sign-up-clinic-steps'
import { SignUpClinicContext, useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { registerPageAccess, toDate } from 'helpers'
import { ISignUpClinicForm } from './sign-up-clinic-types'
import useFormSignUpClinic from './sign-up-clinic-form'

function SignUpClinic(): JSX.Element {
  const [step, setStep] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [showModalAction, setShowModalAction] = useState(false)
  const [agreedTerms, setAgreedTerms] = useState(false)

  const navigate = useNavigate()
  const { signup } = useAuthContext()
  const { errorToast, successToast } = useNotification()
  const methods = useFormSignUpClinic()

  const { handleSubmit, trigger } = methods

  useEffect(() => {
    registerPageAccess('Signup clinic')
  }, [])

  const onSubmit: SubmitHandler<ISignUpClinicForm> = async (params) => {
    try {
      const body = {
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        email: params.email.trim().toLowerCase(),
        password: params.password,
        birthdate: format(toDate(params.birthdate), 'yyyy-MM-dd'),
        role: 'clinic',
        clinicAdditionalInfos: {
          register: params.register.trim(),
          professionalCouncil: params.professionalCouncil,
          state: params.state,
          telephone: params.telephone.replace(/\D/g, ''),
          cpf: params.cpf.replace(/\D/g, ''),
        },
      }

      await signup(body)

      successToast('Cadastro realizado com sucesso')
      navigate('/')
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    goNextStep()
  }

  const goBackStep = () => {
    if (step > 0) setStep((step) => step - 1)
  }

  const goNextStep = async () => {
    const valid = await trigger(signUpClinicSteps[step].fields)
    if (!valid) return
    if (step < signUpClinicSteps.length - 1) return setStep((step) => step + 1)
    if (step === signUpClinicSteps.length - 1) {
      if (!agreedTerms) {
        setShowModalAction(true)
        return setModalOpen(true)
      }
      handleSubmit(onSubmit)()
    }
  }

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const modalAction = {
    name: 'Concordo',
    func: () => {
      setAgreedTerms(true)
      onCloseModal()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <LogoLayout>
      <>
        <Modal
          title='Termos de Uso'
          isOpen={modalOpen}
          handleClose={onCloseModal}
          action={showModalAction ? modalAction : void 0}
          component={<TermsOfUse />}
        />
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            alignItems: { md: 'end' },
            width: { xs: '100%', md: '50%' },
            padding: { xs: '15%', md: '50px 10%' }
          }}
        >
          <SignInHeader />
          <Box
            component='form'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            flexGrow={1}
            width='100%'
            onSubmit={(e: any) => {
              e.preventDefault()
              goNextStep()
            }}
            onKeyDown={checkKeyDown}
            sx={{
              padding: {
                xs: '20% 0',
                md: '0'
              }
            }}
          >
            <Box mb={3}>
              <Typography variant='h3' color={'#657787'}>
                {signUpClinicSteps[step].title}
              </Typography>
              <Typography style={{
                color: '#657787',
                fontSize: '16px',
                margin: '25px 0'
              }}>
                {signUpClinicSteps[step].text}
              </Typography>
            </Box>
            <SignUpClinicContext.Provider value={{ goNextStep, goBackStep }}>
              <FormProvider {...methods}>
                <Grid container width='100%'>
                  {signUpClinicSteps[step].component}
                </Grid>
              </FormProvider>
            </SignUpClinicContext.Provider>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            alignItems='center'
          >
            <StaticLink to='/' onClick={e => {
              e.preventDefault()
              setShowModalAction(false)
              setModalOpen(true)
            }}>
              Termos de uso
            </StaticLink>
          </Box>
        </Box>
      </>
    </LogoLayout>
  )
}

export default SignUpClinic

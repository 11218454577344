import { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { format } from 'date-fns'
import { Box } from '@mui/material'
import { ClinicLayout, PatientCentralLayout } from 'components'
import {
  ResultsHeader,
  TagsContainer,
  TagDetails,
  QuestionBox,
} from 'components/results'
import { useAuthContext, ResultsContext } from 'contexts'
import {
  fetchPatient,
  getPatientResponders,
  getPatientResponses,
  getInformantResponses,
  getTags,
  getPatientAttentions,
} from 'services'
import { correctsTagsInRespondersWithResponses } from 'helpers'
import { FETCHING, IDLE } from 'helpers/constants'
import {
  generateResponsesTransv,
  generateResponsesLong,
  generateQuestionsAndAnswers,
} from './results-helpers'

function Results(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patient, setPatient] = useState<any>()
  const [responderOptions, setResponderOptions] = useState<any>([])
  const [selectedResponders, setSelectedResponders] = useState<any>([])
  const [tagsResults, setTagsResults] = useState<any>([])
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [tagDetailInfos, setTagDetailInfos] = useState<any>({})
  const [respondersWithResponses, setRespondersWithResponses] = useState<any>(
    []
  )
  const [timelineVisibleRange, setTimelineVisibleRange] = useState<number[]>([
    0, 7,
  ])
  const [visibleArea, setVisibleArea] = useState<number>(7)
  const [patientAttentions, setPatientAttentions] = useState<any>([])
  const [filterQuestionIds, setFilterQuestionIds] = useState<any>([])
  const [sankeyHighlight, setSankeyHighlight] = useState<any>()
  const [activeProportion, setActiveProportion] = useState<boolean>(false)

  const { user } = useAuthContext()
  const { patient_id } = useParams()

  const { state } = useLocation()
  const { notification }: any = state || {}

  const methods = useForm({
    defaultValues: { tag: 'results', type: 'transv' },
  })
  const { watch } = methods

  const selectedTag = watch('tag')
  const selectedType = watch('type')

  const trackUsersInfosPayload = useMemo(
    () => ({
      clinic_id: user.clinicId,
      patient_id: patient_id,
    }),
    [user, patient_id]
  )

  const fetchPatientAttentions = useCallback(async () => {
    try {
      if (!patient_id) return

      const patientAttentions = await getPatientAttentions(patient_id)
      setPatientAttentions(patientAttentions)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [patient_id])

  useEffect(() => {
    fetchPatientAttentions()
  }, [fetchPatientAttentions, patient_id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [patient, responders, databaseTags] = await Promise.all([
          fetchPatient(patient_id as string),
          getPatientResponders(patient_id as string),
          getTags(),
        ])

        const formattedResponders = responders.map((responder: any) => {
          return {
            id: responder.id,
            isPatient: responder.isPatient,
            name: responder.fullName,
            relation:
              !responder.relation?.name || responder.relation?.name === 'Sou eu'
                ? 'paciente'
                : responder.relation?.name?.toLowerCase(),
          }
        })

        const responsesArray = await Promise.all(
          formattedResponders.map(({ id, isPatient }: any) =>
            isPatient
              ? getPatientResponses(id)
              : getInformantResponses(patient_id as string, id)
          )
        )

        const respondersWithResponses = formattedResponders.map(
          (selectedResponder: any, index: number) => {
            const orderedResponseDates = responsesArray[index].sort(
              (respA: any, respB: any) =>
                (new Date(respB.formDateUpdate) as any) -
                (new Date(respA.formDateUpdate) as any)
            )

            const uniqueResponseDates = [
              ...new Set(
                orderedResponseDates.map((response: any) =>
                  format(new Date(response.formDateUpdate), 'dd/MM/yyyy')
                )
              ),
            ]

            return {
              ...selectedResponder,
              responses: orderedResponseDates,
              responseDates: uniqueResponseDates,
            }
          }
        )

        const correctRespondersWithResponses =
          correctsTagsInRespondersWithResponses(
            respondersWithResponses,
            databaseTags
          )

        const responderOptions = correctRespondersWithResponses.map(
          (respondersWithResponse: any) => {
            const firstName = respondersWithResponse?.name.split(' ')[0]

            return {
              id: respondersWithResponse.id,
              label: `${firstName} (${respondersWithResponse.relation})`,
              responseDates: respondersWithResponse.responseDates,
            }
          }
        )

        setPatient(patient)
        setRespondersWithResponses(correctRespondersWithResponses)
        setResponderOptions(responderOptions)

        // selecionar o paciente/caregiver a partir da notificação
        if (notification) {
          const idToFind =
            notification.payload?.informantId || notification.payload?.patientId
          const findnotificationResponder = responderOptions.find(
            ({ id }: any) => id === idToFind
          )
          if (findnotificationResponder) {
            return setSelectedResponders([
              {
                ...findnotificationResponder,
                responseDate: [
                  format(new Date(notification.createdAt), 'dd/MM/yyyy') ||
                    findnotificationResponder?.responseDates[0],
                ],
              },
            ])
          }
        }

        // selecionar o paciente/caregiver e sua resposta mais recente
        const findPatientResponder = responderOptions.find(
          ({ id }: any) => id === patient.patientId
        )

        if (findPatientResponder) {
          setSelectedResponders([
            {
              id: findPatientResponder.id,
              label: findPatientResponder.label,
              responseDate: findPatientResponder?.responseDates[0]
                ? [findPatientResponder?.responseDates[0]]
                : [],
            },
          ])
        }
      } catch (error: any) {
        console.error(error.message)
      } finally {
        setStatus(IDLE)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, notification])

  useEffect(() => {
    if (!selectedTag) return

    setShowDetail(selectedTag !== 'results')
  }, [selectedTag])

  useEffect(() => {
    const handleResize = () => {
      const visibleArea = Math.ceil((window.innerWidth - 760) / 200)
      setVisibleArea(visibleArea < 1 ? 1 : visibleArea)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const { timeline } = tagDetailInfos

    if (!timeline) return

    setTimelineVisibleRange(
      timeline.length > visibleArea
        ? [timeline.length - visibleArea, timeline.length]
        : [0, visibleArea]
    )
  }, [tagDetailInfos, visibleArea, setTimelineVisibleRange])

  useEffect(() => {
    // * call generateTagsWithResponses
    const responses =
      selectedType === 'transv'
        ? generateResponsesTransv(selectedResponders, respondersWithResponses)
        : generateResponsesLong(
            selectedResponders[0] || [],
            respondersWithResponses
          )

    const responseTags = responses.reduce((acc: any, { response }: any) => {
      const uniqueTags = response.tags.filter(
        (tag: any) => !acc.some((accTag: any) => accTag.id === tag.id)
      )
      return [...acc, ...uniqueTags]
    }, [])

    const tagsWithResponses = responseTags.map((responseTag: any) => {
      return {
        id: responseTag.id,
        name: responseTag.tag,
        responses: responses.reduce(
          (acc: any, { response, responder }: any) => {
            if (!response.tags.some(({ id }: any) => id === responseTag.id)) {
              return acc
            }

            const filteredQuestions = response.questions.filter(
              ({ tags }: any) => tags.includes(responseTag.tag)
            )

            // Remover caso queira remover os gráficos dos responders que não tem resposta naquela tag
            if (!Boolean(filteredQuestions.length)) return acc

            const quantities = filteredQuestions.reduce(
              (acc: any, { answer }: any) => ({
                ...acc,
                [answer]: (acc[answer] || 0) + 1,
              }),
              {}
            )

            return [
              ...acc,
              {
                responder,
                responseDate: response.formDateUpdate,
                formDateCreate: response.formDateCreate,
                formDateUpdate: response.formDateUpdate,
                formRequestId: response.formRequestId,
                hasResponses: Boolean(filteredQuestions.length),
                quantities: quantities,
                questions: filteredQuestions,
              },
            ]
          },
          []
        ),
      }
    })

    setTagsResults(
      tagsWithResponses
        .filter(({ responses }: any) => responses.length > 0)
        .slice()
        .sort(({ name: nameA }: any, { name: nameB }: any) => {
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResponders])

  useEffect(() => {
    const findTagResults = tagsResults.find(
      (tagResults: any) => tagResults.id === selectedTag
    )

    const questionsWithAnswers = generateQuestionsAndAnswers(
      findTagResults?.responses
    )

    setTagDetailInfos({
      timeline: findTagResults?.responses,
      tag: { id: findTagResults?.id, name: findTagResults?.name },
      questions: questionsWithAnswers,
    })

    setFilterQuestionIds([])
  }, [tagsResults, selectedTag])

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <FormProvider {...methods}>
        <ResultsContext.Provider
          value={{
            trackUsersInfosPayload,
            patient,
            responderOptions,
            selectedResponders,
            setSelectedResponders,
            tagsResults,
            setTagsResults,
            tagDetailInfos,
            timelineVisibleRange,
            setTimelineVisibleRange,
            showDetail,
            patientAttentions,
            fetchPatientAttentions,
            setFilterQuestionIds,
            sankeyHighlight,
            setSankeyHighlight,
            activeProportion,
            setActiveProportion,
          }}
        >
          <PatientCentralLayout
            parentPatientAttentions={patientAttentions}
            refetchPatientAttentions={fetchPatientAttentions}
            sidebarSize={showDetail ? 'small' : 'normal'}
          >
            <ResultsHeader />
            {showDetail ? <TagDetails /> : <TagsContainer />}
          </PatientCentralLayout>
          {showDetail && (
            <Box width='100%' flexGrow={999}>
              <QuestionBox
                timeline={
                  tagDetailInfos?.timeline?.slice(...timelineVisibleRange) || []
                }
                questions={
                  filterQuestionIds.length === 0
                    ? tagDetailInfos?.questions || []
                    : (tagDetailInfos?.questions || []).filter(
                        (question: any) =>
                          filterQuestionIds.includes(question.id)
                      )
                }
              />
            </Box>
          )}
        </ResultsContext.Provider>
      </FormProvider>
    </ClinicLayout>
  )
}

export default Results

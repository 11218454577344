import EmailStep from './email-step'
import BirthdateStep from './birthdate-step'
import PronounStep from './pronoun-step'
import PasswordStep from './password-step'
import RelationStep from './relation-step'
import EnvironmentStep from './environment-step'
import FullNameStep from './full-name-step'
import { PatientStepType, InformantStepType } from 'types'
import CaregiverFullNameStep from './caregiver-full-name-step'

export const patientSteps: PatientStepType[] = [
  { component: <EmailStep />, fields: ['email'], hasNextButton: true },
  {
    component: <FullNameStep />,
    fields: ['firstName', 'lastName'],
    hasNextButton: true,
  },
  { component: <BirthdateStep />, fields: ['birthdate'], hasNextButton: true },
  { component: <PronounStep />, fields: ['pronoun'], hasNextButton: false },
  {
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
  },
]

export const caregiverSteps: PatientStepType[] = [
  {
    component: <FullNameStep />,
    fields: ['firstName', 'lastName'],
    hasNextButton: true,
  },
  { component: <PronounStep />, fields: ['pronoun'], hasNextButton: false },
  { component: <BirthdateStep />, fields: ['birthdate'], hasNextButton: true },
  { component: <EmailStep />, fields: ['email'], hasNextButton: true },
  {
    component: <CaregiverFullNameStep />,
    fields: ['caregiverFirstName', 'caregiverLastName'],
    hasNextButton: true,
  },
  {
    component: <RelationStep type='caregiver' />,
    fields: ['relation'],
    hasNextButton: true,
  },
  {
    component: <EnvironmentStep type='caregiver' />,
    fields: ['environment'],
    hasNextButton: true,
  },
  {
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
  },
]

export const informantSteps: InformantStepType[] = [
  {
    title: 'email-step',
    component: <EmailStep />,
    fields: ['email'],
    hasNextButton: true,
  },
  {
    title: 'fullname-step',
    component: <FullNameStep />,
    fields: ['firstName', 'lastName'],
    hasNextButton: true,
  },
  {
    title: 'relation-step',
    component: <RelationStep type='informant' />,
    fields: ['relation'],
    hasNextButton: true,
  },
  {
    title: 'environment-step',
    component: <EnvironmentStep type='informant' />,
    fields: ['environment'],
    hasNextButton: true,
  },
  {
    title: 'password-step',
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
  },
]

import { format } from 'date-fns'

export function generateResponsesTransv(
  responders: any,
  respondersWithResponses: any
) {
  return responders.map(({ id: responderId, responseDate }: any) => {
    const responderInfos = respondersWithResponses.find(
      (respondersWithResponse: any) => respondersWithResponse.id === responderId
    )

    const response = responderInfos.responses.find((response: any) =>
      responseDate.includes(
        format(new Date(response.formDateUpdate), 'dd/MM/yyyy')
      )
    )

    return {
      response,
      responder: {
        id: responderInfos.id,
        isPatient: responderInfos.isPatient,
        name: responderInfos.name,
        relation: responderInfos.relation,
      },
    }
  })
}

export function generateResponsesLong(
  responder: any,
  respondersWithResponses: any
) {
  if (!responder) return []

  const responderWithResponses = respondersWithResponses.find(
    (responderWithResponses: any) => {
      return responder.id === responderWithResponses.id
    }
  )

  const responses = responder?.responseDate.map((date: string) => {
    const foundResponse = responderWithResponses.responses.find(
      (response: any) => {
        return date === format(new Date(response.formDateUpdate), 'dd/MM/yyyy')
      }
    )

    return {
      response: foundResponse,
      responder: {
        id: responderWithResponses.id,
        isPatient: responderWithResponses.isPatient,
        name: responderWithResponses.name,
        relation: responderWithResponses.relation,
      },
    }
  })

  return responses
}

export function generateQuestionsAndAnswers(timeline: any) {
  if (!timeline) return []

  const uniqueQuestions = timeline.reduce((acc: any, timelineResponse: any) => {
    const responseUniqueQuestions = timelineResponse.questions.filter(
      (question: any) =>
        !acc.some((accQuestion: any) => accQuestion.id === question.id)
    )
    return [...acc, ...responseUniqueQuestions]
  }, [])

  const questionsWithAnswers: any = uniqueQuestions.map(
    ({ answer, ...uniqueQuestion }: any) => {
      return {
        ...uniqueQuestion,
        answers: timeline.map(({ questions: timelineQuestions }: any) => {
          const findAnswer = timelineQuestions.find((timelineQuestion: any) => {
            return timelineQuestion.id === uniqueQuestion.id
          })
          return findAnswer ? findAnswer.answer : 'na'
        }),
        emphasis: false,
      }
    }
  )

  return questionsWithAnswers
}

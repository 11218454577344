import { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { QuestionTextField } from 'components'
import { firstNameQuestion, lastNameQuestion } from './questions'
import { useSignUpPatientContext } from 'contexts'
import { registerPageAccess, registerTrack } from 'helpers'

function FullNameStep(): JSX.Element {
  const { control } = useFormContext()
  const { patient, patientName, type } = useSignUpPatientContext()

  useEffect(() => {
    registerPageAccess('Nome Paciente', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlur = () => {
    registerTrack('Altera Nome', {
      patient_name: patientName,
      source: type,
    })
  }

  return (
    <>
      <Typography variant='question'>
        {firstNameQuestion[patient || 'he']}
      </Typography>
      <Box mt={2} mb={10}>
        <QuestionTextField
          name='firstName'
          control={control}
          callbackBlur={handleBlur}
        />
      </Box>
      <Typography variant='question'>
        {lastNameQuestion[patient || 'he']}
      </Typography>
      <Box mt={2}>
        <QuestionTextField
          name='lastName'
          control={control}
          callbackBlur={handleBlur}
        />
      </Box>
    </>
  )
}

export default FullNameStep

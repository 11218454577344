import { styled } from '@mui/material/styles'

interface IBallProp {
  bgcolor: string
  answer: string
  emphasis?: boolean
}

const Ball = styled('div')<IBallProp>(
  ({ bgcolor, answer, emphasis = true }: any) => ({
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: answer === 'yes' ? bgcolor : '#fff',
    backgroundImage:
      answer === 'soso'
        ? `conic-gradient(white 0deg, white 180deg, ${bgcolor} 180deg)`
        : 'none',
    border: `2px solid ${bgcolor}`,
    opacity: emphasis ? 1 : 0.5,
  })
)

export default Ball

const devFrequencyOptions = [
  {
    value: 'everyFiveMinutes',
    label: '[DEV] a cada cinco minutos',
  },
  { value: 'hourly', label: '[DEV] de hora em hora' },
]

export const frequencyOptions = [
  ...(process.env.REACT_APP_ENV === 'development' ? devFrequencyOptions : []),
  { value: 'once', label: 'uma única vez' },
  { value: 'daily', label: 'diariamente' },
  { value: 'weekly', label: 'semanalmente' },
  { value: 'biweekly', label: 'quinzenalmente' },
  { value: 'monthly', label: 'mensalmente' },
  { value: 'everyThreeMonths', label: 'a cada 3 meses' },
]

export const frequencyTextDict: { [key: string]: string } = {
  everyFiveMinutes: 'a cada cinco minutos',
  hourly: 'a cada hora',
  daily: 'diária',
  weekly: 'semanal',
  biweekly: 'quinzenal',
  monthly: 'mensal',
  everyThreeMonths: 'a cada 3 meses',
}

export const frequencyDictOrder: any = {
  everyFiveMinutes: 1,
  hourly: 2,
  daily: 3,
  weekly: 4,
  biweekly: 5,
  monthly: 6,
  everyThreeMonths: 7,
}

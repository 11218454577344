import { Box, Container } from '@mui/material'

interface Colors {
  [key: string]: string
}

const bgColors: Colors = {
  white: '#FFFFFF',
  green: '#06A599',
  orange: '#EE7262',
  default: '#06A599',
}

function RouteLayout({ bgColor, children }: any): JSX.Element {
  return (
    <Box
      height={window.innerHeight}
      display='flex'
      sx={{
        backgroundColor: bgColors[bgColor] || bgColors.default,
      }}
    >
      <Container
        maxWidth='xs'
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </Container>
    </Box>
  )
}

export default RouteLayout

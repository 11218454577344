import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const ClinicContainer = styled(Stack)({
  minHeight: '100vh',
  backgroundColor: '#BDC1DD',
  minWidth: '995px',
})

export const ContentContainer = styled(Stack)({
  boxShadow: '0px 6px 34px rgba(115, 122, 182, 0.8)',
  borderRadius: '10px 10px 0px 0px',
  background: '#FFFFFF',
})

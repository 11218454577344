import { useState, useMemo, useEffect, KeyboardEvent } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Stack,
  CircularProgress,
  AccordionDetails,
  Typography,
} from '@mui/material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { Button, QuestionnaireGroupsContainer, Select } from 'components'
import {
  createUserQuestionnaires,
  getUserGroups,
  configFormRequestEnvironments,
} from 'services'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { useNotification, useQuery, useMount } from 'hooks'
import { newQuestionnaireGroupsAndFrequencyStepResolver } from 'resolvers'
import { IEnvironmentOption } from 'types'
import {
  capitalizeWordsOnSentence,
  registerPageAccess,
  registerTrack,
} from 'helpers'
import { frequencyOptions } from 'helpers/constants'
import {
  Divider,
  Subtitle,
  PeriodTitle,
  QuestionText,
  Accordion,
  AccordionSummary,
} from './styles/choose-groups-and-frequency-step'
import { ExpandMore } from '@mui/icons-material'
import { PageTitle } from './styles/steps-styles'

const createSelectedGroupTags = (
  tags: any,
  environmentOptions: IEnvironmentOption[]
) => {
  const preparedTags = tags.map((tag: any) => tag.label)

  return environmentOptions.reduce(
    (acc: any, option: IEnvironmentOption) => ({
      ...acc,
      [option.value]: preparedTags.slice(),
    }),
    {}
  )
}

function ChooseGroupsAndFrequencyStep(): JSX.Element {
  const [infos, setInfos] = useState<any>({ counter: 0, tags: [] })
  const [loading, setLoading] = useState<boolean>(true)
  const [environmentOptions, setEnvironmentOptions] = useState<
    IEnvironmentOption[]
  >([])

  const methods = useForm<any>({
    defaultValues: {
      selectedGroupTags: {},
      frequency: 'once',
    },
    resolver: newQuestionnaireGroupsAndFrequencyStepResolver,
  })

  const { control, handleSubmit, setValue, watch } = methods

  const {
    goNextStep,
    setInvitationToken,
    newQuestionnaireInfos,
    setNewQuestionnaireInfos,
    tags,
  } = useInvitePatientContext()
  const { selectedQuestions, selectedTags } = newQuestionnaireInfos

  const query = useQuery()
  const { errorToast } = useNotification()
  const { user } = useAuthContext()
  const { isMounted } = useMount()

  const patientName = useMemo(
    () => newQuestionnaireInfos.patientName,
    [newQuestionnaireInfos.patientName]
  )
  const firstName = patientName.split(' ')[0] || ''

  const period = watch('period')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedTagsWithCorrectCounter: any = selectedTags.map(
          (selectedTag: any) => {
            const tagQuestions = selectedQuestions.filter((question: any) =>
              question.tags.includes(selectedTag.value)
            )
            const tagTotal = tagQuestions.length
            return {
              ...selectedTag,
              total: tagTotal,
              questions: tagQuestions,
              label: `${selectedTag.name} (${tagTotal})`,
            }
          }
        )

        setInfos({
          counter: selectedQuestions.length,
          tags: selectedTagsWithCorrectCounter,
        })

        const userGroups = await getUserGroups()

        const environmentOptions = userGroups.map(({ id, name }: any) => ({
          label: name,
          value: id,
        }))

        setValue(
          'selectedGroupTags',
          createSelectedGroupTags(
            selectedTagsWithCorrectCounter,
            environmentOptions
          )
        )

        setEnvironmentOptions(environmentOptions)
      } catch (error: any) {
        console.error(error)
        errorToast('Ocorreu um erro ao buscar as informações de grupo.')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    registerPageAccess('Configurar Envio', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isMounted) return

    registerTrack('Altera Recorrencia', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  const onSubmit = async (data: any) => {
    try {
      const userEmail = query.get('email')
      const { selectedTags } = newQuestionnaireInfos

      const body = {
        name: newQuestionnaireInfos.patientName,
        questions: newQuestionnaireInfos.selectedQuestions.map(
          ({ id }: any) => id
        ),
        sendTo: userEmail,
        frequency: data.frequency,
        startDate: new Date(),
        tags: selectedTags.map((selectedTag: any) => {
          const findTagApi = tags.find(
            (tag: any) => tag.name === selectedTag.name
          )
          return findTagApi.id
        }),
      }

      const formData = {
        userGroups: Object.entries(data.selectedGroupTags)
          .map(([groupId, selectedTagLabels]: any) => ({
            id: groupId,
            tags: selectedTagLabels.map((selectedTagLabel: any) => {
              const findTagLocal = infos.tags.find(
                (tag: any) => tag.label === selectedTagLabel
              )
              const findTagApi = tags.find(
                (tag: any) => tag.name === findTagLocal.name
              )
              return findTagApi.id
            }),
          }))
          .filter(({ tags }: any) => tags.length),
      }

      const { formRequestId, invitationToken } = await createUserQuestionnaires(
        body
      )

      if (!userEmail) {
        setInvitationToken(invitationToken)
        setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
          ...newQuestionnaireInfos,
          invitationToken,
        }))
      }

      await configFormRequestEnvironments(formRequestId, {
        userGroups: formData.userGroups,
      })

      goNextStep()
    } catch (error: any) {
      console.error(error)
      errorToast(error.message || 'Erro desconhecido.')
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  if (!Boolean(infos.counter)) return <></>

  return (
    <Box
      component='form'
      onKeyDown={checkKeyDown}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems='space-between'
          justifyContent='space-between'
          height='100%'
          mb={5}
        >
          {loading ? (
            <Box
              flexGrow={1}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress size={80} />
            </Box>
          ) : (
            <>
              {/*
               *
               * Frequency
               *
               */}
              <Box py={3} px={5} width={{ xs: '100%', md: '55%' }}>
                <PageTitle>Programar recorrência</PageTitle>
                <Stack direction='row' spacing={1} mt={1}>
                  <CalendarTodayOutlinedIcon
                    fontSize='small'
                    sx={{ fill: '#353F48' }}
                  />
                  <Subtitle>
                    Você pode agendar envios periódicos automáticos.
                  </Subtitle>
                </Stack>

                <Box my={3} mx={4} display='flex' alignItems='center'>
                  <PeriodTitle>
                    Enviar {infos.counter}{' '}
                    {infos.counter < 2 ? 'pergunta' : 'perguntas'} para{' '}
                    {firstName}
                  </PeriodTitle>
                  <Box width='200px' ml={1}>
                    <Select
                      name='frequency'
                      control={control}
                      options={frequencyOptions}
                    />
                  </Box>
                </Box>
                <Box my={5} mx={4}>
                  <Accordion disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      sx={{
                        p: 0,
                        justifyContent: 'start',
                        '.MuiAccordionSummary-content': {
                          flexGrow: 0,
                          mr: 1,
                        },
                        svg: {
                          transform: 'rotate(270deg)',
                        },
                        '.Mui-expanded svg': {
                          transform: 'rotate(180deg)',
                        },
                      }}
                    >
                      Configurações opcionais
                    </AccordionSummary>
                    <AccordionDetails>
                      <Subtitle>
                        Pessoas que convivem com {firstName} em diferentes
                        ambientes podem trazer informações relevantes. O
                        paciente poderá convidar outras pessoas para responder
                        às perguntas. Você pode escolher quais domínios estarão
                        disponíveis de acordo com o ambiente em que eles
                        convivem.
                      </Subtitle>

                      {/*
                       *
                       * Environmnets
                       *
                       */}
                      <QuestionnaireGroupsContainer
                        tags={infos.tags}
                        selectedQuestions={selectedQuestions}
                        environmentOptions={environmentOptions}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Divider orientation='vertical' sx={{ height: '70vh' }} />

              {/*
               *
               * Questions
               *
               */}
              <Box
                px={5}
                width={{ xs: '100%', md: '45%' }}
                display='flex'
                flexDirection='column'
                height='100%'
              >
                <Box height='70vh' overflow='auto' p={3}>
                  <PageTitle>Perguntas selecionadas</PageTitle>
                  {infos?.tags.map((tag: any) => {
                    return (
                      <Accordion disableGutters key={tag.value}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          sx={{
                            p: 0,
                            justifyContent: 'start',
                            '.MuiAccordionSummary-content': {
                              flexGrow: 0,
                              mr: 1,
                            },
                            svg: {
                              transform: 'rotate(270deg)',
                            },
                            '.Mui-expanded svg': {
                              transform: 'rotate(180deg)',
                            },
                          }}
                        >
                          {capitalizeWordsOnSentence(tag.label)}
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, pb: 2 }}>
                          {tag.questions.map((question: any) => (
                            <Box
                              key={question.id}
                              px={3}
                              py={'4px'}
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              borderLeft={'2px solid #F7F7F7'}
                            >
                              <QuestionText flexGrow={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Zen Kaku Gothic Antique',
                                  }}
                                >
                                  {question.questionSelfM}
                                </Typography>
                              </QuestionText>
                            </Box>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box
          display='flex'
          justifyContent='end'
          position={'fixed'}
          right={100}
          bottom={30}
        >
          <Box width='250px' height='46px'>
            <Button type='submit'>Confirmar seleção</Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  )
}

export default ChooseGroupsAndFrequencyStep

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const Infos = styled(Typography)({
  color: '#657787',
  fontSize: '14px',
  marginBottom: '8px',
  lineHeight: '20px',
})

export const TagName = styled(Typography)<any>(({ color }: any) => {
  return {
    color: color || '#657787',
    fontWeight: '600',
    fontSize: '14px',
  }
})

export const Emphasis = styled(Typography)({
  fontWeight: '600',
  fontSize: '14px',
})

export const Title = styled(Typography)({
  color: '#657787',
  fontSize: '14px',
  marginBottom: '8px',
  lineHeight: '20px',
  fontWeight: 'bold',
})

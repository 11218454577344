import { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { QuestionTextField } from 'components'
import { useSignUpPatientContext } from 'contexts'
import { emailQuestion } from './questions'
import { LabelText } from './sign-up-styles'
import { registerPageAccess } from 'helpers'

function EmailStep(): JSX.Element {
  const { control } = useFormContext()
  const { patient, patientName, type } = useSignUpPatientContext()

  useEffect(() => {
    registerPageAccess('Email Paciente', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {patient !== 'you' && <LabelText>Sobre você</LabelText>}
      <Typography variant='question'>{emailQuestion[patient]}</Typography>
      <Box mt={2}>
        <QuestionTextField
          name='email'
          control={control}
          invalidChars={[' ']}
        />
      </Box>
    </>
  )
}

export default EmailStep

import AccessStep from './access-step'
import PersonalInfoStep from './personal-info-step'
import { ClinicStepType } from 'types'

export const signUpClinicSteps: ClinicStepType[] = [
  {
    title: 'Crie sua conta',
    text: 'Se você é profissional de saúde e deseja utilizar o Wida, preencha o cadastro abaixo.',
    component: <AccessStep />,
    fields: ['email', 'password', 'confirmPassword', 'telephone', 'state']
  },
  {
    title: 'Crie sua conta',
    text: 'Agora, precisamos só de mais alguns dados.',
    component: <PersonalInfoStep />,
    fields: [
      'firstName',
      'lastName',
      'cpf',
      'birthdate',
      'professionalCouncil',
      'register',
    ],
  },
]

import { Box, Divider } from '@mui/material'
import { Header, MenuButton, RouteLayout } from 'components'
import { useAuthContext } from 'contexts'
import { Title, Subtitle } from './questionnaire-skipped-category-styles'

function QuestionnaireSkippedCategory({
  skippedQuestions,
  handleYes,
  handleNo,
}: any): JSX.Element {
  const { user } = useAuthContext()

  return (
    <RouteLayout bgColor='orange'>
      <Header hasProfileButton disabledGoHome />
      <Box flexGrow={1} margin={5}>
        <Box my={2}>
          <Title>
            Você pulou {skippedQuestions.length}{' '}
            {skippedQuestions.length < 2 ? 'pergunta' : 'perguntas'}.
          </Title>
        </Box>

        <Box my={2}>
          <Subtitle>Deseja voltar pra responder?</Subtitle>
        </Box>

        <Divider color='white' />
        <Box mt={5}>
          <MenuButton onClick={handleYes}>Sim, responder perguntas</MenuButton>
        </Box>
        <Box mt={5}>
          <MenuButton onClick={handleNo}>
            Não,{' '}
            {user?.personalInfos?.pronoun?.toUpperCase() === 'SHE'
              ? 'obrigada'
              : 'obrigado'}
          </MenuButton>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default QuestionnaireSkippedCategory

import { Stack } from '@mui/material'
import LegendBall from './legend-ball'

function AnswerInfos({ infos }: any): JSX.Element {
  return (
    <Stack direction='row' spacing={1} alignItems={'center'}>
      <LegendBall answer={infos.answer} />
      <p>{infos.ultraShortVersion?.toLowerCase() || ''}</p>
    </Stack>
  )
}

export default AnswerInfos

import { useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
} from '@mui/material'
import {
  PatientsTableActions,
  CaregiverTooltip,
  FilterTableHeader,
} from 'components'
import { frequencyTextDict, frequencyDictOrder } from 'helpers/constants'
import { capitalizeWordsOnSentence } from 'helpers/words'
import { PatientTableRow } from './patient-list-styles'
import brLocale from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

type Order = 'asc' | 'desc'

interface Data {
  firstName: string
  age: string
  lastResponseDate: string
  frequencyName: string
}

interface HeadCell {
  id: string
  label: string
  order?: boolean
}

const headerFields: HeadCell[] = [
  { id: 'firstName', label: 'Nome', order: true },
  { id: 'age', label: 'Idade', order: true },
  { id: 'lastResponseDate', label: 'Últimos Resultados', order: true },
  { id: 'frequencyName', label: 'Recorrência', order: true },
  { id: 'actions', label: 'Ações' },
]

function descendingComparator(a: any, b: any, orderBy: any) {
  if ('age' === orderBy) {
    // for numbers
    return Number(a[orderBy]) - Number(b[orderBy])
  } else if ('frequencyName' === orderBy) {
    // for frequency
    const aFrequency =
      frequencyDictOrder[a.formRequestFrequency?.frequencyName] || 9
    const bFrequency =
      frequencyDictOrder[b.formRequestFrequency?.frequencyName] || 9

    return aFrequency - bFrequency
  } else if ('lastResponseDate' === orderBy) {
    // for dates
    const aDate = new Date(a[orderBy] || 0)
    const bDate = new Date(b[orderBy] || 0)
    if (bDate < aDate) return -1
    if (bDate > aDate) return 1
    return 0
  } else {
    // for strings
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
  }
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function PatientList({ patients, fetchPatients, onFilter }: any): JSX.Element {
  const [orderBy, setOrderBy] = useState<keyof Data>('firstName')
  const [order, setOrder] = useState<Order>('asc')
  const navigate = useNavigate()

  const handleShowProfile = (patientId: string) => {
    navigate(`/patients/${patientId}/profile`)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      flex={1}
    >
      <TableContainer
        component={Paper}
        sx={{ marginTop: '16px', boxShadow: 'none' }}
      >
        <Table aria-label='tabela de pacientes'>
          <FilterTableHeader
            headerFields={headerFields}
            onFilter={onFilter}
            orderBy={orderBy}
            order={order}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
          />
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ border: 'none', height: '40px' }} />
            </TableRow>
            {patients
              .sort(getComparator(order, orderBy))
              .map((patient: any) => {
                return (
                  <PatientTableRow
                    onClick={() => handleShowProfile(patient.patientId)}
                    key={patient.email}
                  >
                    <TableCell width={500}>
                      <Box display='flex' alignItems='center'>
                        <Avatar sx={{ marginRight: '8px' }}>
                          {patient.firstName[0] || 'W'}
                        </Avatar>
                        <span>
                          <b>{patient.firstName}</b>
                          {` ${patient.lastName}`}
                        </span>
                        {patient.isTutor ? (
                          <CaregiverTooltip caregiver={patient.caregiver} />
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{patient.age}</TableCell>
                    <TableCell>
                      {patient.lastResponseDate
                        ? format(
                            new Date(patient.lastResponseDate),
                            'dd MMM yy',
                            {
                              locale: brLocale,
                            }
                          )
                        : ' - '}
                    </TableCell>
                    <TableCell>
                      {capitalizeWordsOnSentence(
                        frequencyTextDict[
                          patient.formRequestFrequency?.frequencyName
                        ] || ''
                      )}
                    </TableCell>
                    <TableCell
                      width={200}
                      align='center'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PatientsTableActions
                        patientName={`${patient.firstName} ${patient.lastName}`.trim()}
                        lastFormId={patient.lastFormId}
                        patientEmail={patient.email}
                        patientId={patient.patientId}
                        caregiverName={
                          patient.caregiver
                            ? `${patient.caregiver.firstName} ${patient.caregiver.lastName}`.trim()
                            : ''
                        }
                        formRequestFrequency={patient.formRequestFrequency}
                        fetchPatients={fetchPatients}
                      />
                    </TableCell>
                  </PatientTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PatientList

import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { Group } from '@mui/icons-material';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#657787',
    width: '500px',
    boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.08)'
  },
  '& p': {
    fontFamily: "'DM Sans', sans-serif",
    fontSize: '14px',
  }
}));



export const GroupIcon = styled(Group)({
  marginLeft: '30px',
  padding: '3px',
  borderRadius: '4px',
  color: '#657787',
  backgroundColor: '#F9F9FB'
})

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '19px',
  lineHeight: '28px',
  letterSpacing: '0.04em',
})

export const PatientName = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '24px',
  letterSpacing: '0.04em',
})

export const PatientAge = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.04em',
})

import { useMemo } from 'react'
import { Stack, Tooltip, Box } from '@mui/material'
import { Graphic } from './proportion-graphic-styles'

interface IProportionGraphicProps {
  barColor: string
  questions: any[]
  totalTagQuestions: number
}

interface IAnswerOption {
  value: string
  label: string
}

const answerOptions: IAnswerOption[] = [
  { value: 'yes', label: 'Presente' },
  { value: 'soso', label: 'Sublimiar' },
  { value: 'no', label: 'Ausente' },
]

function createInitialAnswerObj(answerOptions: IAnswerOption[]) {
  return answerOptions.reduce(
    (acc: any, answerOption: IAnswerOption) => ({
      quantities: { ...acc.quantities, [answerOption.value]: 0 },
      proportions: { ...acc.proportions, [answerOption.value]: 0 },
    }),
    { quantities: {}, proportions: {} }
  )
}

function ProportionGraphic({
  barColor,
  questions,
  totalTagQuestions,
}: IProportionGraphicProps) {
  const answersInfos = useMemo(() => {
    const infos = questions.reduce(
      (acc: any, question: any) => {
        return {
          ...acc,
          quantities: {
            ...acc.quantities,
            [question.answer]: acc.quantities[question.answer] + 1,
          },
        }
      },
      { total: totalTagQuestions, ...createInitialAnswerObj(answerOptions) }
    )

    answerOptions.forEach((answerOption: IAnswerOption) => {
      infos.proportions[answerOption.value] = Math.round(
        (infos.quantities[answerOption.value] / infos.total) * 100
      )
    })

    return infos
  }, [questions, totalTagQuestions])

  const totalFormQuestions = useMemo(
    () =>
      Object.values(answersInfos.quantities).reduce(
        (acc: number, qtd: any) => acc + Number(qtd),
        0
      ),
    [answersInfos]
  )

  return (
    <Box width='100%' display='flex' justifyContent='flex-start'>
      <Tooltip
        title={
          <Stack spacing={1}>
            {answerOptions.map((answerOption: IAnswerOption) => (
              <span key={answerOption.label}>
                {answerOption.label}:{' '}
                {answersInfos.quantities[answerOption.value] || 0}
              </span>
            ))}
            {answersInfos.total !== totalFormQuestions && (
              <span>
                Sem informação: {answersInfos.total - totalFormQuestions}
              </span>
            )}
          </Stack>
        }
      >
        <Graphic color={barColor} answersInfos={answersInfos} />
      </Tooltip>
    </Box>
  )
}

export default ProportionGraphic

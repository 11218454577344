import client from 'services/client'
import {
  SignInBody,
  SignUpPatientBody,
  SignUpCaregiverBody,
  ISignUpClinicBody,
} from 'types/services'

export const signIn = async (body: SignInBody) => {
  try {
    const { data }: any = await client.post('/auth/signin', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const signUp = async (
  body: SignUpPatientBody | SignUpCaregiverBody | ISignUpClinicBody
) => {
  try {
    const { data }: any = await client.post('/auth/signup', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const checkAuth = async () => {
  try {
    await client.get('/auth/me')
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const requestResetPassword = async (body: any) => {
  try {
    await client.post('/auth/forgot-password', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const resetPassword = async (token: string, body: any) => {
  try {
    await client.patch(`/auth/forgot-password/${token}`, body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

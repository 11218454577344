import { useForm } from 'react-hook-form'
import { signUpInformantResolver } from 'resolvers'

function useSignUpInformantForm() {
  return useForm({
    mode: 'onBlur',
    resolver: signUpInformantResolver,
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      environment: '',
      relation: '',
      password: '',
      confirmPassword: '',
    },
  })
}

export default useSignUpInformantForm

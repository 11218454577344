import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { QuestionButtonAnswerType } from 'types'
import optionsMap from './question-button-options'

interface QuestionButtonProps {
  answerType: QuestionButtonAnswerType
  selected: boolean
  handleSelectClick: Function
  disabled: boolean
}

function QuestionButton({
  answerType,
  selected,
  handleSelectClick,
  disabled,
}: QuestionButtonProps) {
  const option = useMemo(() => optionsMap[answerType], [answerType])
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <IconButton
        sx={{
          height: '53px',
          width: '53px',
          border: selected
            ? `3px solid ${option.bgColor}`
            : '3px solid #9D9CB5',
          backgroundColor: selected ? option.bgColor : 'transparent',
        }}
        onClick={() => {
          if (!disabled) handleSelectClick(answerType)
        }}
        disableRipple
      >
        {option.component}
      </IconButton>
      <Box mt={2}>
        <Typography variant='h5' sx={{ width: '53px', textAlign: 'center' }}>
          {option.label}
        </Typography>
      </Box>
    </Box>
  )
}

export default QuestionButton

import { useForm } from 'react-hook-form'
import { signUpPatientResolver, signUpCaregiverResolver } from 'resolvers'
import { Roles } from 'types'

const signUpRoleForm = {
  patient: {
    resolver: signUpPatientResolver,
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      birthdate: '',
      pronoun: '',
      password: '',
      confirmPassword: '',
    },
  },
  caregiver: {
    resolver: signUpCaregiverResolver,
    defaultValues: {
      firstName: '',
      lastName: '',
      pronoun: '',
      birthdate: '',
      email: '',
      caregiverFirstName: '',
      caregiverLastName: '',
      relation: '',
      environment: '',
      password: '',
      confirmPassword: '',
    },
  },
}

function useSignUpPatientForm(type: Extract<Roles, 'patient' | 'caregiver'>) {
  return useForm({
    mode: 'onBlur',
    ...signUpRoleForm[type],
  })
}

export default useSignUpPatientForm

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const UserName = styled(Typography)({
  color: 'white',
  fontWeight: 'normal',
  fontSize: '24px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
})

export const Text = styled(Typography)({
  color: 'white',
  fontWeight: '300',
  fontSize: '22px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
})

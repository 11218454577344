import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import {
  AnswersTimelineDots,
  AnswersTimelineGradient,
} from 'components/results'
import { useResultsContext } from 'contexts'
import { createPatientAttention, deletePatientAttention } from 'services'
import {
  QuestionBoxContainer,
  QuestionLine,
  QuestionText,
  DividerLine,
  BookmarkIconStyled,
  BookmarkBorderIconStyled,
} from './question-box-styles'

interface IQuestionBox {
  questions: any
  timeline: any
}

function QuestionBox({ questions, timeline }: IQuestionBox): JSX.Element {
  const {
    fetchPatientAttentions,
    patientAttentions,
    selectedResponders,
    patient,
  } = useResultsContext()

  const hasPatientSelected = selectedResponders.some(
    (selectedResponder: any) => selectedResponder.id === patient.patientId
  )

  const [highlightQuestion, setHighlightQuestion] = useState<any>(null)

  const { patient_id } = useParams()

  const handleEmphasisQuestion = (question: any) => {
    return () => {
      setHighlightQuestion(
        highlightQuestion === question.id ? null : question.id
      )
    }
  }

  const handleCheckAttention = (question: any) => async (e: any) => {
    try {
      e.stopPropagation()
      await createPatientAttention({
        patientId: patient_id,
        questionId: question.id,
      })

      await fetchPatientAttentions()
    } catch (error) {
      console.error(error)
    }
  }

  const handleUncheckAttention = (question: any) => async (e: any) => {
    try {
      e.stopPropagation()
      const patientAttention = patientAttentions.find(
        (patientAttention: any) =>
          question.id === patientAttention?.question?.id
      )
      await deletePatientAttention(patientAttention.id)

      await fetchPatientAttentions()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <QuestionBoxContainer>
      {questions.map((question: any) => {
        let highlightStatus = 'normal'
        if (highlightQuestion) {
          highlightStatus =
            question.id === highlightQuestion ? 'highlight' : 'opaque'
        }

        const hasAttention = patientAttentions.some(
          (patientAttention: any) =>
            question.id === patientAttention?.question?.id
        )

        return (
          <Fragment key={question.id}>
            <QuestionLine
              direction='row'
              highlight={highlightStatus}
              onClick={handleEmphasisQuestion(question)}
            >
              {hasPatientSelected ? (
                hasAttention ? (
                  <BookmarkIconStyled
                    onClick={handleUncheckAttention(question)}
                  />
                ) : (
                  <BookmarkBorderIconStyled
                    onClick={handleCheckAttention(question)}
                  />
                )
              ) : (
                <Box width='28px' />
              )}
              <Box width='310px' mr={'116px'}>
                <QuestionText>{question.ultraShortVersion}</QuestionText>
              </Box>
              {question.id === highlightQuestion ? (
                <AnswersTimelineGradient question={question} />
              ) : (
                <AnswersTimelineDots
                  question={question}
                  timelineDates={timeline.map(
                    ({ formDateUpdate }: any) => formDateUpdate
                  )}
                />
              )}
            </QuestionLine>

            {question.id !== highlightQuestion && (
              <DividerLine sx={{ opacity: 0.5 }} />
            )}
          </Fragment>
        )
      })}
    </QuestionBoxContainer>
  )
}

export default QuestionBox

import { styled } from '@mui/material/styles'

export const DividerLine = styled('div')<any>(({ isLongType }) => ({
  borderTop: '1px solid #858585',
  position: 'absolute',
  top: 160,
  width: isLongType ? '100%' : '78px',
  zIndex: 2,
}))

export const DateLabel = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#525252',
})

export const ResponderName = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#525252',
})

export const ResponderRelation = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#8C8C8C',
})

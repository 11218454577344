import { useFormContext } from 'react-hook-form'
import { Graphic, Sankey } from 'components/results'
import { Stack, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { generateQuestionQuantities } from 'helpers'
import { tagsSizes } from 'helpers/constants'
import {
  DividerLine,
  DateLabel,
  ResponderName,
  ResponderRelation,
} from './tag-timeline-styles'
import { useResultsContext } from 'contexts'

function TagTimeline({
  timeline,
  tag,
  mappedResponses,
  handleSelectSankey,
}: any): JSX.Element {
  const { activeProportion } = useResultsContext()
  const { watch } = useFormContext()
  const selectedType = watch('type')

  const isLongType: boolean = selectedType === 'long'

  return (
    <Container>
      {isLongType && <DividerLine isLongType={isLongType} />}
      <Stack direction='row' justifyContent='center'>
        {timeline?.map((timelineResponse: any, index: number) => {
          const responseDate = format(
            new Date(timelineResponse.formDateUpdate),
            'dd MMM yy',
            { locale: brLocale }
          )

          const quantities = generateQuestionQuantities(
            timelineResponse.questions
          )

          const firstName = timelineResponse.responder.name.split(' ')[0] || ''
          const relation = timelineResponse.responder.relation

          const hasSquareBorder = mappedResponses
            .filter(({ active }: any) => active)
            .some(
              ({ origin, destination }: any) =>
                timelineResponse.formRequestId === origin.formRequestId ||
                timelineResponse.formRequestId === destination.formRequestId
            )

          return (
            <Stack
              alignItems='center'
              key={timelineResponse.formRequestId}
              minWidth='200px'
              width='200px'
              maxWidth='200px'
            >
              <Graphic
                quantities={quantities}
                maxTagQuestions={tagsSizes[tag.name]}
                onClick={
                  isLongType ? handleSelectSankey(timelineResponse) : undefined
                }
                hasSquareBorder={hasSquareBorder}
                lastGraph={index === timeline.length - 1}
                isProportional={activeProportion}
              />
              <Stack alignItems='center' mt={1}>
                {!isLongType && (
                  <>
                    <ResponderName>{firstName}</ResponderName>
                    <ResponderRelation>({relation})</ResponderRelation>
                  </>
                )}
                <Box mt={0.5}>
                  <DateLabel>{responseDate}</DateLabel>
                </Box>
              </Stack>
              {!isLongType && <DividerLine isLongType={isLongType} />}
            </Stack>
          )
        })}
      </Stack>
      {isLongType && (
        <Sankey
          responses={mappedResponses}
          maxTagQuestions={tagsSizes[tag.name]}
        />
      )}
    </Container>
  )
}

const Container = styled(Box)({
  position: 'relative',
})

export default TagTimeline

import { useState, useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { Button, LogoLayout, SignUpHeader } from 'components'
import { InputField } from 'components/inputs'
import { useNotification } from 'hooks'
import { requestResetPassword } from 'services'
import { registerPageAccess } from 'helpers'
import useFormPasswordReset from './password-reset-form'
import { IFormInput } from './password-reset-types'
import { SuccessText } from './password-reset-styles'

function PasswordReset(): JSX.Element {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { control, handleSubmit } = useFormPasswordReset()
  const { errorToast } = useNotification()

  useEffect(() => {
    registerPageAccess('Recuperacao de Senha')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        email: params.email,
      }

      await requestResetPassword(body)

      setShowSuccessMessage(true)
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        sx={{
          alignItems: { md: 'end' },
          width: { xs: '100%', md: '50%' },
          padding: { xs: '15%', md: '50px 10%' }
        }}
      >
        <SignUpHeader />
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          flexGrow={1}
          width='100%'
        >
          <Typography variant='h3' color={'#657787'}>
            Redefinir a senha
          </Typography>
          {!showSuccessMessage ? (
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box mt={3} mb={7}>
                <InputField
                  name='email'
                  control={control}
                  label='E-mail'
                  invalidChars={[' ']}
                />
              </Box>
              <Button type='submit' style={{ width: '50%' }}>Enviar</Button>
            </Box>
          ) : (
            <Box>
              <Box mt={3} mb={5}>
                <SuccessText>
                  Enviamos um e-mail de redefinição. Cheque sua caixa de entrada ou de spam.
                </SuccessText>
              </Box>
              <Button href='/signin' style={{ width: '50%' }}>Voltar ao login</Button>
            </Box>
          )}
        </Box>
      </Box>
    </LogoLayout>
  )
}

export default PasswordReset

import { useState } from 'react'
import { Box } from '@mui/material'
import { ConfirmationModal, MedicalRecord } from 'components'
import { RichTextEditor } from 'components/inputs'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import { AddCircleOutlined, CloseOutlined } from '@mui/icons-material'

function RecordTimeline({
  onSaveRecord,
  onDeleteRecord,
  records,
  draft,
  setDraft,
}: any): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [textOpen, setTextOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const saveRecord = async (data: any, close: boolean = false) => {
    if (close) {
      setLoading(true)
      await onSaveRecord(data, close)
      setTextOpen(false)
      return setLoading(false)
    }
    await onSaveRecord(data)
  }

  const confirmCloseModal = async () => {
    setLoading(true)
    setTextOpen(false)
    setModalOpen(false)
    if (draft) {
      await onDeleteRecord(draft.id)
      setDraft(null)
    }
    setLoading(false)
  }

  return (
    <Timeline
      sx={{
        paddingBottom: '30px',
        '& li:before': {
          content: 'none',
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator sx={{ marginLeft: '30px' }}>
          <Box display={'flex'}>
            <TimelineDot
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                margin: '5px 1px 10px 1px',
              }}
            >
              {textOpen || draft ? (
                <CloseOutlined
                  onClick={() => setModalOpen(true)}
                  sx={{
                    fontSize: '30px',
                    color: '#F36F60',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <AddCircleOutlined
                  onClick={() => setTextOpen(true)}
                  sx={{
                    fontSize: '30px',
                    color: '#F36F60',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </TimelineDot>
          </Box>
          <TimelineConnector
            sx={{ backgroundColor: '#ECECEC', float: 'right' }}
          />
        </TimelineSeparator>
        <TimelineContent>
          {textOpen || draft ? (
            <RichTextEditor
              saveRecord={saveRecord}
              draft={draft}
              loading={loading}
            />
          ) : (
            <Box mb={2} mt={1}>
              Adicionar anotação
            </Box>
          )}
        </TimelineContent>
      </TimelineItem>
      {records
        ?.filter((record: any) => !record.isDraft)
        .map((record: any) => (
          <MedicalRecord key={record.id} record={record} />
        ))}

      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          infos={{
            title: 'Cancelar registro',
            message: 'Você perderá suas informações. Continuar?',
            confirmText: 'Sim, cancelar',
            cancelText: 'Não, manter',
          }}
          onClose={() => setModalOpen(false)}
          onConfirm={confirmCloseModal}
        />
      )}
    </Timeline>
  )
}
export default RecordTimeline

import { Stepper, Step, StepLabel } from '@mui/material'
import StepIcon from './step-icon'
import { StepperContext } from 'contexts'
interface StepsProps {
  activeStep: number
  totalSteps: number
}

function Steps({ activeStep, totalSteps }: StepsProps) {
  const calculatedWidth = 150 / (totalSteps || 1)
  const width = Math.ceil(calculatedWidth)
  return (
    <StepperContext.Provider value={{ totalSteps }}>
      <Stepper
        activeStep={activeStep}
        connector={null}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}
      >
        {Array.from({ length: totalSteps }, (_, i) => i).map((index) => (
          <Step
            key={index}
            sx={{
              padding: 0,
              marginRight: index === totalSteps - 1 ? 0 : '1px',
              width: `${width}px`,
            }}
          >
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </StepperContext.Provider>
  )
}

export default Steps

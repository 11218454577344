import { Box } from '@mui/material'
import { QuestionButton } from 'components'
import { QuestionButtonAnswerType } from 'types'

const answerOptions = ['no', 'so-so', 'yes']

interface QuestionAnswersProps {
  initialAnswer: string
  handleChooseAnswer: (arg0: string) => void
  loadingAnswer: boolean
}

function QuestionAnswers({
  initialAnswer = '',
  handleChooseAnswer,
  loadingAnswer,
}: QuestionAnswersProps) {
  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      {answerOptions.map((answerType) => (
        <QuestionButton
          key={answerType}
          answerType={answerType as QuestionButtonAnswerType}
          selected={answerType === initialAnswer}
          handleSelectClick={handleChooseAnswer}
          disabled={loadingAnswer}
        />
      ))}
    </Box>
  )
}
export default QuestionAnswers

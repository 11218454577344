import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Box } from '@mui/material'
import { CloseOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material'
import { TitleText, Container, Section } from './clinic-profile-tabs-styles'
import { IconLink, Button } from 'components'
import { InputField, SelectField } from 'components/inputs'
import { generateOptions } from 'helpers'
import { professionalCouncils } from 'helpers/constants'

function AdditionalInfoTab({ resetForm }: any): JSX.Element {
  const [isEdit, setIsEdit] = useState(false)
  const { control } = useFormContext()

  const professionalCouncilsOptions = useMemo(
    () => generateOptions(professionalCouncils),
    []
  )

  return (
    <Container>
      <Section>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='40%'
        >
          <TitleText>Dados adicionais</TitleText>
          <IconLink
            startIcon={isEdit ? <CloseOutlined /> : <EditOutlined />}
            onClick={() => {
              resetForm()
              setIsEdit(!isEdit)
            }}
            style={{ marginTop: '0' }}
          >
            {isEdit ? 'Cancelar' : 'Editar'}
          </IconLink>
        </Box>
        <Grid container width='40%'>
          <Grid item xs={12} md={5} mb={2}>
            <InputField
              disabled
              mask='000.000.000-00'
              name='cpf'
              control={control}
              label='CPF'
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5} mb={2}>
            <InputField
              disabled={!isEdit}
              mask='00/00/0000'
              name='birthdate'
              control={control}
              label='Data de Nascimento'
            />
          </Grid>
          <Grid item xs={12} md={5} mb={2}>
            <SelectField
              disabled={!isEdit}
              label='Conselho profissional'
              name='professionalCouncil'
              control={control}
              options={professionalCouncilsOptions}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5} mb={7}>
            <InputField
              disabled={!isEdit}
              name='register'
              control={control}
              label='Número de inscrição'
            />
          </Grid>
        </Grid>
        {isEdit && (
          <Button
            type='submit'
            sx={{ width: '200px', marginTop: '30px' }}
            startIcon={<SaveOutlined />}
          >
            Salvar
          </Button>
        )}
      </Section>
    </Container>
  )
}

export default AdditionalInfoTab

import { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import { DateField } from 'components'
import { birthdateQuestion } from './questions'
import { useSignUpPatientContext } from 'contexts'
import { registerPageAccess } from 'helpers'

function BirthdateStep(): JSX.Element {
  const { control, watch } = useFormContext()
  const { patient, patientName, type } = useSignUpPatientContext()

  const firstName = watch('firstName')

  const question = (birthdateQuestion[patient || 'he'] as any).replace(
    '[nome_do_paciente]',
    firstName
  )

  useEffect(() => {
    registerPageAccess('Nascimento Paciente', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography variant='question'>
        <ReactMarkdown>{question}</ReactMarkdown>
      </Typography>
      <Box mt={2}>
        <DateField name='birthdate' control={control} />
      </Box>
    </>
  )
}

export default BirthdateStep

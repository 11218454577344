import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)<any>(({ isDragging }) => {
  return {
    minWidth: '280px',
    height: '477px',
    margin: '8px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: isDragging ? '#FAFAFA' : 'white',
    borderRadius: '16px',
    border: '0.5px solid #ECECEC',
  }
})

export const InfosContainer = styled(Box)({
  padding: '32px 24px 0px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const DetailButton = styled(ButtonBase)({
  width: '100%',
  height: '32px',
  backgroundColor: '#D9D9D9',
  borderRadius: '0px 0px 16px 16px',
  fontFamily: 'DM Sans',
  fontSize: '20px',
  fontWeight: '700',
})

export const DividerLineTransv = styled('div')<any>({
  borderTop: '1px solid #858585',
  width: '78px',
  position: 'relative',
  top: '-160px',
  zIndex: 2,
})

export const DividerLineLong = styled('div')<any>(({ hasMultipleAnswers }) => ({
  borderTop: '1px solid #858585',
  position: 'relative',
  bottom: hasMultipleAnswers ? 228 : 160,
  width: '100%',
  zIndex: 2,
}))

export const TagName = styled('span')({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  cursor: 'pointer',
  color: '#525252',
})

export const DateLabel = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#525252',
})

export const ResponderName = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#525252',
})

export const ResponderRelation = styled('span')<any>({
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#8C8C8C',
})

export const emailQuestion = {
  you: (
    <>
      Vamos criar uma conta pra você. Qual seu <b>e-mail</b>?
    </>
  ),
  he: (
    <>
      Qual o seu <b>e-mail</b>?
    </>
  ),
  she: (
    <>
      Qual o seu <b>e-mail</b>?
    </>
  ),
}

export const firstNameQuestion = {
  you: (
    <>
      Qual o seu <b>primeiro nome</b>?
    </>
  ),
  he: (
    <>
      Qual o <b>primeiro nome</b> do paciente?
    </>
  ),
  she: (
    <>
      Qual o <b>primeiro nome</b> do paciente?
    </>
  ),
}

export const lastNameQuestion = {
  you: (
    <>
      Qual o seu <b>sobrenome</b>?
    </>
  ),
  he: (
    <>
      Qual o <b>sobrenome</b> do paciente?
    </>
  ),
  she: (
    <>
      Qual o <b>sobrenome</b> do paciente?
    </>
  ),
}

export const birthdateQuestion = {
  you: 'Qual a sua **data de nascimento?**',
  he: 'Qual a **data de nascimento** de [nome_do_paciente]?',
  she: 'Qual a **data de nascimento** de [nome_do_paciente]?',
}

export const pronounQuestion = {
  you: 'Como você se **identifica?**',
  he: 'Vamos nos **referir** a [nome_do_paciente] como',
  she: 'Vamos nos **referir** a [nome_do_paciente] como',
}

import { useLocation, Navigate } from 'react-router-dom'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'

interface IRequireAuthWrapperProps {
  children: JSX.Element
  alowedRoles?: string[]
}

function RequireAuthWrapper({
  children,
  alowedRoles,
}: IRequireAuthWrapperProps) {
  const { user, loading } = useAuthContext()
  const location = useLocation()
  const { warnToast } = useNotification()

  if (loading) return <></>

  if (!user) {
    warnToast('Para acessar essa página, faça login', {
      toastId: 'warning_login_required',
    })
    return <Navigate to='/signin' state={{ from: location }} replace />
  }

  if (
    !alowedRoles?.some(
      (role) =>
        user.roles.includes(role.toUpperCase()) ||
        user.roles.includes(role.toLowerCase())
    )
  ) {
    warnToast('Você não tem permissão para acessar essa página', {
      toastId: 'warning_page_access_permission',
    })
    return <Navigate to='/' state={{ from: location }} replace />
  }

  return children
}

export default RequireAuthWrapper

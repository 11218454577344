import { fromDate } from 'helpers'

export const formatClinicFromBack = (payload: any) => ({
  id: payload.id,
  professionalCouncil: payload.professionalCouncil,
  register: payload.register,
  state: payload.state,
  telephone: payload.telephone,
  cpf: payload.cpf,
  email: payload.user.email,
  firstName: payload.personalInfo.firstName,
  lastName: payload.personalInfo.lastName,
  birthdate: fromDate(payload.personalInfo.birthdate),
})

export const formatNotificationFromBack = (payload: any) => ({
  ...payload,
  message: 'Uma nova resposta foi registrada nos resultados de '
})

import { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { QuestionPasswordField } from 'components'
import { useSignUpPatientContext } from 'contexts'
import { registerPageAccess } from 'helpers'

function PasswordStep(): JSX.Element {
  const { control } = useFormContext()
  const { patientName, type } = useSignUpPatientContext()

  useEffect(() => {
    registerPageAccess('Senha Paciente', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography variant='question'>
        Agora, crie uma <b>senha</b> de acesso:
      </Typography>
      <Box mt={2} mb={10}>
        <QuestionPasswordField name='password' control={control} />
      </Box>

      <Typography variant='question'>
        <b>Confirme</b> sua senha:
      </Typography>
      <Box mt={2}>
        <QuestionPasswordField name='confirmPassword' control={control} />
      </Box>
    </>
  )
}

export default PasswordStep

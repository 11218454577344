import { createContext, useContext } from 'react'

interface AuthContextType {
  user: any
  loading: boolean
  signin: (body: any, callback?: VoidFunction) => void
  signup: (body: any, callback?: VoidFunction) => void
  signout: () => void
}

export const AuthContext = createContext<AuthContextType>(null!)

export function useAuthContext() {
  return useContext(AuthContext)
}

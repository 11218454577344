export const defaultButtonStyle = {
  height: '46px',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
  boxShadow: 'none',
  '&:disabled': {
    color: 'white',
    backgroundColor: '#d6d6d6',
  },
} as const

import { Box } from '@mui/material'
import { Text } from './start-questionnaire-styles'

function InformantText({ patientInfo }: any) {
  return (
    <>
      <Box my={3}>
        <Text>
          Agora, vamos te fazer algumas perguntas para entender a sua visão
          sobre <b>{patientInfo?.firstName || '_'}</b>.
        </Text>
      </Box>
      <Text>
        <b>Vamos lá?</b>
      </Text>
    </>
  )
}

export default InformantText

import { InfoTitle, InfoText, CenterBox, InfoBox } from './patient-info-styles'
import { fromDate } from 'helpers'
import {
  CakeOutlined,
  FemaleOutlined,
  MaleOutlined,
  AlternateEmailOutlined,
  GroupOutlined,
  PersonOutlined,
} from '@mui/icons-material'

function PatientInfo({ patient, patientInformants }: any): JSX.Element {
  const isMale = 'HE' === patient.pronoun
  const pronoun = 'HE' === patient.pronoun ? 'Masculino' : 'Feminino'

  return (
    <>
      {/* Sobre  */}
      <InfoBox>
        <InfoTitle>Sobre</InfoTitle>
        <CenterBox>
          <CakeOutlined
            sx={{
              fontSize: 20,
              color: '#596B7B',
              marginRight: '10px',
            }}
          />
          <InfoText>{fromDate(patient.birthdate)}</InfoText>
        </CenterBox>
        <CenterBox>
          {isMale ? (
            <MaleOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
          ) : (
            <FemaleOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
          )}
          <InfoText>{pronoun}</InfoText>
        </CenterBox>
      </InfoBox>

      {/* Informações do responsável  */}
      {patient.caregiver && (
        <InfoBox>
          <InfoTitle>Informações do responsável</InfoTitle>
          <CenterBox>
            <GroupOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
            <InfoText>{`${patient.caregiver.firstName} ${patient.caregiver.lastName} (${patient.caregiver.relation.name})`}</InfoText>
          </CenterBox>
        </InfoBox>
      )}

      {/* Contato  */}
      <InfoBox>
        <InfoTitle>Contato</InfoTitle>
        <CenterBox>
          <AlternateEmailOutlined
            sx={{
              fontSize: 20,
              color: '#596B7B',
              marginRight: '10px',
            }}
          />
          <InfoText>{patient.email}</InfoText>
        </CenterBox>
        {/* TODO - pacient phone */}
        {/* <CenterBox>
          <LocalPhoneOutlined
            sx={{
              fontSize: 20,
              color: '#596B7B',
              marginRight: '10px',
            }}
          />
          <InfoText>53 99118 7854</InfoText>
        </CenterBox> */}
      </InfoBox>

      {/* Convidados  */}
      {Boolean(patientInformants.length) && (
        <InfoBox>
          <InfoTitle>Convidados</InfoTitle>
          {patientInformants.map(({ informant, relation }: any, i: number) => {
            const informantName = `${informant?.personalInfo?.firstName} ${informant?.personalInfo?.lastName}`
            return (
              <CenterBox key={i}>
                <PersonOutlined
                  sx={{
                    fontSize: 20,
                    color: '#596B7B',
                    marginRight: '10px',
                  }}
                />
                <InfoText>{`${informantName} (${relation.name})`}</InfoText>
              </CenterBox>
            )
          })}
        </InfoBox>
      )}
    </>
  )
}
export default PatientInfo

import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Box } from '@mui/material'
import { CloseOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material'
import { TitleText, Container, Section } from './clinic-profile-tabs-styles'
import { IconLink, Button } from 'components'
import { InputField, SelectField } from 'components/inputs'
import { generateOptions } from 'helpers'
import { states } from 'helpers/constants'

function MyInfoTab({ resetForm }: any): JSX.Element {
  const [isEdit, setIsEdit] = useState(false)
  const [isEditPass, setIsEditPass] = useState(false)
  const statesOptions = useMemo(() => generateOptions(states), [])
  const { control, setValue } = useFormContext()

  return (
    <Container>
      <Section>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='40%'
        >
          <TitleText>Meus Dados</TitleText>
          <IconLink
            startIcon={isEdit ? <CloseOutlined /> : <EditOutlined />}
            onClick={() => {
              resetForm()
              setIsEditPass(false)
              setIsEdit(!isEdit)
            }}
            style={{ marginTop: '0' }}
          >
            {isEdit ? 'Cancelar' : 'Editar'}
          </IconLink>
        </Box>
        <Grid container width='40%'>
          <Grid item xs={12} md={5} mb={2}>
            <InputField
              disabled={!isEdit}
              name='firstName'
              control={control}
              label='Nome'
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5} mb={2}>
            <InputField
              disabled={!isEdit}
              name='lastName'
              control={control}
              label='Sobrenome'
            />
          </Grid>
          <Grid item xs={12} md={5} mb={2}>
            <InputField
              disabled={!isEdit}
              mask='(00) 00000-0000'
              name='telephone'
              control={control}
              label='Telefone celular'
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5} mb={2}>
            <SelectField
              disabled={!isEdit}
              label='Estado'
              name='state'
              control={control}
              options={statesOptions}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputField
              disabled
              name='email'
              control={control}
              label='E-mail'
              invalidChars={[' ']}
            />
          </Grid>
        </Grid>
        {isEdit && (
          <Button
            type='submit'
            sx={{ width: '200px', marginTop: '30px' }}
            startIcon={<SaveOutlined />}
          >
            Salvar
          </Button>
        )}
      </Section>
      <Section>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='40%'
        >
          <TitleText>Senha e Autenticação</TitleText>
          {isEditPass && (
            <IconLink
              startIcon={<CloseOutlined />}
              onClick={() => {
                resetForm()
                setIsEditPass(false)
              }}
              style={{ marginTop: '0' }}
            >
              {'Cancelar'}
            </IconLink>
          )}
        </Box>
        {!isEditPass && (
          <IconLink
            startIcon={<EditOutlined />}
            onClick={() => {
              setValue('validatePass', true)
              setIsEdit(false)
              setIsEditPass(!isEditPass)
            }}
          >
            Alterar senha
          </IconLink>
        )}
        {isEditPass && (
          <>
            <Grid container width='40%'>
              <Grid item xs={12} md={5} mb={2}>
                <InputField
                  type='password'
                  name='password'
                  control={control}
                  label='Nova Senha'
                />
              </Grid>
              <Grid item xs={0} md={2} />
              <Grid item xs={12} md={5} mb={2}>
                <InputField
                  type='password'
                  name='confirmPassword'
                  control={control}
                  label='Confirmar senha'
                />
              </Grid>
            </Grid>
            <Button
              type='submit'
              sx={{ width: '200px', marginTop: '30px' }}
              startIcon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </>
        )}
      </Section>
      {/* TODO - Desativar conta */}
      {/* <Section>
        <TitleText>
          Remoção de conta
        </TitleText>
        <Typography>
          Desativar sua conta significa que você poderá recuperá-la quando quiser
        </Typography>
        <IconLink
          startIcon={<SettingsOutlined />}
          onClick={() => console.log(clinic)}
        >
          Desativar minha conta
        </IconLink>
      </Section> */}
    </Container>
  )
}

export default MyInfoTab

import { useState, useMemo, useEffect } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import { SendMethod, WhatsappForm, EmailForm } from 'components'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { useQuery } from 'hooks'
import {
  generateWhatsappInvitePatientMessage,
  generateWhatsappResendPatientMessage,
  generateWhatsappResendCaregiverMessage,
  formatUserName,
  registerPageAccess,
  registerTrack,
} from 'helpers'
import { toast } from 'react-toastify'
import { AttentionText, Subtitle } from './styles/send-questions-step-styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { PageTitle } from './styles/steps-styles'

function SendQuestionsStep(): JSX.Element {
  const [activeSendMethod, setActiveSendMethod] = useState('')
  const [linkCopied, setLinkCopied] = useState(false)

  const { user } = useAuthContext()
  const { newQuestionnaireInfos } = useInvitePatientContext()
  const query = useQuery()

  const registerEmail: string = query.get('email') || ''
  const caregiverName: string = query.get('caregiver_name') || ''

  const { invitationToken, patientName } = newQuestionnaireInfos

  const invitePatientLink = `${process.env.REACT_APP_WIDA_URL as string}${
    invitationToken ? '/signup?invitationToken=' + invitationToken : ''
  }`

  const firstName = patientName.split(' ')[0] || ''

  const message = useMemo(() => {
    if (registerEmail) {
      if (Boolean(caregiverName)) {
        return generateWhatsappResendCaregiverMessage(
          patientName,
          caregiverName,
          formatUserName(user),
          invitePatientLink
        )
      } else {
        return generateWhatsappResendPatientMessage(
          firstName,
          formatUserName(user),
          invitePatientLink
        )
      }
    } else {
      return generateWhatsappInvitePatientMessage(
        firstName,
        formatUserName(user),
        invitePatientLink
      )
    }
  }, [
    registerEmail,
    firstName,
    user,
    invitePatientLink,
    caregiverName,
    patientName,
  ])

  useEffect(() => {
    registerPageAccess('Enviar Perguntas', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopy = () => {
    registerTrack('Clica Link', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
      source: 'envio',
    })
    navigator.clipboard.writeText(message.replaceAll('%0a', '\n') || '')
    toast.success('Convite copiado com sucesso!')
    setActiveSendMethod('link')
    setLinkCopied(true)
  }

  return (
    <Box py={3} px={5} width='50%'>
      <Box>
        <PageTitle>Quase lá!</PageTitle>
      </Box>
      <Box>
        {registerEmail ? (
          <Subtitle>
            Selecione uma das opções abaixo para compartilhar as perguntas com{' '}
            {firstName}.
          </Subtitle>
        ) : (
          <>
            <Subtitle>
              Selecione uma das opções abaixo e convide {firstName} para se
              cadastrar na plataforma Wida.
            </Subtitle>
            <Subtitle>
              As perguntas estarão disponíveis para resposta imediatamente após
              o cadastro.
            </Subtitle>
          </>
        )}
      </Box>
      <Box my={7}>
        <Stack direction='row' my={3} spacing={4}>
          <SendMethod
            type='whatsapp'
            handleClick={() => {
              registerTrack('Clica Whatsapp', {
                clinic_id: user.clinicId,
                patient_id: query.get('patient_id'),
                source: 'envio',
              })
              setActiveSendMethod('whatsapp')
            }}
            active={activeSendMethod === 'whatsapp'}
          />
          <Tooltip
            title={
              Boolean(registerEmail)
                ? `${
                    caregiverName ? caregiverName.split(' ')[0] : firstName
                  } já recebeu a notificação por email`
                : ''
            }
            placement='top'
            arrow
          >
            <Box>
              <SendMethod
                type='email'
                handleClick={() => {
                  registerTrack('Clica Email', {
                    clinic_id: user.clinicId,
                    patient_id: query.get('patient_id'),
                    source: 'envio',
                  })
                  setActiveSendMethod('email')
                }}
                active={activeSendMethod === 'email'}
                disabled={Boolean(registerEmail)}
              />
            </Box>
          </Tooltip>
          <SendMethod
            type='link'
            label={linkCopied && 'Link copiado!'}
            handleClick={handleCopy}
            active={activeSendMethod === 'link'}
          />
        </Stack>

        <Box width='60%'>
          {activeSendMethod === 'whatsapp' && (
            <WhatsappForm
              message={message}
              direction='row'
              registerTrackPayload={{
                clinic_id: user.clinicId,
                patient_id: query.get('patient_id'),
                source: 'envio',
              }}
            />
          )}

          {activeSendMethod === 'email' && (
            <EmailForm
              message={message}
              isInvite={!Boolean(registerEmail)}
              invitationToken={invitationToken}
              disabled={Boolean(registerEmail)}
              value={registerEmail}
              direction='row'
              registerTrackPayload={{
                clinic_id: user.clinicId,
                patient_id: query.get('patient_id'),
                source: 'envio',
              }}
            />
          )}
        </Box>
      </Box>
      <Box display={'flex'}>
        <InfoOutlinedIcon
          fontSize='small'
          sx={{
            mr: 1,
            marginTop: '2px',
            color: '#657787',
          }}
        />
        <AttentionText>
          <b>Atenção:</b> o convite acima deve ser enviado exclusivamente para o
          titular da conta (paciente ou seu responsável legal). Apenas o titular
          da conta poderá convidar outras pessoas para responder as perguntas.
        </AttentionText>
      </Box>
    </Box>
  )
}

export default SendQuestionsStep

export const BLOCK_MIN_QUESTION_NUMBER = 5

export const blockOrderDict: any = {
  'ações e comportamentos': 0,
  comunicação: 1,
  conflitos: 2,
  agitação: 3,
  'foco e concentração': 4,
  'pensamentos e sentimentos': 5,
  'interação social': 6,
  sono: 7,
}

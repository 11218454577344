import { answersDictFromBack } from 'helpers/constants'
import { extractTagsFromAnswers } from 'helpers'

const formatQuestionWithAnswerFromBack = (question: any) => ({
  id: question.id,
  ultraShortVersion: question.ultraShortVersion || '',
  shortVersionM: question.shortVersionM || '',
  shortVersionF: question.shortVersionF || '',
  questionSelfM: question.questionSelfM || '',
  block: question.block || '',
  tags: question.tags || [],
  answer: answersDictFromBack[String(question.answer.answer)],
})

const formatUserResponseFromBack = (response: any) => {
  return {
    formDateCreate: response.createdAt || '',
    formDateUpdate: response.responseDate || response.updatedAt || '',
    formRequestId: response.formRequestId,
    tags:
      response?.tags?.length > 0
        ? response.tags
        : extractTagsFromAnswers(response.questions),
    questions: response.questions.map(formatQuestionWithAnswerFromBack),
  }
}

export const formatUserResponsesFromBack = (responses: any) => {
  const validResponses = responses
    .filter((response: any) => response.isCompleted)
    .map((form: any) => ({
      ...form,
      questions: form.questions.filter(
        ({ answer, isActive }: any) => Boolean(answer) && isActive
      ),
    }))

  return validResponses.map(formatUserResponseFromBack)
}

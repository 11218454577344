import { createContext, useContext } from 'react'

type PatientType = 'you' | 'he' | 'she'

interface SignUpPatientContextType {
  goNextStep: () => void
  patient: PatientType
  patientName: string
  type: string
}

export const SignUpPatientContext = createContext<SignUpPatientContextType>({
  goNextStep: () => {},
  patient: 'you',
  patientName: '',
  type: '',
})

export const useSignUpPatientContext = () => {
  const context = useContext(SignUpPatientContext)
  return context
}

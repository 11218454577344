import { useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Button } from 'components'
import { InputField, SelectField } from 'components/inputs'
import { useSignUpClinicContext } from 'contexts'
import { generateOptions } from 'helpers'
import { states } from 'helpers/constants'

function AccessStep(): JSX.Element {
  const { control, watch } = useFormContext()
  const { goNextStep } = useSignUpClinicContext()

  const statesOptions = useMemo(() => generateOptions(states), [])

  const email = watch('email')
  const pass = watch('password')
  const confPass = watch('confirmPassword')
  const tel = watch('telephone')
  const state = watch('state')

  const canProceed = () => {
    return !email || !pass || !confPass || !tel || !state
  }

  return (
    <>
      <Grid item xs={12} mb={2}>
        <InputField
          name='email'
          control={control}
          label='E-mail'
          invalidChars={[' ']}
        />
      </Grid>
      <Grid item xs={12} md={5} mb={2}>
        <InputField
          type='password'
          name='password'
          control={control}
          label='Senha'
        />
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={5} mb={2}>
        <InputField
          type='password'
          name='confirmPassword'
          control={control}
          label='Confirmar senha'
        />
      </Grid>
      <Grid item xs={12} md={5} mb={2}>
        <InputField
          mask='(00) 00000-0000'
          name='telephone'
          control={control}
          label='Telefone celular'
        />
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={5} mb={7}>
        <SelectField
          label='Estado'
          name='state'
          control={control}
          options={statesOptions}
        />
      </Grid>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        alignItems='end'
      >
        <Button
          type='button'
          disabled={canProceed()}
          onClick={goNextStep}
          style={{ width: '50%' }}
        >
          Próximo
        </Button>
      </Box>
    </>
  )
}

export default AccessStep

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const LabelText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
  color: '#657787',
  marginBottom: '16px',
})

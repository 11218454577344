import { ComponentProps, FC } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import brLocale from 'date-fns/locale/pt-BR'

function DateLocalizationProvider({
  children,
}: ComponentProps<FC>): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
      {children}
    </LocalizationProvider>
  )
}

export default DateLocalizationProvider

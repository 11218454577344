import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { answerOptions } from 'helpers'

interface IPresenceCheckboxProps {
  option: 'yes' | 'soso' | 'no'
}

function PresenceCheckbox({ option }: IPresenceCheckboxProps): JSX.Element {
  const { setValue, watch } = useFormContext()
  const answersFilter = watch('answersFilter')

  const active = answersFilter.some((answer: string) => answer === option)

  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      sx={{
        cursor: 'pointer',
        opacity: active ? '100%' : '50%',
      }}
      onClick={() => {
        const newAnswersFilter = active
          ? answersFilter.filter((answer: string) => answer !== option)
          : [...answersFilter, option]

        if (newAnswersFilter.length) setValue('answersFilter', newAnswersFilter)
      }}
    >
      <img
        src={answerOptions[option].svg as string}
        height='18px'
        alt={answerOptions[option].alt}
      />
      <span>{answerOptions[option].label}</span>
    </Stack>
  )
}

export default PresenceCheckbox

import { useEffect, KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Box } from '@mui/material'
import { Button, TextField } from 'components'
import { useNotification } from 'hooks'
import { sendEmailInvitePatient, sendLastFormByEmail } from 'services'
import { registerTrack } from 'helpers'
import { EmailText } from './email-form-styles'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface IEmailFormProps {
  invitationToken: string
  direction: 'row' | 'column'
  isInvite?: boolean
  value?: string
  disabled?: boolean
  registerTrackPayload?: any
  message?: string
}

function EmailForm({
  invitationToken,
  isInvite = true,
  value = '',
  disabled = false,
  direction,
  registerTrackPayload,
  message = '',
}: IEmailFormProps) {
  const { control, handleSubmit, setValue } = useForm()
  const { successToast, errorToast } = useNotification()

  useEffect(() => {
    if (value) setValue('email', value)
  }, [setValue, value])

  const onSubmit = async (data: any) => {
    try {
      registerTrack('Enviar Email', registerTrackPayload)

      const body = {
        ...(isInvite && { invitationToken }),
        email: data.email,
      }

      isInvite
        ? await sendEmailInvitePatient(body)
        : await sendLastFormByEmail(body)

      successToast('Email enviado com sucesso!')
    } catch (error: any) {
      console.error(error)
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const handleOpenClientEmail = () => {
    registerTrack('Clica Envia Client email', registerTrackPayload)

    window.open(
      `mailto:email@example.com?subject=Novas%20perguntas%20para%20você%20no%20Wida&body=${message}`
    )
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Stack
        spacing={1}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
        direction={direction}
        alignItems='flex-end'
      >
        <TextField
          name='email'
          control={control}
          disabled={disabled}
          invalidChars={[' ']}
          placeholder='Digite o e-mail do titular da conta'
        />
        <Button
          color='secondary'
          type='submit'
          sx={{
            height: '42px',
            ...(direction === 'row' && { width: '200px' }),
          }}
        >
          Enviar
        </Button>
      </Stack>
      <Stack direction='row' mt={2}>
        <EmailText>
          ou envie no seu{' '}
          <u onClick={handleOpenClientEmail} style={{ cursor: 'pointer' }}>
            cliente de e-mail
          </u>
        </EmailText>
        <OpenInNewIcon
          onClick={handleOpenClientEmail}
          sx={{ cursor: 'pointer', color: '#9D9CB5' }}
        />
      </Stack>
    </Box>
  )
}

export default EmailForm

import { Box } from '@mui/material'
import { UserName, Text } from './start-questionnaire-styles'

function PatientText({ patientInfo }: any) {
  return (
    <>
      <UserName>
        Oi, <b>{patientInfo?.firstName || '_'}</b>,
      </UserName>
      <Box my={3}>
        <Text>
          Agora vamos te fazer algumas <b>perguntas</b>, selecionadas pelo seu
          clínico, para saber como você está.
        </Text>
      </Box>
      <Text>
        <b>Vamos lá?</b>
      </Text>
    </>
  )
}

export default PatientText

import PatientStep from './patient-step'
import QuestionStep from './questions-step'
import SendQuestionsStep from './send-questions-step'
import ChooseGroupsAndFrequencyStep from './choose-groups-and-frequency-step'

export const invitePatientSteps: any = [
  {
    label: 'Confirmar paciente',
    component: <PatientStep />,
  },
  {
    label: 'Selecionar perguntas',
    component: <QuestionStep />,
  },
  {
    label: 'Configurar envio',
    component: <ChooseGroupsAndFrequencyStep />,
  },
  {
    label: 'Enviar',
    component: <SendQuestionsStep />,
  },
]

export const formatPatientFromBack = (payload: any) => ({
  patientId: payload.patient.id,
  isTutor: payload.caregiver.accountInfo.isTutor,
  lastFormId: payload.formId,
  isCompleted: payload.isCompleted,
  firstName: payload.caregiver.accountInfo.personalInfo.firstName,
  lastName: payload.caregiver.accountInfo.personalInfo.lastName,
  age: payload.caregiver.age,
  email: payload.caregiver.accountInfo.user.email,
  roles: payload.caregiver.accountInfo.user.roles,
  formRequestFrequency: payload.formRequestFrequency,
  responseDate: payload.responseDate,
  lastResponseDate: payload.lastResponseDate,
})

export const formatCaregiverFromBack = (payload: any) => ({
  patientId: payload.patient.id,
  isTutor: payload.caregiver.accountInfo.isTutor,
  lastFormId: payload.formId,
  isCompleted: payload.isCompleted,
  firstName: payload.patient.accountInfo.personalInfo.firstName,
  lastName: payload.patient.accountInfo.personalInfo.lastName,
  age: payload.patient.age,
  email: payload.caregiver.accountInfo.user.email,
  caregiver: {
    firstName: payload.caregiver.accountInfo.personalInfo.firstName,
    lastName: payload.caregiver.accountInfo.personalInfo.lastName,
  },
  roles: payload.caregiver.accountInfo.user.roles,
  formRequestFrequency: payload.formRequestFrequency,
  responseDate: payload.responseDate,
  lastResponseDate: payload.lastResponseDate,
})

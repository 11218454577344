import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  color: 'white',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
})

export const Text = styled(Typography)({
  color: 'white',
  fontSize: '16px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
})

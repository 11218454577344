import { useForm } from 'react-hook-form'
import { signInResolver } from 'resolvers'
import { IFormInput } from './sign-in-types'

function useFormSignIn() {
  const { control, handleSubmit, watch } = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: signInResolver,
    defaultValues: {
      email: '',
      password: '',
    },
  })

  return { control, handleSubmit, watch }
}

export default useFormSignIn

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toDate } from 'helpers'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

const testStrongPassword = (value: any) =>
  /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)

const validateDateFormat = (date: any) => {
  const formattedDate = toDate(date)
  return formattedDate < new Date() && String(formattedDate) !== 'Invalid Date'
}

export const signInResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
      password: yup.string().required(),
    })
    .required()
)

export const signUpPatientResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          (value: any) => String(new Date(value)) !== 'Invalid Date'
        ),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

export const signUpCaregiverResolver = yupResolver(
  yup
    .object({
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          (value: any) => String(new Date(value)) !== 'Invalid Date'
        ),
      email: yup.string().email('Digite um email válido').required(),
      caregiverFirstName: yup.string().required(),
      caregiverLastName: yup.string().required(),
      relation: yup.string().required(),
      environment: yup.string().required(),
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

export const signUpInformantResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      relation: yup.string().required(),
      environment: yup.string().when('relation', {
        is: (val: any) => {
          const selectedObj = JSON.parse(val)
          return selectedObj?.label !== 'Sou eu'
        },
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

export const signUpClinicResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
      register: yup.string().required(),
      professionalCouncil: yup.string().required(),
      state: yup.string().required(),
      telephone: yup.string().required().min(11, 'Telefone inválido'),
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido'),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .min(10, 'Digite uma data válida')
        .test(
          'valid_date',
          'Digite uma data válida',
          validateDateFormat
        ),
    })
    .required()
)

export const clinicInfoResolver = yupResolver(
  yup
    .object({
      firstName: yup
        .string()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      telephone: yup.string().min(11, 'Telefone inválido'),
      state: yup.string(),
      validatePass: yup.boolean(),
      password: yup
        .string()
        .when("validatePass", {
          is: true,
          then: yup
            .string()
            .min(8, 'Senha deve ter mais que 8 caracteres')
            .test(
              'strong_password',
              'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
              testStrongPassword
            )
        }),
      confirmPassword: yup
        .string()
        .when("validatePass", {
          is: true,
          then: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
        })
      ,
      register: yup.string(),
      professionalCouncil: yup.string(),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .min(10, 'Digite uma data válida')
        .test(
          'valid_date',
          'Digite uma data válida',
          validateDateFormat
        ),
    })
    .required()
)

export const passwordResetResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
    })
    .required()
)

export const forgotPasswordResolver = yupResolver(
  yup
    .object({
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

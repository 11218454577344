import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { AnswerLabel } from 'components'
import { ANSWERS } from 'helpers/constants'
import { HtmlTooltip } from './graphic-label-styles'

function GraphicLabel({ quantities, maxTagQuestions, children }: any) {
  const sumQuantities: any = useMemo(() => {
    return Object.values(quantities).reduce(
      (acc: any, qtd: any) => acc + qtd,
      0
    )
  }, [quantities])

  return (
    <HtmlTooltip
      placement='right'
      title={
        <Stack spacing={1}>
          {ANSWERS.map((answer: string) => (
            <AnswerLabel
              key={answer}
              answer={answer}
              qtd={quantities[answer] || 0}
            />
          ))}
          {maxTagQuestions !== sumQuantities && (
            <AnswerLabel
              key={'NA'}
              answer={'NA'}
              qtd={maxTagQuestions - sumQuantities}
            />
          )}
        </Stack>
      }
    >
      {children}
    </HtmlTooltip>
  )
}

export default GraphicLabel

import { useState } from 'react'
import { Container, Stack } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAuthContext } from 'contexts'
import {
  generateWhatsappInvitePatientMessage,
  formatUserName,
  registerTrack,
} from 'helpers'
import {
  SendMethod,
  InputWithButton,
  WhatsappForm,
  EmailForm,
} from 'components'
import { Title, SummaryText, Dialog } from './resend-invite-modal-styles'

interface ResendInviteModalProps {
  onClose: () => void
  informantion: {
    open: boolean
    token: string
    patientName: string
  }
}

function ResendInviteModal({ onClose, informantion }: ResendInviteModalProps) {
  const [activeSendMethod, setActiveSendMethod] = useState('')

  const { user } = useAuthContext()

  const patientFirstName = informantion.patientName?.split(' ')[0] || ''

  const invitePatientLink = `${process.env.REACT_APP_WIDA_URL as string}${
    '/signup?invitationToken=' + informantion.token
  }`

  const message = generateWhatsappInvitePatientMessage(
    patientFirstName,
    formatUserName(user),
    invitePatientLink
  )

  return (
    <Dialog onClose={onClose} open={informantion.open}>
      <Container maxWidth='md' sx={{ padding: '40px 0px' }}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ color: '#657787', position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Title>Tudo pronto!</Title>
          <SummaryText>
            Escolha uma das opções abaixo para enviar sua seleção de perguntas
            para {patientFirstName}.
          </SummaryText>
        </DialogTitle>
        <DialogContent>
          <Stack direction='row' my={3} spacing={4}>
            <SendMethod
              type='whatsapp'
              handleClick={() => {
                registerTrack('Clica Whatsapp', {
                  clinic_id: user.clinicId,
                  source: 'reenvio',
                })
                setActiveSendMethod('whatsapp')
              }}
              active={activeSendMethod === 'whatsapp'}
            />
            <SendMethod
              type='email'
              handleClick={() => {
                registerTrack('Clica Email', {
                  clinic_id: user.clinicId,
                  source: 'reenvio',
                })

                setActiveSendMethod('email')
              }}
              active={activeSendMethod === 'email'}
            />
            <SendMethod
              type='link'
              handleClick={() => {
                registerTrack('Clica Link', {
                  clinic_id: user.clinicId,
                  source: 'reenvio',
                })
                setActiveSendMethod('link')
              }}
              active={activeSendMethod === 'link'}
            />
          </Stack>
          {activeSendMethod === 'whatsapp' && (
            <WhatsappForm
              message={message}
              direction='row'
              registerTrackPayload={{
                clinic_id: user.clinicId,
                source: 'reenvio',
              }}
            />
          )}
          {activeSendMethod === 'email' && (
            <EmailForm
              invitationToken={informantion.token}
              direction='row'
              registerTrackPayload={{
                clinic_id: user.clinicId,
                source: 'reenvio',
              }}
            />
          )}
          {activeSendMethod === 'link' && (
            <InputWithButton
              size='normal'
              displayText={invitePatientLink || ''}
              copyValue={message.replaceAll('%0a', '\n') || ''}
              registerTrackPayload={{
                clinic_id: user.clinicId,
                source: 'reenvio',
              }}
            />
          )}
        </DialogContent>
      </Container>
    </Dialog>
  )
}

export default ResendInviteModal

import { Fragment } from 'react'
import { Controller } from 'react-hook-form'
import { Box, Checkbox, Tooltip, Typography } from '@mui/material'
import { QuestionText } from './select-questions-styles'

function SelectQuestions({
  questions,
  selectedQuestions,
  name,
  control,
  selectedTag,
  handleTagByQuestion,
}: any): JSX.Element {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value: oldSelectedQuestions } }: any) => {
          return questions
            .filter((question: any) =>
              question.tags.includes(selectedTag.value)
            )
            .map((question: any) => {
              const isChecked = oldSelectedQuestions.some(
                ({ id }: any) => id === question.id
              )
              const isDisabled =
                isChecked &&
                selectedQuestions.some(({ id, tag }: any) => {
                  if (tag?.value) {
                    return id === question.id && tag.value !== selectedTag.value
                  }
                  return false
                })

              return (
                <Fragment key={question.id}>
                  <Box
                    px={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    borderLeft={'2px solid #F7F7F7'}
                  >
                    {isDisabled ? (
                      <Tooltip
                        title={
                          'Esta pergunta está selecionada em outro domínio'
                        }
                        arrow
                      >
                        <span style={{ cursor: 'pointer' }}>
                          <Checkbox
                            disabled={true}
                            checked={isChecked}
                            sx={{ p: '6px' }}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={isChecked}
                        onChange={({ target: { checked } }: any) => {
                          handleTagByQuestion(
                            { ...question, tag: selectedTag },
                            checked
                          )
                        }}
                        sx={{ p: '6px' }}
                      />
                    )}

                    <QuestionText flexGrow={1}>
                      <Typography
                        sx={{
                          fontFamily: 'Zen Kaku Gothic Antique',
                        }}
                      >
                        {question.questionSelfM}
                      </Typography>
                    </QuestionText>
                  </Box>
                </Fragment>
              )
            })
        }}
      />
    </>
  )
}

export default SelectQuestions

import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Box, Typography } from '@mui/material'
import {
  QuestionAnswers,
  Header,
  RouteLayout,
  Button,
  Steps,
  InformationModal,
} from 'components'
import { useAuthContext } from 'contexts'
import { isUserPatient, registerTrack } from 'helpers'

function Question({
  question,
  callbackChooseAnswer,
  step,
  totalSteps,
  goBackStep,
  goForwardStep,
  loadingAnswer,
  patientInfo,
}: any) {
  const [moreInformationModal, setMoreInformationModal] = useState(false)

  const { user } = useAuthContext()

  const questionTrackPayload = {
    user_id: user.userId,
    patient_id: patientInfo.patientId,
    identifier: question.identifier,
    source: isUserPatient(user) ? 'patient' : 'caregiver',
    order: step,
  }

  useEffect(() => {
    registerTrack('Visualiza Pergunta', questionTrackPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseInformationModal = () => {
    setMoreInformationModal(false)
  }

  return (
    <RouteLayout bgColor='white'>
      <Header
        color
        hasGoBackButton
        hasGoForwardButton
        hasProfileButton
        disabledGoHome
        handleBack={() => {
          registerTrack('Clica Voltar', questionTrackPayload)
          goBackStep()
        }}
        handleForward={() => {
          registerTrack('Clica Pular', questionTrackPayload)
          goForwardStep()
        }}
      />
      <Box
        mx={5}
        mb={1}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Typography variant='question' sx={{ height: '240px' }}>
          <ReactMarkdown>{question.text}</ReactMarkdown>
        </Typography>
        <Box mt={2}>
          <QuestionAnswers
            initialAnswer={question.answer}
            handleChooseAnswer={callbackChooseAnswer}
            loadingAnswer={loadingAnswer}
          />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {Boolean(question.example) && (
            <Button
              variant='text'
              onClick={() => {
                registerTrack('Clica Não Entendi', questionTrackPayload)
                setMoreInformationModal(true)
              }}
            >
              não entendi a pergunta
            </Button>
          )}
          <Steps activeStep={step} totalSteps={totalSteps} />
        </Box>
      </Box>

      {moreInformationModal && (
        <InformationModal
          open={moreInformationModal}
          information={question.example}
          onClose={onCloseInformationModal}
        />
      )}
    </RouteLayout>
  )
}

export default Question

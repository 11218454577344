import { TitleText, Container, Section } from './clinic-profile-tabs-styles'
import { TermsOfUse } from 'components'

function TermsTab() {
  return (
    <Container>
      <Section>
        <TitleText>
          Termos de uso
        </TitleText>
        <TermsOfUse />
      </Section>
    </Container>
  )

}

export default TermsTab

import { styled } from '@mui/material/styles'

interface IBallProp {
  bgcolor?: string
  answer?: string
}

const backgroundDict: any = {
  no: 'none',
  soso: 'conic-gradient(transparent 0deg, transparent 180deg, white 181deg)',
  yes: 'conic-gradient(white 0deg, white 0deg)',
}

const LegendBall = styled('div')<IBallProp>(({ answer }: any) => ({
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  backgroundColor: 'none',
  backgroundImage: backgroundDict[answer],
  border: `1px solid #fff`,
}))

export default LegendBall

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

export const newQuestionnairePatientStepResolver = yupResolver(
  yup
    .object({
      patientName: yup.string().required(),
    })
    .required()
)

export const newQuestionnaireQuestionsStepResolver = yupResolver(
  yup
    .object({
      selectedQuestions: yup.array().min(1, 'Selecione pelo menos uma questão'),
    })
    .required()
)

export const newQuestionnaireGroupsAndFrequencyStepResolver = yupResolver(
  yup
    .object({
      frequency: yup.string().required(),
    })
    .required()
)

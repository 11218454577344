import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Stack, Divider, IconButton, Tooltip } from '@mui/material'
import { SwitchGraphicType } from 'components'
import { SelectTagResult, NewRespondersMenu } from 'components/results'
import { useResultsContext } from 'contexts'
import { capitalizeWordsOnSentence, orderFormattedDates } from 'helpers'

const VISIBLE_LIMIT = 4

function ResultsHeader({ showChart = true }: any): JSX.Element {
  const {
    responderOptions,
    selectedResponders,
    setSelectedResponders,
    tagsResults,
    activeProportion,
    setActiveProportion,
  } = useResultsContext()

  const [menuOptions, setMenuOptions] = useState(responderOptions)

  const { control, watch } = useFormContext()

  const selectedType = watch('type')

  useEffect(() => {
    if (selectedType === 'transv') {
      setSelectedResponders((selectedResponders: any) => {
        return selectedResponders.map((selectedResponder: any) => {
          const lastDate =
            selectedResponder.responseDate[
              selectedResponder.responseDate.length - 1
            ]

          return {
            ...selectedResponder,
            responseDate: lastDate ? [lastDate] : [],
          }
        })
      })
    }

    if (selectedType === 'long') {
      const newSelectResponder = selectedResponders[0]

      const selectedDates = orderFormattedDates(
        responderOptions.find((responderOption: any) => {
          return newSelectResponder.id === responderOption.id
        })?.responseDates || []
      )

      const lastDateIndex = selectedDates.length
      const firstDateIndex = lastDateIndex - VISIBLE_LIMIT

      setSelectedResponders([
        {
          ...newSelectResponder,
          responseDate: selectedDates.slice(
            firstDateIndex < 0 ? 0 : firstDateIndex,
            lastDateIndex
          ),
        },
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType])

  useEffect(() => {
    const updateMenuOptions: any = () => {
      setMenuOptions(
        responderOptions.filter(
          (responderOption: any) =>
            !selectedResponders.some(
              (selectedResponder: any) =>
                selectedResponder.id === responderOption.id
            )
        )
      )
    }

    updateMenuOptions()
  }, [responderOptions, selectedResponders])

  const handleAddResponder = ({ newResponderId, date }: any) => {
    const findResponder: any = responderOptions.find(
      (responderOption: any) => responderOption.id === newResponderId
    )

    const newResponder: any = { ...findResponder, responseDate: date }
    delete newResponder.responseDates

    setSelectedResponders((value: any) => [...value, newResponder])
  }

  const handleChangeOption = ({
    oldResponderId,
    newResponderId,
    date,
  }: any) => {
    const findNewResponder = responderOptions.find(
      (responderOption: any) => responderOption.id === newResponderId
    )

    const newResponder: any = {
      ...findNewResponder,
      responseDate: orderFormattedDates(date),
    }
    delete newResponder.responseDates

    setSelectedResponders((oldSelectedResponders: any) => {
      const newSelectedResponders = oldSelectedResponders.map(
        (oldSelectedResponder: any) => {
          return oldSelectedResponder.id === oldResponderId
            ? newResponder
            : oldSelectedResponder
        }
      )
      return newSelectedResponders
    })
  }

  const handleRemoveOption = (responder: any) => {
    setSelectedResponders((selectedResponders: any) =>
      selectedResponders.filter(
        (selectedResponder: any) => selectedResponder.id !== responder.id
      )
    )
  }

  const handleTurnSankey = () => {
    setActiveProportion((activeProportion: boolean) => !activeProportion)
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      pb={2}
      flexWrap='wrap'
    >
      <Box ml={1}>
        <SelectTagResult
          name='tag'
          control={control}
          options={[
            { value: 'results', label: 'Resultados' },
            ...tagsResults.map((tag: any) => ({
              value: tag.id,
              label: capitalizeWordsOnSentence(tag.name),
            })),
          ]}
        />
      </Box>

      <Box flexGrow={1} display='flex' mx={1}>
        <NewRespondersMenu
          type={selectedType}
          selectedResponders={selectedResponders}
          respondersOptions={responderOptions}
          menuOptions={menuOptions}
          handleAddResponder={handleAddResponder}
          handleRemoveResponder={handleRemoveOption}
          handleChangeOption={handleChangeOption}
        />
      </Box>

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box m={1}>
          <SwitchGraphicType name='type' control={control} />
        </Box>

        {showChart && (
          <>
            <Divider orientation='vertical' flexItem sx={{ margin: '0 8px' }} />
            <Tooltip title='Ativar/desativar gráfico proporcional'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <IconButton
                  aria-label='graphic-proportion'
                  onClick={handleTurnSankey}
                  sx={{
                    backgroundColor: activeProportion ? '#F36F60' : '#fff',
                    '&:hover': {
                      backgroundColor: activeProportion
                        ? '#F36F60ca'
                        : '#f0f0f0',
                    },
                    height: '42px',
                    width: '42px',
                  }}
                >
                  <span
                    style={{
                      cursor: 'pointer',
                      color: activeProportion ? '#f0f0f0' : '#F36F60ca',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    100%
                  </span>
                </IconButton>
              </Stack>
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  )
}

export default ResultsHeader

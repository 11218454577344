import { styled } from '@mui/material/styles'

export const Graphic = styled('div')<any>(({ color, answersInfos }) => {
  const { proportions, quantities, total } = answersInfos

  const relativeYes = proportions['yes']
  const relativeSoso = relativeYes + proportions['soso']
  const relativeNo =
    total !==
    Object.values(quantities).reduce(
      (acc: number, qtd: any) => acc + Number(qtd),
      0
    )
      ? relativeSoso + proportions['no']
      : 100

  const rangeYes = `0% ${relativeYes}%`
  const rangeSoso = `${relativeYes}% ${relativeSoso}%`
  const rangeNo = `${relativeSoso}% ${relativeNo}%`
  const rangeSkipped = `${relativeNo}% 100%`

  const minWidth = 30
  const maxWidth = 100
  const minQtdQuestions = 1
  const maxQtdQuestions = 30
  const width =
    minWidth +
    ((maxWidth - minWidth) /
      (Math.sqrt(maxQtdQuestions) - Math.sqrt(minQtdQuestions))) *
      (Math.sqrt(answersInfos.total) - Math.sqrt(minQtdQuestions))

  return {
    width: `${width}px`,
    height: '30px',
    border: `1px solid ${color}`,
    borderRadius: '21px',
    display: 'flex',
    background:
      `linear-gradient(to right, ` +
      `${color + 'FF'} ${rangeYes}, ` +
      `${color + '99'} ${rangeSoso}, ` +
      `${color + '00'} ${rangeNo}, ` +
      `#D2D2D255 ${rangeSkipped})`,
  }
})

import { Typography } from '@mui/material'
import { GradientLink } from './gradient-link'

function SignUpHeader(): JSX.Element {
  return (
    <Typography variant='h5' style={{ fontSize: '16px' }}>
      Não tem uma conta?{' '}
      <GradientLink
        href='https://www.wida.app/?register=1'
        rel='noreferrer'
        target='_blank'
      >
        Cadastre-se
      </GradientLink>
    </Typography>
  )
}

export default SignUpHeader

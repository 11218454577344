import { Box, Typography } from '@mui/material'
import noPatientsImage from 'images/no-patients.svg'

function PatientListEmpty(): JSX.Element {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex={1}
    >
      <Box mb={3}>
        <img
          src={noPatientsImage}
          height='130px'
          alt='Lista de pacientes vazia'
        />
      </Box>
      <Box mb={2}>
        <Typography variant='title'>
          <b>Bem-vindo ao Wida!</b>
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='text'>
          Você ainda não tem nenhum paciente.
        </Typography>
      </Box>
    </Box>
  )
}

export default PatientListEmpty

import { styled } from '@mui/material/styles'

export const GradientLink = styled('a')`
  font-weight: 700;
  &:link, &:visited, &:active {
    background: -webkit-linear-gradient(left, #00B3A6, #F36F60, #F36F60);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.4s;
    
    &:hover {
      opacity: 0.6;
    }
  }
`

import { useState, useEffect } from 'react'
import { RouteLayout, Header } from 'components'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import { Box, Stack } from '@mui/material'
import { Button, MenuButton, CheckboxGroup } from 'components'
import { useNotification, useQuery } from 'hooks'
import { getUserInformants, createFormRequest } from 'services'
import { useAuthContext } from 'contexts'
import useCreateInviteForm from './use-invite-informant-form'
import { Title, Description } from './invite-informant-styles'

function InviteInformant(): JSX.Element {
  const [informants, setInformants] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const navigate = useNavigate()
  const { handleSubmit, control } = useCreateInviteForm()
  const { successToast, errorToast, warnToast } = useNotification()
  const query = useQuery()
  const { user } = useAuthContext()

  const formId = query.get('form_id')

  useEffect(() => {
    if (!formId) {
      warnToast(
        'Você ainda não tem um questionário válido para convidar outras pessoas'
      )
      navigate('/')
      return
    }

    const fetchData = async () => {
      try {
        setLoading(true)

        const informants = await getUserInformants()

        if (!Boolean(informants.length)) {
          navigate(`/invite_new_informant?form_id=${formId}`)
          return
        }
        setInformants(
          informants.map((informant: any) => ({
            ...informant,
            alreadySent: false,
          }))
        )
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewInformantInvite = () => {
    navigate(`/invite_new_informant?form_id=${formId}`)
  }

  const onSubmit: SubmitHandler<any> = async (params) => {
    try {
      const validInformants = params.selectedInformants.filter(
        (informantId: string) => {
          const informant = informants.find(
            (informant: any) => informantId === informant.id
          )
          if (!informant) return false
          return !informant.alreadySent
        }
      )

      if (!Boolean(validInformants.length)) {
        warnToast('Selecione pelo menos um informante')
        return
      }

      const bodies = validInformants.map((informantId: string) => {
        const informant = informants.find(
          (informant: any) => informantId === informant.id
        )
        return {
          requestedBy: user.email,
          sendTo: informant?.user?.email,
          formId,
        }
      })

      await Promise.all(
        bodies.map(async (body: any) => {
          await createFormRequest(body)
        })
      )

      successToast(
        'Perguntas enviadas com sucesso! Um email foi enviado para avisar o informante.'
      )

      setInformants((informants) =>
        informants.map((informant: any) => ({
          ...informant,
          alreadySent:
            informant.alreadySent ||
            validInformants.some(
              (informantId: any) => informantId === informant.id
            ),
        }))
      )
    } catch (error: any) {
      console.error(error)
      errorToast(error.message || 'Erro desconhecido')
    }
  }

  if (loading) return <></>

  return (
    <RouteLayout bgColor='white'>
      <Box
        display='flex'
        flexDirection='column'
        height={window.innerHeight}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header
          color
          hasGoBackButton
          handleBack={() => {
            navigate('/')
          }}
        />
        <Box flexGrow={1} mx={5} mt={3}>
          <Title>Convide de novo</Title>
          <Box mt={2}>
            <Description>
              Selecione outras pessoas para responder esta rodada de perguntas
            </Description>
          </Box>
          <CheckboxGroup
            name='selectedInformants'
            control={control}
            options={informants.map((informant: any) => ({
              label: `${informant.personalInfo?.firstName || ''} ${
                informant.personalInfo?.lastName || ''
              }`,
              value: informant.id,
              alreadySent: informant.alreadySent,
            }))}
          />
        </Box>
        <Stack mb={7} mx={5} spacing={2}>
          <Button color='secondary' type='submit'>
            Compartilhar
          </Button>
          <MenuButton onClick={handleNewInformantInvite}>
            Quero convidar um novo informante
          </MenuButton>
        </Stack>
      </Box>
    </RouteLayout>
  )
}

export default InviteInformant

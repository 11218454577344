import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const InfoTitle = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #353F48;
`

export const InfoText = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #596B7B;
`
export const CenterBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '10px'
})

export const InfoBox = styled(Box)({
  paddingTop: '20px'
})

import { Chip, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledChip = styled(Chip)<any>(() => ({
  minHeight: '30px',
  height: 'auto',
  marginRight: '15px',
  marginBottom: '10px',
  transition: '0.4s',
  borderRadius: '4px',
  backgroundColor: '#FAFAFA',
  border: '0.5px solid #DFDFDF',
  color: '#01B3A7',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontWeight: '500',
  '& span': {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  '&:hover:not(:first-of-type)': {
    'span': {
      opacity: '0.6',
    }
  },
  'svg': {
    position: 'absolute',
    right: '-10px',
    top: '-5px',
    fill: '#F09C92'
  },
  '.MuiChip-deleteIcon': {
    margin: '0px 5px 0 -15px'
  }
}))

export const StyledIconButton = styled(IconButton)<any>(() => ({
  color: '#F09C92',
  border: '2px solid #F09C92',
  borderRadius: '4px',
  padding: '0',
  marginBottom: '10px',
}))

import { Link } from './static-link-styles'
import { styled } from '@mui/material/styles'

const sizeOptions = {
  small: '14px',
  medium: '16px',
}

interface LinkProps {
  size?: 'small' | 'medium'
}

const StaticLink = styled(Link)<LinkProps>(({ theme, size = 'medium' }) => ({
  color: theme.palette.typography.main,
  fontSize: sizeOptions[size],
}))

export default StaticLink

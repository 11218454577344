import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import DividerMui from '@mui/material/Divider'
import AccordionMui from '@mui/material/Accordion'
import AccordionSummaryMui from '@mui/material/AccordionSummary'

export const Subtitle = styled(Typography)({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  fontSize: '14px',
  lineHeight: '20px',
  color: '#353F48',
})

export const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '30px',
  color: '#353F48',
  letterSpacing: '0.4px',
})

export const PeriodTitle = styled(Typography)({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  fontSize: '14px',
  lineHeight: '24px',
  color: '#353F48',
})

export const GroupsTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#353F48',
  letterSpacing: '0.4px',
})

export const GroupsSubtitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  color: '#353F48',
  letterSpacing: '0.4px',
})

export const TagChip = styled(Chip)({
  backgroundColor: 'rgba(233, 233, 233, 0.3)',
  color: ' #757575',
  fontWeight: '600',
  height: '36px',
  borderRadius: '22px',
  marginBottom: '8px',
  marginRight: '8px',
  letterSpacing: '0.4px',
})

export const Divider = styled(DividerMui)({
  color: '#E8EBF2',
  borderBottomWidth: '2px',
})

export const QuestionText = styled(Typography)({
  fontSize: '16px',
  color: '#353F48',
})

export const Accordion = styled(AccordionMui)({
  boxShadow: 'none',
  '&::before': { content: 'none' },
  '.MuiAccordionSummary-root': {
    padding: 0,
  },
})

export const AccordionSummary = styled(AccordionSummaryMui)({
  justifyContent: 'start',
  '.MuiAccordionSummary-content': {
    flexGrow: 0,
    mr: 1,
    my: 2,
  },
})

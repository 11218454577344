import { MenuItem, FormControl, Select as SelectMui } from '@mui/material'
import { styled } from '@mui/material/styles'

interface OptionType {
  value: string
  label: string
}

// interface SelectProps {
//   name: string
//   control: Control<any>
//   options: OptionType[]
//   placeholder?: string
//   variant?: 'standard' | 'outlined' | 'filled' | undefined
//   label?: string
// }

function SelectResponder({
  name,
  placeholder = 'Selecione uma opção',
  options,
  onChange,
  value,
}: any): JSX.Element {
  return (
    <FormControl variant='standard'>
      <StyledSelectMui
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty
        color='success'
        disableUnderline
        renderValue={() => placeholder}
      >
        {options.map(({ value, label }: OptionType) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelectMui>
    </FormControl>
  )
}

const StyledSelectMui = styled(SelectMui)<any>({
  fontSize: '14px',
  '& :focus': {
    backgroundColor: 'transparent',
  },
})

export default SelectResponder

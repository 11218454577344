import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { QuestionTextField } from 'components'
import { LabelText } from './sign-up-styles'

function CaregiverFullNameStep(): JSX.Element {
  const { control } = useFormContext()

  return (
    <>
      <LabelText>Sobre você</LabelText>
      <Typography variant='question'>
        Qual o seu primeiro <b>nome</b>?
      </Typography>
      <Box mt={2} mb={10}>
        <QuestionTextField name='caregiverFirstName' control={control} />
      </Box>
      <Typography variant='question'>
        Qual o seu <b>sobrenome</b>?
      </Typography>
      <Box mt={2}>
        <QuestionTextField name='caregiverLastName' control={control} />
      </Box>
    </>
  )
}

export default CaregiverFullNameStep

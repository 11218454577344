import { Controller, Control } from 'react-hook-form'
import { MenuItem, FormControl, Select as SelectMui } from '@mui/material'
import { styled } from '@mui/material/styles'

interface OptionType {
  value: string
  label: string
}

interface SelectProps {
  name: string
  control: Control<any>
  options: OptionType[]
  placeholder?: string
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  label?: string
}

function SelectTagResult({
  name,
  control,
  placeholder = 'Selecione uma opção',
  options,
}: SelectProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant='standard'>
          <StyledSelectMui
            value={value}
            onChange={onChange}
            displayEmpty
            color='success'
            disableUnderline
            renderValue={(selected: string) => {
              const findOption = options.find(
                (option: OptionType) => selected === option.value
              )

              return findOption?.label || placeholder
            }}
          >
            {options.map(({ value, label }: OptionType) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelectMui>
        </FormControl>
      )}
    />
  )
}

const StyledSelectMui = styled(SelectMui)<any>({
  color: '#657787',
  fontSize: '16px',
  '& :focus': {
    backgroundColor: '#fff',
  },
})

export default SelectTagResult

import client from 'services/client'

export const sendLastFormByEmail = async (body: any) => {
  try {
    await client.post('/form-requests/send-email', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const configFormRequestEnvironments = async (id: string, body: any) => {
  try {
    await client.patch(`/form-requests/${id}`, body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const createFormRequest = async (body: any) => {
  try {
    await client.post('/form-requests', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

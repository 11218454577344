import { useRef, useState } from 'react'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Popper, { PopperProps } from '@mui/material/Popper'

type RecursiveMenuItemProps = MenuItemProps & {
  button?: true
  label: string
} & Pick<PopperProps, 'placement'>

const RecursiveMenuItem = (props: RecursiveMenuItemProps) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLLIElement | null>(null)

  return (
    <MenuItem
      {...props}
      ref={ref}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <span>{props.label}</span>
      <Popper anchorEl={ref.current} open={open} placement={'right'}>
        {props.children}
      </Popper>
    </MenuItem>
  )
}

const RespondersMenu = ({
  menuOptions,
  handleSelect,
  handleRemoveOption,
  selectedResponder,
}: any) => {
  return (
    <div style={{ display: 'flex' }}>
      <Paper elevation={4}>
        <MenuList>
          {menuOptions.map((menuOption: any) => {
            const hasDates = menuOption?.responseDates?.length > 0

            if (!hasDates) {
              // * Não tem outro nível de seleção
              return (
                <RecursiveMenuItem
                  autoFocus={false}
                  label={menuOption.label}
                  key={menuOption.id}
                  onClick={() => {
                    handleSelect({
                      oldResponderId: selectedResponder?.id,
                      newResponderId: menuOption.id,
                    })
                  }}
                />
              )
            }

            return (
              <RecursiveMenuItem
                autoFocus={false}
                label={menuOption.label + ' >'}
                key={menuOption.id}
              >
                <div style={{ display: 'flex' }}>
                  <Paper elevation={4}>
                    {menuOption.responseDates.map((responseDate: any) => (
                      <MenuList key={responseDate}>
                        <MenuItem
                          onClick={() => {
                            handleSelect({
                              oldResponderId: selectedResponder?.id,
                              newResponderId: menuOption.id,
                              date: [responseDate],
                            })
                          }}
                        >
                          {responseDate}
                        </MenuItem>
                      </MenuList>
                    ))}
                  </Paper>
                </div>
              </RecursiveMenuItem>
            )
          })}
          {Boolean(handleRemoveOption) && <Divider />}
          {Boolean(handleRemoveOption) && (
            <MenuItem
              onClick={() => {
                handleRemoveOption(selectedResponder)
              }}
            >
              Remover
            </MenuItem>
          )}
        </MenuList>
      </Paper>
    </div>
  )
}

export default RespondersMenu

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const Subtitle = styled(Typography)({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  fontSize: '16px',
  color: '#353F48',
})

export const AttentionText = styled(Typography)({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  fontSize: '14px',
  color: '#657787',
})

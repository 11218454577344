import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ResponderName = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.04em',
  marginLeft: '5px',
  textDecoration: 'underline',
  cursor: 'pointer',
})

import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Stack, Avatar } from '@mui/material'
import { ClinicLayout, Button, Multiselect, MultiselectObj } from 'components'
import {
  PresenceCheckbox,
  // Graphic,
  List,
  DescriptionText,
} from 'components/dashboard'
import { DashboardContext, useAuthContext } from 'contexts'
import {
  fetchPatient,
  getPatientResponders,
  getPatientResponses,
  getInformantResponses,
  getTags,
} from 'services'
import { useQuery, useMount, useNotification } from 'hooks'
import {
  extractDatesFromResponses,
  extractTagsFromQuestions,
  filterMultipleTags,
  filterFormsByDates,
  registerTrack,
} from 'helpers'
import { FETCHING, IDLE, tagsNames, ANSWERS } from 'helpers/constants'
import { Title, PatientName, PatientAge } from './dashboard-styles'

function Dashboard(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patient, setPatient] = useState<any>()
  const [responderOptions, setResponderOptions] = useState<any>([])
  const [dateOptions, setDateOptions] = useState<any>([])
  const [tagOptions, setTagOptions] = useState<any>(tagsNames)
  const [responderForms, setResponderForms] = useState<any>([])
  const [filteredForms, setFilteredForms] = useState<any>([])

  const { isMounted } = useMount()
  const { user } = useAuthContext()
  const { patient_id } = useParams()
  const navigate = useNavigate()
  const query = useQuery()
  const { warnToast } = useNotification()
  const methods = useForm<any>({
    defaultValues: {
      answersFilter: ANSWERS,
      dates: [],
      tags: [],
      sources: [],
    },
  })

  const { control, watch, setValue } = methods

  const dates = watch('dates')
  const tags = watch('tags')
  const answersFilter = watch('answersFilter')
  const selectedResponders = watch('sources')

  const trackUsersInfosPayload = useMemo(
    () => ({
      clinic_id: user.clinicId,
      patient_id: patient_id,
    }),
    [user, patient_id]
  )

  const fetchResponses = async (selectedResponders: any[]) => {
    try {
      const responses = await Promise.all(
        selectedResponders.map(({ value, isPatient }: any) =>
          isPatient
            ? getPatientResponses(value)
            : getInformantResponses(patient_id as string, value)
        )
      )

      const respondersWithResponses = selectedResponders.map(
        (selectedResponder: any, index: number) => ({
          ...selectedResponder,
          responses: responses[index],
        })
      )

      const databaseTags = await getTags()

      const dates: string[] = [
        ...new Set(
          respondersWithResponses.reduce(
            (acc: string[], { responses }: any) =>
              acc.concat(extractDatesFromResponses(responses) as any),
            []
          )
        ),
      ]

      const responsesWithClinicTags = respondersWithResponses.reduce(
        (acc: any, { responses, value, ...rest }: any) => {
          const responderResponsesWithClinicTags = responses.map(
            (response: any) => {
              const responseTags = Boolean(response.tags?.length)
                ? response.tags
                : filterMultipleTags(
                    extractTagsFromQuestions(response.questions),
                    response.questions
                  ).map((inferredTag: any) => {
                    const findDatabaseTag = databaseTags.find(
                      ({ name }: any) => inferredTag.name === name
                    )
                    return { tag: inferredTag.name, id: findDatabaseTag?.id }
                  })

              const questionsWithClinicTags = response.questions.map(
                (question: any) => {
                  return {
                    ...question,
                    tags: question.tags.filter((tag: string) =>
                      responseTags.some(
                        (responseTag: any) => responseTag.tag === tag
                      )
                    ),
                  }
                }
              )
              return {
                formRequestId: value,
                ...rest,
                ...response,
                tags: responseTags,
                questions: questionsWithClinicTags,
              }
            }
          )
          return acc.concat(responderResponsesWithClinicTags)
        },
        []
      )

      const responderForms = responsesWithClinicTags
        .slice()
        .sort(
          (respA: any, respB: any) =>
            (new Date(respA.formDateUpdate) as any) -
            (new Date(respB.formDateUpdate) as any)
        )

      setResponderForms(responderForms)
      setDateOptions(dates)
      setValue('dates', dates.length ? [dates[0]] : [])
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || 'Erro desconhecido'

      if (error?.response?.status === 401) {
        warnToast(message)
        navigate('/')
      }

      console.error(message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [patient, responders] = await Promise.all([
          fetchPatient(patient_id as string),
          getPatientResponders(patient_id as string),
        ])

        const formattedResponders = responders.map((dt: any) => {
          return {
            value: dt.id,
            label: dt.relation?.name
              ? `${dt.fullName} (${
                  (dt.relation.name === 'Sou eu'
                    ? 'paciente'
                    : dt.relation.name.toLowerCase()) || ''
                })`
              : 'Autorrelato',
            isPatient: dt.isPatient,
            name: dt.fullName,
            relation:
              dt.relation?.name === 'Sou eu'
                ? 'paciente'
                : dt.relation?.name?.toLowerCase(),
          }
        })

        setPatient(patient)
        setResponderOptions(formattedResponders)

        const patientResponder = formattedResponders.find(
          (responder: any) => responder.isPatient
        )

        setValue(
          'sources',
          patientResponder ? [patientResponder] : [formattedResponders[0]]
        )
      } catch (error: any) {
        const message =
          error?.response?.data?.message ||
          error?.message ||
          'Erro desconhecido'

        if (error?.response?.status === 401) {
          warnToast(message)
          navigate('/')
        }

        console.error(message)
      } finally {
        setStatus(IDLE)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isMounted) return

    registerTrack('Filtra Respostas', {
      ...trackUsersInfosPayload,
      selection: answersFilter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersFilter])

  useEffect(() => {
    if (!isMounted) return

    registerTrack('Altera Datas', trackUsersInfosPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates])

  useEffect(() => {
    if (!isMounted) return

    registerTrack('Altera Dominios', trackUsersInfosPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  useEffect(() => {
    if (!Boolean(selectedResponders?.length)) return
    fetchResponses(selectedResponders)

    registerTrack('Altera Informantes', trackUsersInfosPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResponders])

  useEffect(() => {
    const filteredForms = dates.length
      ? filterFormsByDates(responderForms, dates)
      : responderForms

    setFilteredForms(filteredForms)

    // TODO availableTags vai vir do back, na soma das tags dos forms
    const availableTags: any = extractTagsFromQuestions(
      filteredForms.reduce(
        (acc: any, { questions }: any) => [...acc, ...questions],
        []
      )
    )

    const tagOptions: string[] = availableTags
      .map(({ name }: any) => name)
      .sort((a: any, b: any) => a.localeCompare(b))

    setTagOptions(tagOptions)
  }, [responderForms, dates, setTagOptions, answersFilter])

  const handleNewQuestionnaire = () => {
    const patientEmail = query.get('email') || ''
    const patientName = query.get('patient_name') || ''
    const lastFormId = query.get('last_form_id') || ''

    registerTrack('Clica Novo Questionario', {
      ...trackUsersInfosPayload,
      source: 'dashboard',
    })

    navigate(
      `/invite_patient?email=${patientEmail
        .replaceAll('#', '%23')
        .replaceAll(
          '+',
          '%2B'
        )}&last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patient_id}`
    )
  }

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <DashboardContext.Provider
        value={{
          setTagOptions,
          tagOptions,
          patient,
          trackUsersInfosPayload,
        }}
      >
        <FormProvider {...methods}>
          <Box
            py={4}
            px={10}
            display='flex'
            flexDirection='column'
            height='100%'
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              pb={2}
              borderBottom='2px solid #E8EBF2'
            >
              <Title>Dashboard</Title>
              <Box width='200px' height='46px'>
                <Button variant='outlined' onClick={handleNewQuestionnaire}>
                  Enviar novo questionário
                </Button>
              </Box>
            </Box>
            <Box py={2} mb={4} display='flex' justifyContent='space-between'>
              <Box display='flex'>
                <Avatar>{patient?.firstName[0] || 'W'}</Avatar>
                <Stack ml={2}>
                  <PatientName>
                    {patient?.firstName || ''} {patient?.lastName || ''}
                  </PatientName>
                  <PatientAge>{patient?.age || '-'} anos</PatientAge>
                </Stack>
              </Box>
              <Stack direction='row' spacing={2}>
                <Box width='240px'>
                  <MultiselectObj
                    name='sources'
                    control={control}
                    options={responderOptions}
                    callbackClick={() => {
                      registerTrack(
                        'Seleciona Informantes',
                        trackUsersInfosPayload
                      )
                    }}
                  />
                </Box>
                <Box width='240px'>
                  <Multiselect
                    name='dates'
                    control={control}
                    label='Datas'
                    options={dateOptions}
                    callbackClick={() => {
                      registerTrack('Seleciona Datas', trackUsersInfosPayload)
                    }}
                  />
                </Box>
                <Box width='240px'>
                  <Multiselect
                    name='tags'
                    label='Domínios'
                    control={control}
                    options={tagOptions}
                    callbackClick={() => {
                      registerTrack(
                        'Seleciona Dominios',
                        trackUsersInfosPayload
                      )
                    }}
                  />
                </Box>
              </Stack>
            </Box>
            <DescriptionText
              patientName={patient?.firstName || 'Paciente'}
              forms={filteredForms
                .slice()
                .sort(
                  (respA: any, respB: any) =>
                    (new Date(respB.formDateUpdate) as any) -
                    (new Date(respA.formDateUpdate) as any)
                )}
            />

            <Stack
              direction='row'
              spacing={3}
              justifyContent='flex-end'
              mt={5}
              mb={3}
            >
              <PresenceCheckbox option='yes' />
              <PresenceCheckbox option='soso' />
              <PresenceCheckbox option='no' />
            </Stack>

            <Box display='flex' alignItems='flex-end'>
              {/* <Graphic forms={responderForms} width={'70vw'} /> */}
              <List forms={filteredForms} width={'70vw'} />
            </Box>
          </Box>
        </FormProvider>
      </DashboardContext.Provider>
    </ClinicLayout>
  )
}

export default Dashboard

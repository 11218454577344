import {
  MenuItem,
  FormControl,
  Select as SelectMui,
  Checkbox,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useResultsContext } from 'contexts'

interface OptionType {
  value: string
  label: string
}

function SelectResponderMultiple({
  name,
  placeholder = 'Selecione uma opção',
  options,
  onChange,
  value,
}: any): JSX.Element {
  const { showDetail } = useResultsContext()

  return (
    <FormControl variant='standard'>
      <StyledSelectMui
        name={name}
        value={value}
        onChange={(event: any) => {
          // * não permite ficar sem nenhuma selecionada
          if (event.target.value.length === 0) return

          // * limite máximo de gráficos por card quando não está no detalhamento
          if (!showDetail && event.target.value.length > 4) return

          onChange(event)
        }}
        displayEmpty
        color='success'
        disableUnderline
        renderValue={() => placeholder}
        multiple
      >
        {options.map(({ value: optValue, label }: OptionType) => (
          <MenuItem key={optValue} value={optValue}>
            <Checkbox checked={value.includes(optValue)} />
            {label}
          </MenuItem>
        ))}
      </StyledSelectMui>
    </FormControl>
  )
}

const StyledSelectMui = styled(SelectMui)<any>({
  fontSize: '14px',
  '& :focus': {
    backgroundColor: 'transparent',
  },
})

export default SelectResponderMultiple

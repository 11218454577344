import { useEffect, useState, SyntheticEvent } from 'react'
import { Box, Divider, Tab } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import {
  PersonOutlineOutlined,
  PeopleAltOutlined,
  CreditCardOutlined,
  ArticleOutlined,
} from '@mui/icons-material'
import { ClinicLayout, ClinicSidebar } from 'components'
import {
  MyInfoTab,
  AdditionalInfoTab,
  TermsTab,
} from 'components/clinic-profile-tabs'
import { Tabs } from './my-profile-styles'
import { useAuthContext } from 'contexts'
import { getClinicInfo, updateClinicInfo } from 'services'
import { registerPageAccess, registerTrack, toDate } from 'helpers'
import { FETCHING, IDLE } from 'helpers/constants'
import useFormMyProfile from './my-profile-form'
import { formatClinicFromBack } from 'helpers/formatters'
import { useNotification } from 'hooks'
import { format } from 'date-fns'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function MyProfile(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [clinic, setClinic] = useState({})
  const [activeTab, setActiveTab] = useState<number>(0)

  const { user } = useAuthContext()
  const methods = useFormMyProfile()
  const { errorToast, successToast } = useNotification()

  const { handleSubmit, reset, setValue } = methods

  const fetchData = async () => {
    try {
      setStatus(FETCHING)
      const fullClinic = await getClinicInfo()
      setClinic(formatClinicFromBack(fullClinic))
    } catch (error: any) {
      console.error(error.message)
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    reset(clinic)
  }, [reset, clinic])

  useEffect(() => {
    registerPageAccess('Perfil Clinico', { clinic_id: user.clinicId })
  }, [user])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    resetForm()
    setActiveTab(newValue)
  }

  const onSubmit = async (params: any) => {
    try {
      const body = {} as any

      if (params.validatePass) {
        body.password = params.password
      } else {
        body.personalInfo = {
          firstName: params.firstName.trim(),
          lastName: params.lastName.trim(),
          birthdate: format(toDate(params.birthdate), 'yyyy-MM-dd'),
        }
        body.register = params.register.trim()
        body.professionalCouncil = params.professionalCouncil
        body.state = params.state
        body.telephone = params.telephone.replace(/\D/g, '')
      }

      await updateClinicInfo(body)
      await fetchData()

      successToast('Alteração realizada com sucesso')
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  const resetForm = () => {
    setValue('validatePass', false)
    setValue('password', '')
    setValue('confirmPassword', '')
    reset(clinic)
  }

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <Box flexGrow={1} display='flex'>
        <ClinicSidebar clinic={clinic} />
        <Box display='flex' flexDirection='column' height='100%' width='100%'>
          <Box px={2} py={1}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'transparent',
                  borderRadius: '4px',
                },
              }}
              value={activeTab}
              onChange={handleChange}
            >
              <Tab
                icon={<PersonOutlineOutlined />}
                iconPosition='start'
                label='Meus Dados'
                onClick={() => {
                  registerTrack('Clica Meus Dados', {
                    clinic_id: user.clinicId,
                  })
                }}
                {...a11yProps(0)}
              />
              <Tab
                icon={<PeopleAltOutlined />}
                iconPosition='start'
                label={`Dados adicionais`}
                onClick={() => {
                  registerTrack('Clica Dados adicionais', {
                    clinic_id: user.clinicId,
                  })
                }}
                {...a11yProps(1)}
              />
              <Tab
                icon={<CreditCardOutlined />}
                iconPosition='start'
                label={`Dados de pagamento`}
                onClick={() => {
                  registerTrack('Clica Dados de pagamento', {
                    clinic_id: user.clinicId,
                  })
                }}
                {...a11yProps(2)}
              />
              <Tab
                icon={<ArticleOutlined />}
                iconPosition='start'
                label={`Termos de uso`}
                onClick={() => {
                  registerTrack('Clica Termos de uso', {
                    clinic_id: user.clinicId,
                  })
                }}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <Divider />
          <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <TabPanel value={activeTab} index={0}>
                <MyInfoTab resetForm={resetForm} />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <AdditionalInfoTab resetForm={resetForm} />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                {/* TODO Aba de Dados de Pagamento */}
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <TermsTab />
              </TabPanel>
            </FormProvider>
          </Box>
        </Box>
      </Box>
    </ClinicLayout>
  )
}

export default MyProfile

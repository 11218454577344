import { styled } from '@mui/material/styles'
import TooltipMui, {
  TooltipProps as TooltipPropsMui,
  tooltipClasses,
} from '@mui/material/Tooltip'

type TooltipProps = TooltipPropsMui & {
  bgColor?: string
}

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipMui {...props} classes={{ popper: className }} />
))(({ theme, bgColor = '#f5f5f9' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor,
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(12),
  },
}))

export default Tooltip

import { useState, MouseEvent } from 'react'
import { ClickAwayListener, ButtonBase, Popper } from '@mui/material'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import { styled } from '@mui/material/styles'
import { RespondersMenu } from 'components/results'
import { useNotification } from 'hooks'

function NewAddResponderMenu({
  handleAddResponder,
  menuOptions,
  abbreviated,
  disabled = false,
}: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { infoToast } = useNotification()

  const handleClick: any = (event: MouseEvent<HTMLElement>) => {
    if (disabled) infoToast('Sem resultados de outros informantes')

    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const handleSelectOption = (value: any) => {
    setAnchorEl(null)
    handleAddResponder(value)
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <MenuButton onClick={handleClick} abbreviated={abbreviated}>
          <PersonAddAlt1OutlinedIcon
            sx={{ height: '20px', marginRight: abbreviated ? 0 : '4px' }}
          />
          {!abbreviated && 'Adicionar informante'}
        </MenuButton>
        <Popper
          id={open ? 'add-responder-menu' : undefined}
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 3 }}
        >
          <RespondersMenu
            menuOptions={menuOptions}
            handleSelect={handleSelectOption}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

const MenuButton = styled(ButtonBase)<any>(({ abbreviated }) => ({
  background: '#404040',
  color: '#fff',
  height: '26px',
  width: abbreviated ? '40px' : '180px',
  borderRadius: '8px',
}))

export default NewAddResponderMenu

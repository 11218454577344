import { useState, useEffect, useMemo } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { ClinicLayout, Stepper, TagsFilter } from 'components'
import { invitePatientSteps } from 'components/invite-patient-steps'
import { InvitePatientContext } from 'contexts'
import { getTags } from 'services'
import { useQuery } from 'hooks'

function InvitePatient(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0)
  const [invitationToken, setInvitationToken] = useState()
  const [tags, setTags] = useState([])
  const [invitationSteps, setInvitationSteps] = useState(invitePatientSteps)
  const [selectedTag, setSelectedTag] = useState<any>(null) // used for filter + question step
  const [newQuestionnaireInfos, setNewQuestionnaireInfos] = useState({
    patientName: '',
    selectedQuestions: [],
    sendTo: null,
    invitationToken: null,
  })
  const query = useQuery()
  const isResend = useMemo(() => Boolean(query.get('last_form_id')), [query])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getTags()
        setTags(tags)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const patientName = query.get('patient_name')
    if (isResend) {
      setNewQuestionnaireInfos((prevInfo: any) => ({
        ...prevInfo,
        patientName,
      }))
      setInvitationSteps((prevSteps: any) => prevSteps.slice(1))
    }
  }, [isResend, query])

  const goNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const goBackStep = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <ClinicLayout>
      <Box py={4} display='flex' flexDirection='column' height='100%'>
        <Box
          mb={3}
          px={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box width={'50%'}>
            <Typography sx={{ fontSize: '1.3rem', marginBottom: '10px' }}>
              Enviar questionário para{' '}
              {!activeStep ? (
                'novo paciente'
              ) : (
                <span style={{ fontWeight: 700 }}>
                  {newQuestionnaireInfos.patientName}
                </span>
              )}
            </Typography>
            <Stepper
              steps={invitationSteps.map((step: any) => step.label)}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Box>
          <Box>
            {invitationSteps[activeStep].label === 'Selecionar perguntas' && (
              <TagsFilter tags={tags} setSelectedTag={setSelectedTag} />
            )}
          </Box>
        </Box>
        <Divider />
        <InvitePatientContext.Provider
          value={{
            goNextStep,
            goBackStep,
            invitationToken,
            setInvitationToken,
            newQuestionnaireInfos,
            setNewQuestionnaireInfos,
            selectedTag,
            setSelectedTag,
            tags,
          }}
        >
          {invitationSteps[activeStep].component}
        </InvitePatientContext.Provider>
      </Box>
    </ClinicLayout>
  )
}

export default InvitePatient

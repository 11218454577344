import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack, IconButton, Tooltip } from '@mui/material'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded'
import SendIcon from '@mui/icons-material/Send'
import { useAuthContext } from 'contexts'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import { ConfirmationModal } from 'components'
import {
  getPatientResponders,
  inactivateFormRequestFrequency,
  getForm,
} from 'services'
import { registerPageAccess } from 'helpers'
import { frequencyTextDict } from 'helpers/constants'

function PatientsTableActions({
  lastFormId,
  patientEmail,
  patientName,
  patientId,
  caregiverName,
  formRequestFrequency,
  fetchPatients,
}: any): JSX.Element {
  const [disabledDashboard, setDisabledDashboard] = useState(true)
  const [frequencyModalInfos, setFrequencyModalInfos] = useState({
    open: false,
    message: '',
  })

  const { user } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    const checkExistingResponder = async () => {
      try {
        const responders = await getPatientResponders(patientId as string)

        setDisabledDashboard(!Boolean(responders.length))
      } catch (error) {
        console.error(error)
      }
    }
    checkExistingResponder()
  }, [patientId])

  const handlePatientRecords = () => {
    registerPageAccess('Patient Records', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(`${patientId}/patient_records`)
  }

  const handleShowResults = () => {
    registerPageAccess('Resultados', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(
      `/patients/${patientId}/results?email=${patientEmail
        .replaceAll('#', '%23')
        .replaceAll(
          '+',
          '%2B'
        )}&last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patientId}`
    )
  }

  const handleNewQuestionnaire = () => {
    registerPageAccess('Novo Questionario', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(
      `/invite_patient?email=${patientEmail
        .replaceAll('#', '%23')
        .replaceAll(
          '+',
          '%2B'
        )}&last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patientId}${
        caregiverName ? `&caregiver_name=${caregiverName}` : ''
      }`
    )
  }

  const handleOpenCancelFrequencyModal = async () => {
    try {
      const form = await getForm(lastFormId)

      if (!form) throw new Error('Form not found')

      setFrequencyModalInfos({
        open: true,
        message: `O questionário com ${
          form?.questions?.length
        } perguntas está sendo enviado com frequência ${
          frequencyTextDict[formRequestFrequency.frequencyName]
        }. Você tem certeza que deseja cancelá-lo?`,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancelFrequency = async () => {
    try {
      await inactivateFormRequestFrequency(formRequestFrequency.id)

      setFrequencyModalInfos({ open: false, message: '' })

      await fetchPatients()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Stack direction='row' spacing={1} justifyContent='space-evenly'>
      <Tooltip title={'Visualizar prontuário'} placement='top' arrow>
        <IconButton
          aria-label='Visualizar prontuário'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handlePatientRecords}
        >
          <AssignmentOutlinedIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Visualizar resultados'} placement='top' arrow>
        <IconButton
          aria-label='Visualizar resultados'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handleShowResults}
          disabled={disabledDashboard}
        >
          <InsertChartOutlinedRoundedIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Cancelar recorrência'} placement='top' arrow>
        <IconButton
          aria-label='Cancelar recorrência'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handleOpenCancelFrequencyModal}
          disabled={!(formRequestFrequency && formRequestFrequency.isActive)}
        >
          <EventRepeatIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Criar novo questionário'} placement='top' arrow>
        <IconButton
          aria-label='Criar novo questionário'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handleNewQuestionnaire}
        >
          <SendIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      {frequencyModalInfos.open && (
        <ConfirmationModal
          open={frequencyModalInfos.open}
          infos={{
            title: 'Recorrência',
            message: frequencyModalInfos.message,
            confirmText: 'Sim, cancelar',
            cancelText: 'Não, manter',
          }}
          onClose={() => setFrequencyModalInfos({ open: false, message: '' })}
          onConfirm={handleCancelFrequency}
        />
      )}
    </Stack>
  )
}

export default PatientsTableActions

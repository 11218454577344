import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import {
  RouteLayout,
  Header,
  InputWithButton,
  SendMethod,
  Button,
  WhatsappForm,
  EmailForm,
} from 'components'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Typography, Stack } from '@mui/material'
import { useAuthContext } from 'contexts'
import {
  generateWhatsappPatientInviteInformantMessage,
  generateWhatsappCaregiverInviteInformantMessage,
  isUserCaregiver,
  formatUserName,
  registerTrack,
  isUserPatient,
} from 'helpers'

function SendInvite(): JSX.Element {
  const [activeSendMethod, setActiveSendMethod] = useState('')

  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location: any = useLocation()

  useEffect(() => {
    if (!location?.state?.invitationToken) navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const link = `${
    process.env.REACT_APP_WIDA_URL as string
  }/signup/informant?invitationToken=${location?.state?.invitationToken}`

  const generateWhatsappMessage = () => {
    if (isUserCaregiver(user)) {
      const invitationInfos: any = jwtDecode(location?.state?.invitationToken)
      const patientName = invitationInfos?.createdBy?.name || ''

      return generateWhatsappCaregiverInviteInformantMessage(
        formatUserName(user),
        patientName,
        location?.state?.informantName,
        link
      )
    } else {
      return generateWhatsappPatientInviteInformantMessage(
        formatUserName(user),
        location?.state?.informantName,
        link
      )
    }
  }

  const message = generateWhatsappMessage()

  return (
    <RouteLayout bgColor='white'>
      <Header color />
      <Box flexGrow={1} mx={5} my={3}>
        <Typography variant='title'>
          <b>Pronto para enviar!</b>
        </Typography>

        <Box my={2}>
          <Typography variant='text'>
            Envie o convite para o novo convidado.
          </Typography>
        </Box>

        <Stack direction='row' my={3} justifyContent='space-evenly'>
          <SendMethod
            type='whatsapp'
            handleClick={() => {
              registerTrack('Clica Whatsapp', {
                user_id: user.userId,
                source: isUserPatient(user) ? 'patient' : 'caregiver',
              })

              setActiveSendMethod('whatsapp')
            }}
            active={activeSendMethod === 'whatsapp'}
          />
          <SendMethod
            type='email'
            handleClick={() => {
              registerTrack('Clica Email', {
                user_id: user.userId,
                source: isUserPatient(user) ? 'patient' : 'caregiver',
              })

              setActiveSendMethod('email')
            }}
            active={activeSendMethod === 'email'}
          />
          <SendMethod
            type='link'
            handleClick={() => {
              registerTrack('Clica Link', {
                user_id: user.userId,
                source: isUserPatient(user) ? 'patient' : 'caregiver',
              })

              setActiveSendMethod('link')
            }}
            active={activeSendMethod === 'link'}
          />
        </Stack>

        {activeSendMethod === 'whatsapp' && (
          <WhatsappForm
            message={message}
            direction='column'
            registerTrackPayload={{
              user_id: user.userId,
              source: isUserPatient(user) ? 'patient' : 'caregiver',
            }}
          />
        )}

        {activeSendMethod === 'email' && (
          <EmailForm
            invitationToken={location?.state?.invitationToken}
            direction='column'
            registerTrackPayload={{
              user_id: user.userId,
              source: isUserPatient(user) ? 'patient' : 'caregiver',
            }}
          />
        )}

        {activeSendMethod === 'link' && (
          <InputWithButton
            size='normal'
            displayText={link || ''}
            copyValue={message.replaceAll('%0a', '\n') || ''}
            registerTrackPayload={{
              user_id: user.userId,
              source: isUserPatient(user) ? 'patient' : 'caregiver',
            }}
          />
        )}
      </Box>
      <Box mb={7} mx={5}>
        <Button
          onClick={() => {
            navigate('/')
          }}
        >
          Voltar ao início
        </Button>
      </Box>
    </RouteLayout>
  )
}

export default SendInvite

import { HeaderClinic } from 'components'
import { ClinicContainer, ContentContainer } from './clinic-layout-styles'

interface IDashboardLayoutProps {
  children: JSX.Element | JSX.Element[]
}

function ClinicLayout({ children }: IDashboardLayoutProps): JSX.Element {
  return (
    <ClinicContainer display='flex' px={3} flexDirection='column' spacing={3}>
      <HeaderClinic />
      <ContentContainer flex={1} minWidth='945px'>
        {children}
      </ContentContainer>
    </ClinicContainer>
  )
}

export default ClinicLayout

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { styled } from '@mui/material/styles'

export const SwitchContainer = styled(Box)({
  height: '40px',
  width: '240px',
  background: '#ECECEC',
  borderRadius: '40px',
  padding: '4px',
  display: 'flex',
  justifyContent: 'space-evenly',
})

export const SwitchButton = styled(ButtonBase)<any>(({ isActive }) => ({
  padding: '0px 16px',
  borderRadius: '40px',
  backgroundColor: isActive ? '#fff' : 'transparent',
  color: isActive ? '#657787' : '#353F48',
}))

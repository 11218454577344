import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, LogoLayout, SignUpHeader, StaticLink } from 'components'
import { InputField } from 'components/inputs'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { registerPageAccess } from 'helpers'
import { IFormInput } from './sign-in-types'
import useFormSignIn from './sign-in-form'

function SignIn(): JSX.Element {
  const { control, handleSubmit, watch } = useFormSignIn()
  const navigate = useNavigate()
  const { signin } = useAuthContext()
  const { successToast, errorToast } = useNotification()

  const location: any = useLocation()
  const email = watch('email')
  const pass = watch('password')

  useEffect(() => {
    registerPageAccess('Login')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        email: params.email.toLowerCase(),
        password: params.password,
      }

      await signin(body)

      successToast('Login realizado com sucesso')
      const from = (location?.state?.from?.pathname as string) || '/'
      navigate(from, { replace: true })
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}

        sx={{
          alignItems: { md: 'end' },
          width: { xs: '100%', md: '50%' },
          padding: { xs: '15%', md: '50px 10%' }
        }}
      >
        <SignUpHeader />
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          flexGrow={1}
          width='100%'
          sx={{
            padding: {
              xs: '20% 0',
              md: '0'
            }
          }}
        >
          <Typography variant='h3' color={'#657787'}>
            Acesse sua conta
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box mt={3} mb={2}>
              <InputField
                name='email'
                control={control}
                label='E-mail'
                invalidChars={[' ']}
              />
            </Box>
            <Box mb={7}>
              <InputField type='password' name='password' control={control} label='Senha' />
            </Box>
            <Button disabled={!email || !pass} type='submit' style={{ width: '50%' }}>Entrar</Button>
          </Box>
          <Box mt={7}>
            <StaticLink to='/password_reset'>
              Esqueci minha senha
            </StaticLink>
          </Box>
        </Box>
      </Box>
    </LogoLayout >
  )
}

export default SignIn

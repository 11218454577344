import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { QuestionTextField } from 'components'

function NameStep(): JSX.Element {
  const { control } = useFormContext()

  return (
    <>
      <Typography variant='question'>
        <b>Quem você quer convidar?</b>
      </Typography>
      <Box mt={2}>
        <QuestionTextField
          name='name'
          control={control}
          placeholder='Digite o nome aqui'
        />
      </Box>
    </>
  )
}

export default NameStep

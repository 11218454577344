import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Title } from './questionnaire-category-styles'
import { Button, RouteLayout, Header } from 'components'
import { isUserPatient, registerTrack } from 'helpers'
import { useAuthContext } from 'contexts'

function QuestionnaireCategory({
  block,
  type,
  handleGoNext,
  handleGoBack,
  patientInfo,
}: any): JSX.Element {
  const { user } = useAuthContext()

  useEffect(() => {
    registerTrack('Inicia Bloco', {
      user_id: user.userId,
      patient_id: patientInfo.patientId,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
      block_name: block,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouteLayout bgColor='green'>
      <Header
        hasGoBackButton
        hasProfileButton
        disabledGoHome
        handleBack={handleGoBack}
      />
      <Box m={5} flexGrow={1} display='flex' flexDirection='column'>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='column'
          justifyContent='center'
        >
          <Title>
            {type === 'specific' ? (
              <>
                Vamos falar sobre <b>{block}</b>
              </>
            ) : (
              <b>E por último queremos saber...</b>
            )}
          </Title>
        </Box>
        <Button variant='outlined' color='white' onClick={handleGoNext}>
          Próximo
        </Button>
      </Box>
    </RouteLayout>
  )
}

export default QuestionnaireCategory

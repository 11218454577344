import { useForm } from 'react-hook-form'
import { clinicInfoResolver } from 'resolvers'
import { IMyProfileForm } from './my-profile-types'

function useFormMyProfile() {
  const methods = useForm<IMyProfileForm>({
    mode: 'onBlur',
    resolver: clinicInfoResolver,
    defaultValues: {
      firstName: '',
      lastName: '',
      telephone: '',
      state: '',
      validatePass: false,
      password: '',
      confirmPassword: '',
      register: '',
      professionalCouncil: '',
      birthdate: '',
    },
  })

  return methods
}

export default useFormMyProfile

import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const BackButton = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '21px',
  cursor: 'pointer',
  color: '#F36F60',
  transition: '0.4s',
  '&:hover': {
    opacity: '0.6'
  }
})

import { Avatar } from '@mui/material'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { SidebarContainer, FirstName, LastName } from './clinic-sidebar-styles'
import { useAuthContext } from 'contexts'
import { IconLink } from 'components'


const Sidebar = ({ clinic }: any) => {
  const { signout } = useAuthContext()
  const { firstName, lastName } = clinic

  return (
    <SidebarContainer>
      <Avatar
        variant="rounded"
        sx={{
          borderRadius: '14px',
          fontSize: '48px',
          marginBottom: '20px',
          width: 160,
          height: 160,
        }}
      >
        {firstName[0] || 'W'}
      </Avatar>
      <FirstName>{firstName}</FirstName>
      <LastName>{lastName}</LastName>
      <IconLink
        startIcon={<LogoutOutlinedIcon />}
        onClick={signout}
      >
        Sair
      </IconLink>
    </SidebarContainer>
  )
}

export default Sidebar

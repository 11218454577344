import { Controller, Control } from 'react-hook-form'
import {
  MenuItem,
  FormControl,
  Select as SelectMui,
  FormHelperText,
  Box,
  InputLabel
} from '@mui/material'
import { StyledInput } from './select-field-styles'

interface OptionType {
  value: string
  label: string
}

interface SelectProps {
  name: string
  control: Control<any>
  options: OptionType[]
  disabled?: boolean
  label?: string
}

function SelectField({
  name,
  control,
  options,
  disabled,
  label = '',
}: SelectProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <FormControl
            variant="standard"
            fullWidth
            error={invalid}
          >
            <InputLabel id={name} error={invalid} style={{ color: '#353F48', fontSize: '14px' }}>{label}</InputLabel>
            <SelectMui
              disabled={disabled}
              id={name}
              input={<StyledInput />}
              value={value}
              onChange={onChange}
              displayEmpty
              color='primary'
              sx={disabled ? {
                '&&&::before': { border: 'none' },
                svg: { display: 'none' }
              } : void 0}
              renderValue={(selected: string) => {
                const findOption = options.find(
                  (option: OptionType) => selected === option.value
                )

                return findOption?.label
              }}
            >
              {options.map(({ value, label }: OptionType) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SelectMui>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        </Box>
      )}
    />
  )
}

export default SelectField

import { Fragment } from 'react'
import { Box } from '@mui/material'
import { useDashboardContext } from 'contexts'
import { generateLongitudinalInfos, generateTransversalInfos } from 'helpers'
import { tagsColor } from 'helpers/constants'
import { Infos, TagName, Emphasis } from './longitudinal-infos-styles'

function LongitudinalInfos({ patientName }: any): JSX.Element {
  const { longitudinalInfos, responder } = useDashboardContext()

  const renderTagName = (
    tagName: string,
    qtdLabel: string | undefined,
    i: number,
    tagsLength: number
  ) => {
    return (
      <Fragment key={tagName}>
        {i !== 0 && (i !== tagsLength - 1 ? ', ' : ' e ')}
        <TagName as='span' color={tagsColor[tagName]}>
          {tagName}
          {qtdLabel ? ` (${qtdLabel})` : ''}
        </TagName>
      </Fragment>
    )
  }

  const renderTransversalTagName = (tagName: string, i: number) => {
    return (
      <Fragment key={tagName}>
        {i !== 0 &&
          (i !== generatedTransversalInfos!.presents!.relevantTags.length - 1
            ? ', '
            : ' e ')}
        <TagName as='span' color={tagsColor[tagName]}>
          {tagName}
        </TagName>
      </Fragment>
    )
  }

  const renderDecreaseTags = () =>
    generatedLongitudinalInfos!.presents!.decrease.map((tag: any, i: number) =>
      renderTagName(
        tag.tagName,
        tag.qtdLabel,
        i,
        generatedLongitudinalInfos!.presents!.decrease.length
      )
    )

  const renderIncreaseTags = () =>
    generatedLongitudinalInfos!.presents!.increase.map((tag: any, i: number) =>
      renderTagName(
        tag.tagName,
        tag.qtdLabel,
        i,
        generatedLongitudinalInfos!.presents!.increase.length
      )
    )

  const renderNoChangeTags = () =>
    generatedLongitudinalInfos!.presents!.noChange.map((tag: any, i: number) =>
      renderTagName(
        tag.tagName,
        tag.qtdLabel,
        i,
        generatedLongitudinalInfos!.presents!.noChange.length
      )
    )

  const renderAbsentTags = () =>
    generatedLongitudinalInfos!.absents!.map((tag: any, i: number) =>
      renderTagName(
        tag,
        undefined,
        i,
        generatedLongitudinalInfos!.absents!.length
      )
    )

  const renderTagProportion = (tag: any, i: number) => {
    return (
      <Fragment key={tag.name}>
        {i !== 0 &&
          (i !== generatedTransversalInfos!.presents!.relevantTags.length - 1
            ? ', '
            : ' e ')}
        <TagName as='span' color={tagsColor[tag.name]}>
          {tag.label}
        </TagName>
      </Fragment>
    )
  }

  if (!longitudinalInfos || !Boolean(longitudinalInfos?.length)) return <></>

  const generatedLongitudinalInfos = generateLongitudinalInfos(
    longitudinalInfos,
    responder,
    patientName
  )

  const generatedTransversalInfos = generateTransversalInfos(
    longitudinalInfos[longitudinalInfos.length - 1],
    responder,
    patientName
  )

  const hasIncrease = Boolean(
    generatedLongitudinalInfos!.presents!.increase.length
  )
  const hasDecrease = Boolean(
    generatedLongitudinalInfos!.presents!.decrease.length
  )
  const hasNoChange = Boolean(
    generatedLongitudinalInfos!.presents!.noChange.length
  )
  const hasAbsent = Boolean(generatedLongitudinalInfos!.absents!.length)

  const totalPresent = generatedTransversalInfos!.presents!.total

  return (
    <Box>
      <Infos>
        No período de {generatedLongitudinalInfos.answerDateFirst} a{' '}
        {generatedLongitudinalInfos.answerDateLast}{' '}
        {generatedLongitudinalInfos.responderName}
        {generatedLongitudinalInfos.isResponderPatient
          ? ' '
          : ` (${responder.relation || ''}) `}
        respondeu{' '}
        <Emphasis as='span'>
          {generatedLongitudinalInfos.totalForms}{' '}
          {generatedLongitudinalInfos.totalForms > 1
            ? 'rodadas de perguntas'
            : 'rodada de perguntas'}
        </Emphasis>
        {generatedLongitudinalInfos.isResponderPatient
          ? ''
          : ` sobre ${generatedLongitudinalInfos.patientName}`}
        .{' '}
        {hasIncrease || hasDecrease ? (
          <>
            {generatedLongitudinalInfos.isResponderPatient
              ? 'Comparando as respostas fornecidas na primeira e na última data'
              : 'Comparando as perguntas feitas em comum tanto na primeira quanto na última data,'}

            <>
              {hasDecrease && (
                <>
                  {' '}
                  houve redução no número de itens presentes{' '}
                  {generatedLongitudinalInfos!.presents!.decrease.length > 1
                    ? 'nos domínios'
                    : 'no domínio'}{' '}
                  {renderDecreaseTags()}
                </>
              )}
              {hasIncrease && (
                <>
                  {hasDecrease ? ' e ' : ' houve '}
                  aumento{' '}
                  {generatedLongitudinalInfos!.presents!.increase.length > 1
                    ? 'nos domínios'
                    : 'no domínio'}{' '}
                  {renderIncreaseTags()}
                </>
              )}
              .
            </>
          </>
        ) : null}
        {hasNoChange && (
          <>
            <br />O
            {generatedLongitudinalInfos!.presents!.noChange.length > 1
              ? 's domínios '
              : ' domínio '}
            {renderNoChangeTags()} não{' '}
            {generatedLongitudinalInfos!.presents!.noChange.length > 1
              ? 'apresentaram'
              : 'apresentou'}{' '}
            flutuação no número de itens presentes entre as duas datas.
          </>
        )}
        {hasAbsent && (
          <>
            <br />O
            {generatedLongitudinalInfos!.absents!.length > 1
              ? 's domínios '
              : ' domínio '}
            {renderAbsentTags()} não{' '}
            {generatedLongitudinalInfos!.absents!.length > 1
              ? 'apresentaram'
              : 'apresentou'}{' '}
            dados comparáveis nas datas selecionadas.
          </>
        )}
        <br />
        <Infos>
          Em {generatedTransversalInfos.answerDate},{' '}
          {generatedTransversalInfos.responderName}{' '}
          {generatedTransversalInfos.isResponderPatient
            ? ' '
            : ` (${responder.relation || ''}) `}
          respondeu {generatedTransversalInfos.totalQuestions}{' '}
          {generatedTransversalInfos.totalQuestions > 1
            ? 'perguntas'
            : 'pergunta'}{' '}
          {generatedTransversalInfos.isResponderPatient
            ? ', '
            : `sobre ${generatedTransversalInfos.patientName}, `}
          {totalPresent ? (
            <>
              {`identificando como presente${totalPresent > 1 ? 's' : ''}`}
              <>
                {' '}
                <Emphasis as='span'>
                  {totalPresent} {totalPresent > 1 ? 'itens' : 'item'}
                </Emphasis>
                {Boolean(
                  generatedTransversalInfos!.presents!.relevantTags.length
                ) ? (
                  <>
                    ,{' '}
                    {generatedTransversalInfos!.presents!.hasMoreTags
                      ? 'predominantemente'
                      : ''}{' '}
                    {totalPresent > 1 ? 'referentes' : 'referente'} a{' '}
                    {generatedTransversalInfos!.presents!.relevantTags.map(
                      renderTransversalTagName
                    )}
                    {generatedTransversalInfos!.presents!.hasMoreTags
                      ? ', entre outros domínios. '
                      : '. '}
                    A proporção de itens presentes foi maior{' '}
                    {generatedTransversalInfos!.presents!.proportion.length > 1
                      ? 'nos domínios'
                      : 'no domínio'}{' '}
                    {generatedTransversalInfos!.presents!.proportion.map(
                      renderTagProportion
                    )}
                    .
                  </>
                ) : (
                  <>.</>
                )}
              </>
            </>
          ) : (
            'não identificando nenhum item como presente.'
          )}
        </Infos>
      </Infos>
    </Box>
  )
}

export default LongitudinalInfos

import '../rich-text.css'
import '../../../../../node_modules/draft-js/dist/Draft.css'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useState } from 'react'
import { ConfirmationModal, Button } from 'components'

function RichTextFooter({
  saveStatus,
  handleFinish,
  canFinish,
  save,
}: any): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false)

  const getMessage = () => {
    if (saveStatus === 'saving') {
      return 'Salvando alterações...'
    }
    return 'Processando...'
  }

  const confirmCloseModal = async () => {
    setModalOpen(false)
    handleFinish()
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box display={'flex'} alignItems={'center'}>
        {saveStatus !== 'no' &&
          (saveStatus === 'done' ? (
            <>
              <Check
                sx={{ color: '#01B3A7', marginRight: '10px', fontSize: '15px' }}
              />
              <Typography variant='caption'>Alterações salvas!</Typography>
            </>
          ) : (
            <>
              <CircularProgress
                size={'10px'}
                sx={{ color: '#01B3A7', marginRight: '10px' }}
              />
              <Typography variant='caption'>{getMessage()}</Typography>
            </>
          ))}
      </Box>
      <Box display={'flex'}>
        <Button variant={'text'} onClick={save} sx={{ width: 'auto', mx: 3 }}>
          Salvar
        </Button>
        <Button onClick={() => setModalOpen(true)} disabled={!canFinish}>
          Finalizar registro
        </Button>
      </Box>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          infos={{
            title: 'Finalizar registro',
            message:
              'Após finalizar, não será possível alterar ou excluir o registro. Deseja prosseguir?',
            confirmText: 'Finalizar',
            cancelText: 'Voltar',
          }}
          variant={'secondary'}
          onClose={() => setModalOpen(false)}
          onConfirm={confirmCloseModal}
        />
      )}
    </Box>
  )
}

export default RichTextFooter

import { useMemo } from 'react'
import { Box, Stack, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { formatDateToLabel, orderFormattedDates } from 'helpers'
import {
  NewAddResponderMenu,
  NewChangeResponderMenu,
  SelectResponder,
  SelectResponderMultiple,
} from 'components/results'

const NewRespondersMenu = ({
  type,
  menuOptions,
  respondersOptions,
  handleAddResponder,
  handleRemoveResponder,
  handleChangeOption,
  selectedResponders,
}: any) => {
  const disabledMenuAdd = useMemo(() => menuOptions.length === 0, [menuOptions])

  return (
    <Container>
      <RespondersContainer direction='row'>
        {selectedResponders.map((selectedResponder: any) => {
          const responderOptions =
            respondersOptions.find(
              (menuOption: any) => menuOption.id === selectedResponder.id
            )?.responseDates || []

          return (
            <SelectResponderContainer key={selectedResponder.id}>
              {selectedResponders.length > 1 && (
                <IconButton
                  onClick={() => {
                    handleRemoveResponder(selectedResponder)
                  }}
                  sx={{ height: '22px', width: '22px' }}
                >
                  <CloseRoundedIcon sx={{ height: '18px', width: '18px' }} />
                </IconButton>
              )}
              {type === 'transv' ? (
                <SelectResponder
                  key={selectedResponder.id}
                  name={selectedResponder.id}
                  placeholder={selectedResponder.label}
                  options={responderOptions.map((date: string) => ({
                    value: date,
                    label: formatDateToLabel(date),
                  }))}
                  value={selectedResponder.responseDate[0]}
                  onChange={({ target: { value } }: any) => {
                    handleChangeOption({
                      oldResponderId: selectedResponder.id,
                      newResponderId: selectedResponder.id,
                      date: [value],
                    })
                  }}
                />
              ) : (
                <SelectResponderMultiple
                  key={selectedResponder.id}
                  name={selectedResponder.id}
                  placeholder={selectedResponder.label}
                  options={responderOptions.map((date: string) => ({
                    value: date,
                    label: formatDateToLabel(date),
                  }))}
                  value={selectedResponder.responseDate}
                  onChange={({ target: { value } }: any) => {
                    const orderNewDates = orderFormattedDates(value)

                    handleChangeOption({
                      oldResponderId: selectedResponder.id,
                      newResponderId: selectedResponder.id,
                      date: orderNewDates,
                    })
                  }}
                />
              )}
            </SelectResponderContainer>
          )
        })}
      </RespondersContainer>
      <Box m={1}>
        {type === 'transv' ? (
          <NewAddResponderMenu
            menuOptions={menuOptions}
            handleAddResponder={handleAddResponder}
            abbreviated={selectedResponders.length > 1}
            disabled={disabledMenuAdd}
          />
        ) : (
          <NewChangeResponderMenu
            oldResponder={selectedResponders[0]}
            menuOptions={menuOptions}
            handleChangeResponder={handleChangeOption}
            abbreviated={selectedResponders.length > 1}
            disabled={disabledMenuAdd}
          />
        )}
      </Box>
    </Container>
  )
}

const Container = styled(Box)({
  borderRadius: '12px',
  background: '#F9F9F9',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const RespondersContainer = styled(Stack)({
  flexWrap: 'wrap',
})

const SelectResponderContainer = styled(Box)({
  borderRadius: '8px',
  backgroundColor: '#ECECEC',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  margin: '8px',
  padding: '8px',
})

export default NewRespondersMenu

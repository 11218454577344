import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { capitalizeWordsOnSentence } from 'helpers'
import { Controller, useFormContext } from 'react-hook-form'
import { Subtitle } from './questionnaire-groups-container-styles'
import { IEnvironmentOption } from 'types'

interface QuestionnaireGroupsModalProps {
  tags: any
  selectedQuestions: any
  environmentOptions: any
}

function QuestionnaireGroupsContainer({
  tags,
  selectedQuestions,
  environmentOptions,
}: QuestionnaireGroupsModalProps) {
  const { control, watch } = useFormContext()

  const selectedCounter = (env: any) => {
    const tagsQuestions = selectedQuestions.filter((question: any) => {
      return env.some((selectedGroupTag: any) => {
        const findTag = tags.find((tag: any) => tag.label === selectedGroupTag)
        return question.tags.includes(findTag.value)
      })
    })

    return tagsQuestions.length
  }

  return (
    <Box mt={3}>
      <Table>
        <TableHead sx={{ borderBottom: '1.5px solid #353F48' }}>
          <TableRow>
            <TableCell></TableCell>
            {environmentOptions.map((environmentOption: IEnvironmentOption) => {
              const selectedGroupTags: any = watch(
                `selectedGroupTags.${environmentOption.value}`
              )
              const counter = selectedCounter(selectedGroupTags)

              return (
                <TableCell key={environmentOption.label}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    {environmentOption.label}
                    <Subtitle
                      sx={{ textAlign: 'center' }}
                    >{`${counter} pergunta${counter < 2 ? '' : 's'}`}</Subtitle>
                  </Box>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tags.map((tag: any) => (
            <TableRow key={tag.label}>
              <TableCell>{capitalizeWordsOnSentence(tag.label)}</TableCell>
              {environmentOptions.map((env: IEnvironmentOption) => {
                return (
                  <Controller
                    key={env.label}
                    control={control}
                    name={`selectedGroupTags.${env.value}`}
                    render={({
                      field: { value: selectedOptions, onChange },
                      fieldState: { invalid, error },
                    }) => {
                      const onChangeCheckbox = (checked: boolean) => {
                        if (checked) {
                          selectedOptions.push(tag.label)
                        } else {
                          const remove = selectedOptions.indexOf(tag.label)
                          selectedOptions.splice(remove, 1)
                        }
                        onChange(selectedOptions)
                      }

                      return (
                        <TableCell
                          sx={{
                            '.MuiCheckbox-root': { borderRadius: '4px' },
                          }}
                        >
                          <FormControl
                            color='secondary'
                            error={invalid}
                            fullWidth
                          >
                            <Checkbox
                              checked={selectedOptions?.indexOf(tag.label) > -1}
                              onChange={(e: any) => {
                                onChangeCheckbox(e.target.checked)
                              }}
                            />
                            {invalid && (
                              <FormHelperText>{error?.message}</FormHelperText>
                            )}
                          </FormControl>
                        </TableCell>
                      )
                    }}
                  />
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default QuestionnaireGroupsContainer

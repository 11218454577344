import { useNavigate } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import { Box } from '@mui/material'
import { Button, LogoLayout, PasswordField } from 'components'
import { useNotification, useQuery } from 'hooks'
import { resetPassword } from 'services'
import useFormForgotPassword from './forgot-password-form'
import { IFormInput } from './forgot-password-types'
import { Title } from './forgot-password-styles'

function ForgotPassword(): JSX.Element {
  const { control, handleSubmit } = useFormForgotPassword()
  const { successToast, errorToast } = useNotification()
  const query = useQuery()
  const navigation = useNavigate()

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        password: params.password,
      }

      await resetPassword(query.get('token') || '', body)

      successToast('Senha redefinida com sucesso!')
      navigation('/signin')
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        flexGrow={1}
        width={{ xs: '100%', md: '50%' }}
      >
        <Box my={3}>
          <Title>Esqueceu a senha?</Title>
        </Box>
        <Box
          component='form'
          display='flex'
          flexDirection='column'
          onSubmit={handleSubmit(onSubmit)}
          width={{ xs: '80%', md: '50%' }}
        >
          <Box mb={2} width='100%'>
            <PasswordField
              name='password'
              control={control}
              label='Crie uma nova senha'
            />
          </Box>
          <Box mb={5} width='100%'>
            <PasswordField
              name='confirmPassword'
              control={control}
              label='Confirme sua nova senha'
            />
          </Box>
          <Button type='submit'>Acessar minha conta</Button>
        </Box>
      </Box>
    </LogoLayout>
  )
}

export default ForgotPassword

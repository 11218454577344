import { styled } from '@mui/material/styles'
import { Tabs as TabsMui } from '@mui/material'

export const Tabs = styled(TabsMui)`
    button {
      min-height: 40px;
      border-radius: 5px;
      text-transform: none;
    }
    button[aria-selected="true"]{
      color: #353F48;
      background-color: #F9F9FB;

      svg {
        color: #F36F60;
      }
    }
    button[aria-selected="false"]{
      color: #657787;
    }
  `

import { Box } from '@mui/material'
import { LogoAnimation } from './logo-animation'
import LogoTextContainer from './logo-text-container/logo-text-container'
import logoColor from 'images/wida-logo-color.svg'
import logoW from 'images/w.svg'

interface IDashboardLayoutProps {
  children: JSX.Element
}

function LogoLayout({ children }: IDashboardLayoutProps): JSX.Element {
  return (
    <Box
      display='flex'
      height={window.innerHeight}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <LogoAnimation
        width={{ xs: '100%', md: '50%' }}
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <img
            src={logoW}
            alt='Wida'
            height={'65%'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />

        </Box>
        <Box
          display='flex'
          sx={{
            height: { md: window.innerHeight },
            flexDirection: { xs: 'row', md: 'column' },
            justifyContent: { xs: 'center', md: 'space-between' },
            padding: { xs: '20% 0', md: '65px' }
          }}
          style={{
            background: 'linear-gradient(330deg, rgba(168,172,207,0) 0%, rgb(241, 242, 252) 80%)',
          }}
        >
          <Box>
            <img src={logoColor} alt='Wida Logo' height={38} />
          </Box>
          <LogoTextContainer />
        </Box>
      </LogoAnimation >
      {children}
    </Box>
  )
}

export default LogoLayout

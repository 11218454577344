export const tagsColor: any = {
  'ansiedade generalizada': '#CF8FC0',
  depressão: '#5371AB',
  'desatenção/hiperatividade': '#D1885C',
  'obsessões e compulsões': '#87C18B',
  mania: '#DC5854',
  'oposição e desafio': '#7F73AF',
  autismo: '#5EA1BB',
  'ansiedade social': '#DDCF95',
  sono: '#8F7963',
  'irritabilidade e agressividade': '#8C8C8B',
}

export const tagsNames = [
  'ansiedade generalizada',
  'depressão',
  'desatenção/hiperatividade',
  'obsessões e compulsões',
  'mania',
  'oposição e desafio',
  'autismo',
  'ansiedade social',
  'sono',
  'irritabilidade e agressividade',
]

export const tagsSizes: any = {
  'ansiedade generalizada': 10,
  depressão: 25,
  'desatenção/hiperatividade': 18,
  'obsessões e compulsões': 7,
  mania: 12,
  'oposição e desafio': 8,
  autismo: 22,
  'ansiedade social': 11,
  sono: 8,
  'irritabilidade e agressividade': 8,
}

import { Draggable } from 'react-beautiful-dnd'
import { format } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import brLocale from 'date-fns/locale/pt-BR'
import { Box, Stack } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { AnswerLabel } from 'components'
import { Graphic } from 'components/results'
import { ANSWERS } from 'helpers/constants'
import { capitalizeWordsOnSentence } from 'helpers'
import { useResultsContext } from 'contexts'
import {
  Container,
  DividerLineTransv,
  TagName,
  DateLabel,
  ResponderName,
  ResponderRelation,
  DetailButton,
  InfosContainer,
  DividerLineLong,
} from './tag-card-styles'

function TagCard({ tag, index, responses }: any): JSX.Element {
  const { activeProportion, selectedResponders } = useResultsContext()
  const { setValue, watch } = useFormContext()

  const selectedType = watch('type')

  const hasMultipleAnswers = responses.length > 1

  const tagName = capitalizeWordsOnSentence(tag.name)

  const handleClickDetail = () => {
    setValue('tag', tag.id)
  }

  return (
    <Draggable draggableId={tag.id} index={index}>
      {(provided: any, snapshot: any) => {
        return (
          <Container
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <InfosContainer>
              {snapshot.isDragging && (
                <DragIndicatorIcon
                  style={{ fill: '#BDBDBD', marginTop: '-24px' }}
                />
              )}
              <Box mb={1}>
                <TagName>{tagName}</TagName>
              </Box>
              <Stack direction={'row'} spacing={8}>
                {responses.map((response: any) => {
                  const firstName = response.responder.name.split(' ')[0]

                  return (
                    <Stack
                      alignItems='center'
                      key={response.responder.id + response.responseDate}
                    >
                      <Graphic
                        quantities={response.quantities}
                        maxTagQuestions={tag.maxQuestions}
                        isProportional={activeProportion}
                      />
                      {selectedType === 'transv' && <DividerLineTransv />}

                      {hasMultipleAnswers && (
                        <Stack alignItems='center' mt={1}>
                          {selectedType === 'transv' ? (
                            <>
                              <ResponderName>{firstName}</ResponderName>
                              <ResponderRelation>
                                ({response.responder.relation})
                              </ResponderRelation>
                            </>
                          ) : (
                            <Box height={32} />
                          )}
                          <Box mt={0.5}>
                            <DateLabel>
                              {format(
                                new Date(response.responseDate),
                                'dd MMM yy',
                                { locale: brLocale }
                              )}
                            </DateLabel>
                          </Box>
                        </Stack>
                      )}
                    </Stack>
                  )
                })}
              </Stack>

              {selectedType === 'long' && (
                <DividerLineLong hasMultipleAnswers={hasMultipleAnswers} />
              )}

              {!hasMultipleAnswers && (
                <>
                  {selectedType !== 'long' ? (
                    <Stack
                      direction={'row'}
                      spacing={1}
                      mt={selectedResponders.length <= 1 ? 2 : 1}
                    >
                      {selectedResponders.length <= 1 ? (
                        ANSWERS.map((answer: string) => (
                          <AnswerLabel
                            key={answer}
                            answer={answer}
                            qtd={responses[0]?.quantities[answer] || 0}
                          />
                        ))
                      ) : (
                        <Stack alignItems='center'>
                          <ResponderName>
                            {responses[0]?.responder.name.split(' ')[0]}
                          </ResponderName>
                          <ResponderRelation>
                            ({responses[0]?.responder.relation})
                          </ResponderRelation>
                        </Stack>
                      )}
                    </Stack>
                  ) : (
                    <Box height={36} />
                  )}
                  <Box mt={1}>
                    <DateLabel>
                      {responses[0]?.responseDate &&
                        format(
                          new Date(responses[0].responseDate),
                          'dd MMM yy',
                          {
                            locale: brLocale,
                          }
                        )}
                    </DateLabel>
                  </Box>
                </>
              )}
            </InfosContainer>
            <DetailButton onClick={handleClickDetail}>+</DetailButton>
          </Container>
        )
      }}
    </Draggable>
  )
}

export default TagCard

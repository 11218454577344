import { Fragment, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { generateDescriptionInfos, formatDateToGraphic } from 'helpers'
import { tagsColor } from 'helpers/constants'
import { Infos, TagName, Title } from './description-text-styles'

function DescriptionText({ patientName, forms }: any): JSX.Element {
  const [expanded, setExpanded] = useState(false)

  const renderTagName = ({ tag }: any, i: number, array: any) => {
    return (
      <Fragment key={tag}>
        {i !== 0 && (i !== array.length - 1 ? ', ' : ' e ')}
        <TagName as='span' color={tagsColor[tag]}>
          {tag}
        </TagName>
      </Fragment>
    )
  }

  return (
    <Box>
      {forms.slice(0, expanded ? forms.length : 1).map((form: any) => {
        const generatedDescriptionInfos = generateDescriptionInfos(
          form,
          patientName
        )

        const { answerDate, responder, tags } = generatedDescriptionInfos

        const responderName = `${responder!.name} ${
          responder?.isPatient ? ' ' : ` (${responder!.relation || ''}) `
        }`

        return (
          <Infos key={form.formRequestId}>
            <Title>
              {responderName} - {formatDateToGraphic(form.formDateUpdate)}
            </Title>
            <>
              Em {answerDate}, {responderName} respondeu a perguntas sobre{' '}
              {tags.map(renderTagName)}.{' '}
            </>
            <>
              {tags.map((tagInfo: any) => {
                const partialText =
                  tagInfo.partial.total > 0
                    ? ` Considerou ${tagInfo.partial.total} ${
                        tagInfo.partial.total > 1
                          ? 'itens como sublimiares'
                          : 'item como sublimiar'
                      } (${tagInfo.partial.text}). `
                    : ' '

                if (tagInfo.present.total > 0) {
                  return (
                    <Fragment key={tagInfo.id}>
                      Para{' '}
                      <TagName as='span' color={tagsColor[tagInfo.tag]}>
                        {tagInfo.tag}
                      </TagName>
                      , relatou a presença de {tagInfo.present.total} de{' '}
                      {tagInfo.total}{' '}
                      {tagInfo.total > 1 ? 'itens avaliados' : 'item avaliado'}{' '}
                      ({tagInfo.present.text}).{partialText}
                    </Fragment>
                  )
                } else {
                  return (
                    <Fragment key={tagInfo.id}>
                      Quanto a{' '}
                      <TagName as='span' color={tagsColor[tagInfo.tag]}>
                        {tagInfo.tag}
                      </TagName>
                      , não relatou a presença de nenhum dos {tagInfo.total}{' '}
                      {tagInfo.total > 1
                        ? 'itens avaliados.'
                        : 'item avaliado.'}
                      {partialText}
                    </Fragment>
                  )
                }
              })}
            </>
          </Infos>
        )
      })}
      <IconButton
        aria-label='expand or retract'
        onClick={() => setExpanded(!expanded)}
      >
        {forms.length > 1 &&
          (expanded ? (
            <RemoveCircleOutlineOutlinedIcon />
          ) : (
            <AddCircleOutlineOutlinedIcon />
          ))}
      </IconButton>
    </Box>
  )
}

export default DescriptionText

import { Controller, Control } from 'react-hook-form'
import {
  MenuItem,
  FormControl,
  Select as SelectMui,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material'

interface OptionType {
  value: string
  label: string
}

interface SelectProps {
  name: string
  control: Control<any>
  options: OptionType[]
  placeholder?: string
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  label?: string
}

function Select({
  name,
  control,
  placeholder = 'Selecione uma opção',
  options,
  variant = 'standard',
  label = '',
}: SelectProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <FormControl
          color='secondary'
          variant={variant}
          fullWidth
          error={invalid}
        >
          {label && <InputLabel id={name}>{label}</InputLabel>}
          <SelectMui
            {...(label && { labelId: name })}
            {...(label && { input: <OutlinedInput label={label} /> })}
            value={value}
            onChange={onChange}
            displayEmpty
            color='secondary'
            renderValue={(selected: string) => {
              const findOption = options.find(
                (option: OptionType) => selected === option.value
              )

              return findOption?.label || (label ? '' : placeholder)
            }}
          >
            {options.map(({ value, label }: OptionType) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectMui>
          {invalid && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default Select

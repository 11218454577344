import { useEffect, useState, KeyboardEvent } from 'react'
import { FormProvider, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Box } from '@mui/material'
import { Button, Header, RouteLayout } from 'components'
import { useNotification, useQuery } from 'hooks'
import { useAuthContext } from 'contexts'
import { registerPageAccess } from 'helpers'
import useSignUpInformantForm from './use-sign-up-informant-form'
import { informantSteps } from 'components/sign-up-steps'

function SignUpInformant(): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState<number>(0)

  const navigate = useNavigate()
  const { successToast, warnToast, errorToast } = useNotification()
  const query = useQuery()
  const methods = useSignUpInformantForm()
  const { signup } = useAuthContext()

  const { setValue, handleSubmit, trigger, watch } = methods

  const invitationToken = query.get('invitationToken')

  const relation = watch('relation')

  useEffect(() => {
    if (!relation) return

    const selectedRelation = JSON.parse(relation)
    if (selectedRelation?.label === 'Sou eu') {
      setValue('environment', '')
    }
  }, [relation, setValue])

  useEffect(() => {
    if (!invitationToken) {
      warnToast('Você precisa de um convite para acessar essa página')
      navigate('/')
    }

    const userInfos: any = jwtDecode(invitationToken!)

    const names = userInfos?.name?.split(' ')

    setValue('firstName', names?.shift() || '')
    setValue('lastName', names?.join(' ') || '')

    setLoading(false)

    registerPageAccess('Signup informant')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: SubmitHandler<any> = async (params) => {
    try {
      const body: any = {
        email: params.email.trim().toLowerCase(),
        password: params.password.trim(),
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        ...(params.environment && { userGroupId: params.environment }),
        relationId: JSON.parse(params.relation).value,
        role: 'informant',
        invitationToken: invitationToken!,
      }

      await signup(body)

      successToast('Sua conta foi criada com sucesso!')
      navigate('/')
    } catch (error: any) {
      console.error(error)
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    goNextStep()
  }

  const goBackStep = () => {
    if (step > 0) {
      if (relation && informantSteps[step].title === 'password-step') {
        const selectedRelation = JSON.parse(relation)

        if (selectedRelation?.label === 'Sou eu') {
          setStep((step) => step - 2)
          return
        }
      }

      setStep((step) => step - 1)
    }
  }

  const goNextStep = async () => {
    const valid = await trigger(informantSteps[step].fields)
    if (valid && step < informantSteps.length - 1) {
      if (relation && informantSteps[step].title === 'relation-step') {
        const selectedRelation = JSON.parse(relation)

        if (selectedRelation?.label === 'Sou eu') {
          setStep((step) => step + 2)
          return
        }
      }

      setStep((step) => step + 1)
    }
  }

  if (loading) return <></>

  return (
    <FormProvider {...methods}>
      <RouteLayout bgColor='white'>
        <Box
          display='flex'
          flexDirection='column'
          height={window.innerHeight}
          component='form'
          onKeyDown={checkKeyDown}
        >
          <Header color hasGoBackButton={step !== 0} handleBack={goBackStep} />
          <Box mx={5} mt={3} flexGrow={1}>
            {informantSteps[step].component}
          </Box>
          <Box mb={7} mx={5}>
            {informantSteps[step].hasNextButton &&
              step < informantSteps.length - 1 && (
                <Button type='button' onClick={goNextStep}>
                  Próximo
                </Button>
              )}
            {step === informantSteps.length - 1 && (
              <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
            )}
          </Box>
        </Box>
      </RouteLayout>
    </FormProvider>
  )
}

export default SignUpInformant

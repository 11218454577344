import client from 'services/client'

export const fetchPatient = async (id: string) => {
  const { data }: any = await client.get(`patients/${id}`)
  return data
}

export const getPatientTags = async (id: string) => {
  const { data }: any = await client.get(`patient-tags?patientId=${id}`)
  return data
}

export const deletePatientTag = async (id: string) => {
  await client.delete(`patient-tags/${id}`)
}

export const createPatientTag = async (patientId: string, text: string) => {
  const body = { patientId, text }
  await client.post(`patient-tags`, body)
}

export const getPatientLogs = async (id: string) => {
  const { data }: any = await client.get(`patient-logs?patientId=${id}`)
  return data
}

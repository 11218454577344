import { styled } from '@mui/material/styles'

interface StepIconRootProps {
  ownerState: { active?: boolean; totalSteps?: number }
}

export const StepIconRoot = styled('div')<StepIconRootProps>(
  ({ ownerState }) => {
    const calculatedWidth = 150 / (ownerState.totalSteps || 1)
    const width = Math.ceil(calculatedWidth)
    return {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#07B2A5',
      }),
      '& .StepIcon-completed': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: '#07B2A5',
        padding: 0,
      },
      '& .StepIcon-circle': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: 'currentColor',
        padding: 0,
      },
    }
  }
)

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const FirstName = styled('span')<any>(() => ({
  fontFamily: 'DM Sans',
  fontWeight: 700,
  fontSize: '32px',
  color: '#37405D',
}))

export const LastName = styled('span')<any>(() => ({
  fontFamily: 'DM Sans',
  fontWeight: 400,
  fontSize: '25px',
  color: '#37405D',
}))

export const SidebarContainer = styled(Box)<any>(() => ({
  padding: '32px 16px',
  width: '326px',
  minWidth: '326px',
  backgroundColor: '#F5F6F8',
  borderRadius: '10px 0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
}))

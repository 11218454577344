import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import ButtonBase from '@mui/material/ButtonBase'

export const Input = styled(InputBase)({
  paddingLeft: 8,
  fontSize: '12px',
  backgroundColor: '#FFFFFF',
  borderRadius: '2px 0px 0px 2px',
  height: '100%',
})

export const Button: any = styled(ButtonBase)(({ size }: any) => ({
  height: '100%',
  width: size === 'small' ? '64px' : '138px',
  fontWeight: '600',
  fontSize: size === 'small' ? '10px' : '14px',
  lineHeight: size === 'small' ? '14px' : '18px',
  color: '#FFFFFF',
}))

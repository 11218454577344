import { Controller, Control } from 'react-hook-form'
import {
  MenuItem,
  FormControl,
  Select as SelectMui,
  FormHelperText,
} from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'

interface SelectProps {
  name: string
  control: Control<any>
  options: string[]
  label?: string
  callbackClick?: () => void
}

function Multiselect({
  name,
  control,
  options,
  label = '',
  callbackClick = () => {},
}: SelectProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value: selectedOptions, onChange },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl
            color='secondary'
            error={invalid}
            fullWidth
            onClick={callbackClick}
          >
            {label && <InputLabel id={name}>{label}</InputLabel>}
            <SelectMui
              labelId={name}
              value={selectedOptions || []}
              onChange={onChange}
              displayEmpty
              multiple
              input={<OutlinedInput {...(label && { label })} />}
              renderValue={(selected) => selected.join(', ')}
            >
              {options.map((option: string) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Checkbox checked={selectedOptions?.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </SelectMui>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

export default Multiselect

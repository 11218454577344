import { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Button } from 'components'
import { pronounQuestion } from './questions'
import { useSignUpPatientContext } from 'contexts'
import ReactMarkdown from 'react-markdown'
import { registerPageAccess } from 'helpers'

function PronounStep(): JSX.Element {
  const { setValue, watch } = useFormContext()
  const { goNextStep, patient, patientName, type } = useSignUpPatientContext()

  const firstName = watch('firstName')

  const question = (pronounQuestion[patient || 'he'] as any).replace(
    '[nome_do_paciente]',
    firstName
  )

  useEffect(() => {
    registerPageAccess('Pronome Paciente', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography variant='question'>
        <ReactMarkdown>{question}</ReactMarkdown>
      </Typography>
      {[
        { value: 'he', label: 'Ele' },
        { value: 'she', label: 'Ela' },
      ].map(({ value, label }) => (
        <Box mt={2} key={value}>
          <Button
            color='questionButton'
            onClick={() => {
              setValue('pronoun', value)
              goNextStep()
            }}
          >
            {label}
          </Button>
        </Box>
      ))}
    </>
  )
}

export default PronounStep

import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { useDashboardContext } from 'contexts'
import {
  formatDateToGraphic,
  extractTagsFromQuestions,
  registerTrack,
} from 'helpers'
import ListRow from './list-row'

const MIN_WIDTH_TABLE = 500 + 66

function List({ forms }: any): JSX.Element {
  const [filteredForms, setFilteredForms] = useState<any>([])
  const [questions, setQuestions] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [expandedTags, setExpandedTags] = useState<string[]>([])
  const [hasMultipleResponders, setHasMultipleResponders] = useState(false)

  const {
    setTransversalInfos,
    setLongitudinalInfos,
    setDashboardType,
    setTagOptions,
    tagOptions,
    trackUsersInfosPayload,
  } = useDashboardContext()
  const { watch } = useFormContext()

  const selectedTags = watch('tags')
  const answersFilter = watch('answersFilter')
  const selectedResponders = watch('sources')

  useEffect(() => {
    setHasMultipleResponders(selectedResponders.length > 1)
  }, [selectedResponders])

  useEffect(() => {
    setFilteredForms(forms)

    const allQuestions = forms.reduce(
      (accUniqueQuestions: any[], { questions: formQuestions }: any) => {
        return accUniqueQuestions.concat(
          formQuestions.reduce(
            (accFormQuestions: any[], { answer, ...question }: any) => {
              const findQuestion = accUniqueQuestions.find(
                (accQuestion: any) => accQuestion.id === question.id
              )

              if (findQuestion) {
                // concat answers to filter
                findQuestion.answers.push(answer)
                // concat tags to show in uniq questions
                findQuestion.tags = [
                  ...new Set([...findQuestion.tags, ...question.tags]),
                ]
                return accFormQuestions
              } else {
                return accFormQuestions.concat({
                  ...question,
                  answers: [answer],
                })
              }
            },
            []
          )
        )
      },
      []
    )

    const allQuestionsFilteredByAnswers = allQuestions.filter((question: any) =>
      question.answers.some((answer: string) => answersFilter.includes(answer))
    )

    setQuestions(allQuestions)
    setFilteredQuestions(allQuestionsFilteredByAnswers)

    // TODO availableTags vai vir do back, na soma das tags dos forms
    const availableTags: any = extractTagsFromQuestions(
      forms.reduce((acc: any, { questions }: any) => [...acc, ...questions], [])
    )

    const tagOptions: string[] = availableTags
      .map(({ name }: any) => name)
      .sort((a: any, b: any) => a.localeCompare(b))

    setTagOptions(tagOptions)
  }, [
    forms,
    setTransversalInfos,
    setLongitudinalInfos,
    setDashboardType,
    setTagOptions,
    answersFilter,
  ])

  const tableWidth = MIN_WIDTH_TABLE + filteredForms.length * 120

  return (
    <TableContainer
      sx={{
        width: `${tableWidth <= 1526 ? tableWidth : 1526}px`,
        overflowX: 'auto',
      }}
    >
      <Table aria-label='answers table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell width='50px' sx={{ minWidth: '50px', maxWidth: '50px' }}>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => {
                  setExpandedTags(
                    Boolean(expandedTags.length) ? [] : tagOptions
                  )
                  registerTrack(
                    'Expande/Contrai Dominios',
                    trackUsersInfosPayload
                  )
                }}
              >
                {Boolean(expandedTags.length) ? (
                  <KeyboardDoubleArrowDownIcon />
                ) : (
                  <KeyboardDoubleArrowRightIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              width='500px'
              sx={{ minWidth: '500px', maxWidth: '500px' }}
            />
            {filteredForms.map((form: any) => {
              const formUpdateDate = form.formDateUpdate
                ? formatDateToGraphic(form.formDateUpdate)
                : ''

              return (
                <TableCell
                  key={form.formRequestId}
                  sx={{ minWidth: '120px', maxWidth: '120px' }}
                  align='center'
                >
                  {hasMultipleResponders ? (
                    <>
                      {form.name.split(' ')[0]}
                      <br />
                      <span style={{ wordBreak: 'break-word' }}>
                        {form.relation}
                      </span>
                      <br />
                      {formUpdateDate}
                    </>
                  ) : (
                    formUpdateDate
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(Boolean(selectedTags.length) ? selectedTags : tagOptions)
            .sort((a: any, b: any) => a.localeCompare(b))
            .map((tagName: string) => {
              const isExpanded = expandedTags.some(
                (tag: string) => tag === tagName
              )

              return (
                <ListRow
                  key={tagName}
                  tag={tagName}
                  filteredForms={filteredForms}
                  tagQuestions={filteredQuestions.filter((question: any) =>
                    question.tags.includes(tagName)
                  )}
                  pillQuestions={questions.filter((question: any) =>
                    question.tags.includes(tagName)
                  )}
                  expandState={isExpanded}
                  changeExpandState={() => {
                    registerTrack('Expande/Contrai Dominio', {
                      ...trackUsersInfosPayload,
                      tag_name: tagName,
                    })

                    setExpandedTags(
                      isExpanded
                        ? expandedTags.filter((tag: string) => tag !== tagName)
                        : [...expandedTags, tagName]
                    )
                  }}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default List

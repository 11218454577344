import { Container } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'components'
import {
  Dialog,
  InformationTitle,
  InformationText,
} from './information-modal-styles'

interface InformationModalProps {
  open: boolean
  onClose: () => void
  information: string
}

function InformationModal(props: InformationModalProps) {
  const { onClose, open, information } = props

  return (
    <Dialog onClose={onClose} open={open}>
      <Container maxWidth='xs'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ color: '#657787', position: 'absolute', right: 2, top: 2 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <InformationTitle>Aqui vai um exemplo:</InformationTitle>
        </DialogTitle>
        <DialogContent>
          <InformationText>{information}</InformationText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='secondary' onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default InformationModal

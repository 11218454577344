import client from 'services/client'

export const inactivateFormRequestFrequency = async (id: string) => {
  try {
    await client.patch(`/form-request-frequencies/${id}/inactivate`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

import { Box } from '@mui/material';
import { termsOfUse as terms } from 'helpers/constants/termsOfUse'

export default function TermsOfUse(): JSX.Element {
  return (
    <Box style={{ whiteSpace: 'pre-line' }}>
      {terms}
    </Box>
  );
}

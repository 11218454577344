import client from 'services/client'

export const createInformantInvite = async (body: any) => {
  try {
    const { data }: any = await client.post('/invites', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const sendEmailInvitePatient = async (body: any) => {
  try {
    await client.post('/invites/send-email', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPendingInvites = async () => {
  try {
    const { data } = await client.get('/invites-users/my-open-invites')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const checkInviteIsValid = async (token: string) => {
  try {
    const { data } = await client.get(`/invites?token=${token}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

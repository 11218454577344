import { GRAPHIC_HEIGHT } from 'helpers/constants'

const variationOrder: any = {
  'yes=>yes': 1,
  'yes=>NA': 1.5,
  'yes=>soso': 2,
  'yes=>no': 3,
  'NA=>yes': 3.5,
  'soso=>yes': 4,
  'soso=>soso': 5,
  'soso=>NA': 5.5,
  'soso=>no': 6,
  'NA=>soso': 6.5,
  'no=>yes': 7,
  'no=>soso': 8,
  'no=>no': 9,
  'no=>NA': 9.5,
  'NA=>no': 10,
}

export const generateMapResponse = (origin: any, destination: any) => {
  const uniqueQuestionsUnion = [
    ...origin.questions,
    ...destination.questions,
  ].filter((question, index, questionsUnion) => {
    return index === questionsUnion.findIndex(({ id }) => id === question.id)
  })

  return uniqueQuestionsUnion.map((question) => {
    const questionOrigin = origin.questions.find(
      (questionOrigin: any) => questionOrigin.id === question.id
    )

    const questionDestination = destination.questions.find(
      (questionDestination: any) => questionDestination.id === question.id
    )

    return {
      id: question.id,
      tags: question.tags,
      ultraShortVersion: question.ultraShortVersion,
      answerOrigin: questionOrigin?.answer || 'NA',
      answerDestination: questionDestination?.answer || 'NA',
      variation: `${questionOrigin?.answer || 'NA'}=>${
        questionDestination?.answer || 'NA'
      }`,
    }
  })
}

export const generateSankeys = (origin: any, destination: any) => {
  const mappedResponses = generateMapResponse(origin, destination)

  const calculateSankey = mappedResponses.reduce(
    (acc: any, mappedResponse: any) => {
      return {
        ...acc,
        [mappedResponse.variation]: (acc[mappedResponse.variation] || 0) + 1,
      }
    },
    {}
  )

  const sankeyObj = Object.entries(calculateSankey)
    .map(([variation, qtd]: any) => {
      const [answerOrigin, answerDestination] = variation.split('=>')

      return {
        answerOrigin,
        answerDestination,
        variation,
        qtd,
        questionIds: mappedResponses
          .filter(
            (mappedResponse: any) => mappedResponse.variation === variation
          )
          .map((mappedResponse: any) => mappedResponse.id),
      }
    })
    .sort((a, b) => variationOrder[a.variation] - variationOrder[b.variation])

  return sankeyObj
}

export const generatePositions = ({ yes = 0, soso = 0, no = 0 }: any) => ({
  yes: { start: 0, end: yes, current: 0 },
  soso: {
    start: yes,
    end: yes + soso,
    current: yes,
  },
  no: {
    start: yes + soso,
    end: yes + soso + no,
    current: yes + soso,
  },
})

export const updatePositions = (
  originalPositions: any,
  answer: any,
  qtdToAdd: any
) => ({
  ...originalPositions,
  [answer]: {
    ...originalPositions[answer],
    current: originalPositions[answer].current + qtdToAdd,
  },
})

export const calculateMarginTop = (
  { yes = 0, soso = 0, no = 0 }: any,
  questionHeight: number
) => {
  const HEIGHT_YES = Math.ceil(questionHeight * yes)
  const HEIGHT_SOSO = Math.ceil(questionHeight * soso)
  const HEIGHT_NO = Math.ceil(questionHeight * no)
  const HEIGHT_WHITE =
    (GRAPHIC_HEIGHT - (HEIGHT_YES + HEIGHT_SOSO + HEIGHT_NO)) / 2

  const marginTopOrigin = HEIGHT_WHITE - HEIGHT_YES / 2 + HEIGHT_NO / 2

  return marginTopOrigin
}

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Typography, Box } from '@mui/material'
import { Button, Header, RouteLayout } from 'components'
import { checkInviteIsValid } from 'services'
import { useNotification, useQuery } from 'hooks'
import { registerPageAccess, registerTrack } from 'helpers'

function SignUp(): JSX.Element {
  const [patientName, setPatientName] = useState('')

  const navigate = useNavigate()
  const query = useQuery()
  const { warnToast } = useNotification()

  const invitationToken = query.get('invitationToken')
  const userInfos: any = jwtDecode(invitationToken!)

  useEffect(() => {
    const validateInvite = async () => {
      try {
        const { isActive } = await checkInviteIsValid(invitationToken!)
        if (!isActive) navigate('/')
      } catch (error: any) {
        console.error(error?.message)
        navigate('/')
      }
    }

    if (!invitationToken) {
      warnToast('Você precisa de um convite para acessar essa página')
      navigate('/signin')
    }

    validateInvite()

    const userInfos: any = jwtDecode(invitationToken!)
    const names = userInfos?.name?.split(' ')
    setPatientName(names?.shift() || '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    registerPageAccess('Cadastro Paciente', { patient_name: userInfos.name })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoToPatient = () => {
    registerTrack('Clica Paciente', { patient_name: userInfos.name })
    navigate(`patient?invitationToken=${query.get('invitationToken')}`)
  }

  const handleGoToCaregiver = () => {
    registerTrack('Clica Responsavel', { patient_name: userInfos.name })
    navigate(`caregiver?invitationToken=${query.get('invitationToken')}`)
  }

  return (
    <RouteLayout bgColor='white'>
      <Header color disabledGoHome />
      <Box mx={5} mt={3} flexGrow={1} display='flex' flexDirection='column'>
        <Typography variant='question'>
          <b>Olá!</b>
          <br />
          <br />
          Você foi convidado para criar uma conta no Wida.
          <br />
          <br />
          Quem é você?
        </Typography>
        <Box mt='10%'>
          <Box mt={2}>
            <Button color='questionButton' onClick={handleGoToPatient}>
              Sou {patientName}
            </Button>
          </Box>
          <Box mt={2}>
            <Button color='questionButton' onClick={handleGoToCaregiver}>
              Sou responsável por {patientName}
            </Button>
          </Box>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default SignUp

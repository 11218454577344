import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { TagCard } from 'components/results'
import { useResultsContext } from 'contexts'
import { reorder } from 'helpers/dashboards'
import { tagsSizes } from 'helpers/constants'
import { Container } from './tags-container-styles'

function TagsContainer(): JSX.Element {
  const { tagsResults, setTagsResults } = useResultsContext()

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return // dropped outside the list

    const newItems = reorder(tagsResults, source.index, destination.index)

    setTagsResults(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-list' direction='horizontal'>
        {(provided: any, snapshot: any) => (
          <Container
            p={1}
            direction='row'
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tagsResults.map((tag: any, index: Number) => {
              return (
                <TagCard
                  key={tag.id}
                  tag={{
                    id: tag.id,
                    name: tag.name,
                    maxQuestions: tagsSizes[tag.name],
                  }}
                  index={index}
                  responses={tag.responses}
                />
              )
            })}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default TagsContainer

import { Typography } from '@mui/material'
import { GradientLink } from './gradient-link'

function SignUpHeader(): JSX.Element {
  return (
    <Typography variant='h5' style={{ fontSize: '16px' }}>
      Já tem conta?{' '}
      <GradientLink
        href='/signin'
        rel='noreferrer'
        target='_blank'
      >
        Login
      </GradientLink>
    </Typography>
  )
}

export default SignUpHeader

import { useState, useEffect, KeyboardEvent } from 'react'
import { FormProvider, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import jwtDecode from 'jwt-decode'
import { Button, Header, RouteLayout } from 'components'
import { patientSteps, caregiverSteps } from 'components/sign-up-steps'
import { SignUpPatientContext, useAuthContext } from 'contexts'
import { SignUpPatientBody, SignUpCaregiverBody } from 'types/services'
import { SignUpRoles, CaregiverFormInput, PatientFormInput } from 'types'
import { useNotification, useQuery } from 'hooks'
import { registerPageAccess, registerTrack } from 'helpers'
import useSignUpPatientForm from './use-sign-up-patient-form'

const stepsMap = {
  patient: patientSteps,
  caregiver: caregiverSteps,
}

const errorMessages: any = {
  'Token not found': 'Você precisa de um convite válido para se cadastrar',
  'User not authorized.': 'Esse convite já foi utilizado',
}

interface SignUpPatientProps {
  type: SignUpRoles
}

function SignUpPatient({ type }: SignUpPatientProps) {
  const [step, setStep] = useState<number>(0)

  const { successToast, warnToast, errorToast } = useNotification()
  const navigate = useNavigate()
  const query = useQuery()
  const methods = useSignUpPatientForm(type)
  const { signup } = useAuthContext()

  const {
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods

  const pronoun = watch('pronoun')

  const invitationToken = query.get('invitationToken')
  const userInfos: any = jwtDecode(invitationToken!)

  useEffect(() => {
    if (!errors.password && !errors.confirmPassword) return

    const message = errors.password?.message || errors.confirmPassword?.message

    if (message === 'Senhas devem ser iguais') {
      registerTrack('Senhas Diferentes', {
        patient_name: userInfos?.name,
        source: type,
      })
    }

    if (
      message ===
      'Sua senha deve ter letra minúscula, maiúscula, símbolo e número'
    ) {
      registerTrack('Senha Fraca', {
        patient_name: userInfos?.name,
        source: type,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.password, errors.confirmPassword])

  useEffect(() => {
    if (!invitationToken) {
      warnToast('Você precisa de um convite para acessar essa página')
      navigate('/signin')
    }

    const names = userInfos?.name?.split(' ')

    setValue('firstName', names?.shift() || '')
    setValue('lastName', names?.join(' ') || '')

    registerPageAccess(`Signup ${type.toLowerCase()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: SubmitHandler<PatientFormInput | CaregiverFormInput> = async (
    params: PatientFormInput | CaregiverFormInput
  ) => {
    try {
      const body: SignUpPatientBody | SignUpCaregiverBody = {
        ...params, // TODO remove and set only valid properties
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        password: params.password.trim(),
        email: params.email.toLowerCase(),
        pronoun: params.pronoun.toUpperCase(),
        birthdate: format(new Date(params.birthdate), 'yyyy-MM-dd'),
        ...('relation' in params
          ? { relationId: JSON.parse(params.relation).value }
          : null),
        ...('environment' in params
          ? { userGroupId: params.environment }
          : null),
        ...('caregiverFirstName' in params
          ? { caregiverFirstName: params.caregiverFirstName.trim() }
          : null),
        ...('caregiverLastName' in params
          ? { caregiverLastName: params.caregiverLastName.trim() }
          : null),
        role: type,
        invitationToken: invitationToken!,
      }

      await signup(body)

      successToast('Sua conta foi criada com sucesso!')
      navigate('/')
    } catch (error: any) {
      console.error(error)
      if (error?.message === 'Usuário já cadastrado.') {
        registerTrack('Usuário Existente', {
          patient_name: userInfos?.name,
          source: type,
          email: params.email,
        })
      }
      errorToast(
        errorMessages[error?.message] || error.message || 'Erro desconhecido'
      )
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    goNextStep()
  }

  const goBackStep = () => {
    if (step > 0) setStep((step) => step - 1)
    if (step === 0) navigate(`/signup?invitationToken=${invitationToken}`)
  }

  const goNextStep = async () => {
    const valid = await trigger(stepsMap[type][step].fields)
    if (valid && step < stepsMap[type].length - 1) setStep((step) => step + 1)
  }

  return (
    <SignUpPatientContext.Provider
      value={{
        goNextStep,
        patient: type === 'patient' ? 'you' : pronoun,
        patientName: userInfos?.name,
        type,
      }}
    >
      <FormProvider {...methods}>
        <RouteLayout bgColor='white'>
          <Box
            display='flex'
            flexDirection='column'
            height={window.innerHeight}
            component='form'
            onKeyDown={checkKeyDown}
          >
            <Header color hasGoBackButton handleBack={goBackStep} />
            <Box mx={5} mt={3} flexGrow={1}>
              {stepsMap[type][step].component}
            </Box>
            <Box mb={7} mx={5}>
              {stepsMap[type][step].hasNextButton &&
                step < stepsMap[type].length - 1 && (
                  <Button type='button' onClick={goNextStep}>
                    Próximo
                  </Button>
                )}
              {step === stepsMap[type].length - 1 && (
                <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
              )}
            </Box>
          </Box>
        </RouteLayout>
      </FormProvider>
    </SignUpPatientContext.Provider>
  )
}

export default SignUpPatient

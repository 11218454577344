import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Header, Button, RouteLayout } from 'components'
import PatientText from './patient-text'
import InformantText from './informant-text'
import { useAuthContext } from 'contexts'
import { isUserPatient, registerPageAccess } from 'helpers'

function StartQuestionnaire({ type, handleGoNext, patientInfo }: any) {
  const { user } = useAuthContext()
  useEffect(() => {
    registerPageAccess('Questionário', {
      user_id: user.userId,
      patient_id: patientInfo.patientId,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouteLayout bgColor='green'>
      <Header hasProfileButton />
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Box>
          {type === 'patient' ? (
            <PatientText patientInfo={patientInfo} />
          ) : (
            <InformantText patientInfo={patientInfo} />
          )}
        </Box>
        <Box>
          <Button variant='outlined' color='white' onClick={handleGoNext}>
            Começar
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default StartQuestionnaire

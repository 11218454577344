import client from 'services/client'

export const getUserInformants = async () => {
  try {
    const { data: informantsPatients }: any = await client.get(
      'informants-patients/my-informants'
    )

    return informantsPatients.map(
      (informantPatient: any) => informantPatient.informant
    )
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPatientInformants = async (patientId: string) => {
  try {
    const { data: patientInformants }: any = await client.get(
      `informants-patients?patientId=${patientId}`
    )
    return patientInformants
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

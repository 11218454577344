import { useEffect } from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Header, MenuButton, RouteLayout } from 'components'
import { getUserQuestionnaire } from 'services'
import { useNotification } from 'hooks'
import { useAuthContext } from 'contexts'
import {
  isUserInformant,
  getUserRole,
  registerPageAccess,
  isUserPatient,
} from 'helpers'
import { Title, Text } from './patient-home-styles'

function PatientHome(): JSX.Element {
  const navigate = useNavigate()
  const { warnToast, errorToast } = useNotification()
  const { user } = useAuthContext()

  useEffect(() => {
    if (user && getUserRole(user)) {
      registerPageAccess(`Home ${getUserRole(user)}`) // TODO multiroles
    }
  }, [user])

  const handleAnswerQuestionnaire = async () => {
    try {
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      if (form?.lastFormId || form?.status === 204) {
        return warnToast('Você já respondeu o último questionário')
      }

      navigate('/questionnaire', { state: { form } })
    } catch (error: any) {
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const handleInformantInvite = async () => {
    try {
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      const formId = form?.lastFormId || form?.formId

      if (!formId) {
        warnToast(
          'Você ainda não tem um questionário válido para convidar outras pessoas'
        )
        return
      }

      const alreadyAnswered = Boolean(form?.lastFormId)

      registerPageAccess('Convidar Informantes', {
        user_id: user.userId,
        source: isUserPatient(user) ? 'patient' : 'caregiver',
        answered: alreadyAnswered,
      })

      navigate(`/invite_informant?form_id=${formId}`)
    } catch (error: any) {
      warnToast(error?.message || 'Erro desconhecido')
    }
  }

  return (
    <RouteLayout bgColor='green'>
      <Header hasProfileButton hasLogoutButton />
      <Box flexGrow={1} margin={5}>
        <Title>Olá, {user?.personalInfos?.firstName || ''}!</Title>
        <Box mt={1} mb={3}>
          <Text>O que você quer fazer?</Text>
        </Box>
        <Box mb={2}>
          <MenuButton onClick={handleAnswerQuestionnaire}>
            Responder questionário
          </MenuButton>
        </Box>
        {!isUserInformant(user) && (
          <Box mb={2}>
            <MenuButton onClick={handleInformantInvite}>
              Convidar outras pessoas para responder
            </MenuButton>
          </Box>
        )}
      </Box>
    </RouteLayout>
  )
}

export default PatientHome

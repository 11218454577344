import { useNavigate } from 'react-router-dom'
import { IconButton, Divider, Box, Badge, Menu, Typography } from '@mui/material'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { sortDates } from 'helpers'
import { formatNotificationFromBack } from 'helpers/formatters'
import { getClinicNotifications, setNotificationAsViewed, setNotificationAsRead } from 'services'
import { CloseOutlined } from '@mui/icons-material'
import { Fragment, useEffect, useState } from 'react'
import { FETCHING, IDLE } from 'helpers/constants'
import Notification from '../notification/notification'

const sortByDate = (a: any, b: any) => sortDates(a.createdAt, b.createdAt, 'desc')

function ClinicNotifications(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setNotificationsCount] = useState(0)
  const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const notificationsLength = notifications.length

  const fetchData = async () => {
    try {
      const nData = await getClinicNotifications()
      const formattedNotifications = nData.map((n: any) => formatNotificationFromBack(n)).sort(sortByDate)
      const nCount = formattedNotifications.filter((n: any) => !n.viewed).length
      setNotifications(formattedNotifications)
      setNotificationsCount(nCount)
    } catch (error: any) {
      console.error(error.message)
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleNotificationsClick = async (event: any) => {
    setAnchorEl(event.currentTarget);
    const notViewed = notifications.filter((n: any) => !n.viewed)
    try {
      if (!!notViewed.length) {
        await Promise.all(
          notViewed.map(async (n: any) => {
            setNotificationAsViewed(n.id)
          })
        )
        fetchData()
        setNotificationsCount(0)
      }
    } catch (error: any) {
      console.error(error.message)
    }
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const onClickNotification = async (notification: any) => {
    try {
      await setNotificationAsRead(notification.id)
      navigate(`/patients/${notification.payload.patientId}/results`, { state: { notification } })
    } catch (error: any) {
      console.error(error.message)
    } finally {
      fetchData()
    }
  }

  if (status !== IDLE) return <></>

  return (
    <>
      <IconButton onClick={handleNotificationsClick}>
        <Badge color="primary" badgeContent={notificationsCount}>
          <NotificationsNoneOutlinedIcon sx={{ fill: '#BDBDBD' }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleNotificationsClose}
        onClick={handleNotificationsClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            'ul:first-of-type': {
              paddingTop: 0,
              paddingBottom: '20px',
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box
          sx={{
            borderTop: '5px solid ',
            borderImageSlice: 1,
            borderWidth: '5px',
            borderImageSource: 'linear-gradient(to right, #F36F60, #F36F60, #00B3A6)',
            whiteSpace: 'normal',
            wordBreak: 'break-word'
          }}
          display='flex'
          flexGrow={1}
          alignItems='center'
          justifyContent='space-between'
          p={2}
        >
          <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#353F48' }}>
            Notificações
          </Typography>
          <IconButton
            onClick={handleNotificationsClose}
          >
            <CloseOutlined fill='#353F48' />
          </IconButton>
        </Box>
        <Box sx={{ overflowY: 'scroll', maxHeight: '350px' }}>
          {notifications.map((n: any, i: number) => {
            return <Fragment key={n.id}>
              <Notification notification={n} onClickNotification={onClickNotification} />
              {i !== notificationsLength - 1 && <Divider key={i} sx={{ margin: '15px 5% !important' }} />}
            </Fragment>
          })}
        </Box>
      </Menu>
    </>
  )
}

export default ClinicNotifications

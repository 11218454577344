import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)`
  padding: 0 40px;
`
export const Section = styled(Box)`
  padding-top: 30px;
  padding-bottom: 10px;
  &:first-of-type {
    padding-top: 25px;
  }
`

export const TitleText = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #353F48;
`

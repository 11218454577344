import { Box } from '@mui/material'
import { LogoTitle, LogoText } from './logo-text-container-styles'

function LogoTextContainer(): JSX.Element {
  const title = `Wida é mais\ndo que tecnologia.`
  const text = `É uma questão de respeitar pacientes e profissionais\nda saúde, unindo dados à sabedoriaclínica para\ngerar análises relevantes.`

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <LogoTitle variant='h3'>
        {title}
      </LogoTitle>
      <LogoText>
        {text}
      </LogoText>
    </Box>
  )
}

export default LogoTextContainer

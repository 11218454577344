import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Select } from 'components'
import { getUserGroups } from 'services'
import { useNotification, useQuery } from 'hooks'

interface EnvironmentStepProps {
  type: 'informant' | 'caregiver'
}

function EnvironmentStep({ type }: EnvironmentStepProps): JSX.Element {
  const [options, setOptions] = useState([])

  const { control, watch } = useFormContext()
  const { errorToast } = useNotification()
  const query = useQuery()

  const getPatientName = () => {
    if (type === 'informant') {
      const invitationToken = query.get('invitationToken')
      const userInfos: any = jwtDecode(invitationToken!)
      return userInfos?.createdBy?.name.split(' ')[0] || 'o paciente'
    } else if (type === 'caregiver') {
      const patientFirstName = watch('firstName')
      return patientFirstName || 'o paciente'
    }

    return 'o paciente'
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userGroups = await getUserGroups()

        setOptions(
          userGroups?.map(({ id, name }: any) => ({
            value: id,
            label: name,
          })) || []
        )
      } catch (error: any) {
        console.error(error)
        errorToast(error?.message || 'Erro desconhecido')
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography variant='title'>
        Em que <b>ambiente</b> você mais convive com {getPatientName()}?
      </Typography>
      <Box mt={5}>
        <Select name='environment' control={control} options={options} />
      </Box>
    </>
  )
}

export default EnvironmentStep

import Box from '@mui/material/Box'
import { Button, Input } from './input-with-button-styles'
import { toast } from 'react-toastify'
import { registerTrack } from 'helpers'

interface InputWithButtonProps {
  copyValue: string | undefined
  displayText: string | undefined
  size?: string
  registerTrackPayload?: any
}

function InputWithButton({
  copyValue = '',
  displayText = '',
  size = 'small',
  registerTrackPayload,
}: InputWithButtonProps) {
  const handleCopy = () => {
    registerTrack('Clica Copiar Link', registerTrackPayload)
    navigator.clipboard.writeText(copyValue)
    toast.success('Convite copiado com sucesso!')
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      border='1.5px solid #07B2A5'
      borderRadius='3px'
      height={size === 'small' ? '32px' : '40px'}
      boxSizing='content-box'
      sx={{
        backgroundColor: '#07B2A5',
      }}
    >
      <Input
        inputProps={{ 'aria-label': 'link de convite' }}
        value={displayText}
        fullWidth
        disabled
      />

      <Button size={size} onClick={handleCopy}>
        Copiar
      </Button>
    </Box>
  )
}

export default InputWithButton

import { useEffect, useState } from 'react'
import { useResultsContext } from 'contexts'
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { TagTimeline } from 'components/results'
import { generateSankeys } from 'helpers'

function TagDetails(): JSX.Element {
  const { tagDetailInfos, timelineVisibleRange, setTimelineVisibleRange } =
    useResultsContext()

  const [mappedResponses, setMappedResponses] = useState([])

  useEffect(() => {
    const timeline = tagDetailInfos?.timeline?.slice(...timelineVisibleRange)
    if (!timeline || timeline.length === 0) return

    const sankeyResponses: any = [...Array(timeline.length - 1).keys()].map(
      (index) => {
        const origin = timeline[index]
        const destination = timeline[index + 1]

        const sankeys = generateSankeys(origin, destination)

        return {
          id: `${origin.formRequestId}:${destination.formRequestId}`,
          origin,
          destination,
          sankeys,
          active: false,
        }
      }
    )

    setMappedResponses(sankeyResponses)
  }, [tagDetailInfos, timelineVisibleRange])

  const handleSelectSankey = (timelineResponse: any) => () => {
    const timeline = tagDetailInfos?.timeline?.slice(...timelineVisibleRange)

    const graphicIndex = timeline.findIndex(
      ({ formRequestId }: any) =>
        formRequestId === timelineResponse.formRequestId
    )

    const currentGraphicId = timeline[graphicIndex]?.formRequestId
    const nextGraphicId = timeline[graphicIndex + 1]?.formRequestId

    if (!nextGraphicId) return

    const findMappedResponse: any = mappedResponses.find(
      ({ id }: any) => id === `${currentGraphicId}:${nextGraphicId}`
    )

    setMappedResponses((mappedResponses: any) =>
      mappedResponses.map((mappedResponse: any) => {
        if (mappedResponse.id !== findMappedResponse.id) return mappedResponse

        return { ...mappedResponse, active: !mappedResponse.active }
      })
    )
  }

  const handleBackward = () => {
    const [lowerLimit, upperLimit] = timelineVisibleRange

    if (lowerLimit <= 0) return
    setTimelineVisibleRange([lowerLimit - 1, upperLimit - 1])
  }

  const handleForward = () => {
    const [lowerLimit, upperLimit] = timelineVisibleRange

    if (upperLimit >= tagDetailInfos?.timeline?.length) return
    setTimelineVisibleRange([lowerLimit + 1, upperLimit + 1])
  }

  return (
    <Container display='flex' alignItems='center'>
      <Box width='24px' mb='36px' mr={2}>
        {timelineVisibleRange[0] !== 0 && (
          <IconButton onClick={handleBackward}>
            <ArrowBackIosRoundedIcon sx={{ fill: '#b7b7b7' }} />
          </IconButton>
        )}
      </Box>

      <TagTimeline
        timeline={tagDetailInfos?.timeline?.slice(...timelineVisibleRange)}
        tag={tagDetailInfos?.tag}
        mappedResponses={mappedResponses}
        handleSelectSankey={handleSelectSankey}
      />

      <Box width='24px' mb='36px'>
        {timelineVisibleRange[1] < tagDetailInfos?.timeline?.length && (
          <IconButton onClick={handleForward}>
            <ArrowForwardIosRoundedIcon sx={{ fill: '#b7b7b7' }} />
          </IconButton>
        )}
      </Box>
    </Container>
  )
}

const Container = styled(Box)({
  height: '100%',
})

export default TagDetails

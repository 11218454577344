import { useEffect, useState, SyntheticEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Tab } from '@mui/material'
import {
  PersonOutlineOutlined,
  ForwardToInboxOutlined,
  Send,
} from '@mui/icons-material'
import {
  ClinicLayout,
  Button,
  PatientListEmpty,
  PatientList,
  PendingInviteList,
} from 'components'
import { Tabs } from './my-patients-styles'
import { useAuthContext } from 'contexts'
import { getClinicPatients, getPendingInvites } from 'services'
import {
  getPatientFullName,
  registerPageAccess,
  registerTrack,
  findInWord,
} from 'helpers'
import {
  formatPatientFromBack,
  formatCaregiverFromBack,
} from 'helpers/formatters'
import { FETCHING, IDLE } from 'helpers/constants'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function MyPatients(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patients, setPatients] = useState([])
  const [invites, setInvites] = useState([])
  const [filter, setFilter] = useState('')
  const [activeTab, setActiveTab] = useState<number>(0)

  const navigate = useNavigate()
  const { user } = useAuthContext()

  const fetchData = useCallback(
    async (filter = '') => {
      try {
        const [patients, invites] = await Promise.all([
          getClinicPatients(),
          getPendingInvites(),
        ])

        const formattedPatients = patients.map((patientData: any) =>
          patientData.caregiver.accountInfo.isTutor
            ? formatCaregiverFromBack(patientData)
            : formatPatientFromBack(patientData)
        )

        setPatients(
          formattedPatients.filter((p: any) =>
            findInWord(getPatientFullName(p), activeTab === 0 ? filter : '')
          )
        )

        setInvites(
          invites.filter((i: any) =>
            findInWord(i.name, activeTab === 1 ? filter : '')
          )
        )
      } catch (error: any) {
        console.error(error.message)
      } finally {
        setStatus(IDLE)
      }
    },
    [activeTab]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    registerPageAccess('Home Clinico', { clinic_id: user.clinicId })
  }, [user])

  const handleChange = async (event: SyntheticEvent, newValue: number) => {
    setFilter('')
    await fetchData()
    setActiveTab(newValue)
  }

  const onFilter = async (value: string) => {
    if (!filter && value) setFilter(value)
    await fetchData(value)
  }

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <Box py={4} px={10} display='flex' flexDirection='column' height='100%'>
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: 'transparent',
              borderRadius: '4px',
            },
          }}
          value={activeTab}
          onChange={handleChange}
        >
          <Tab
            icon={<PersonOutlineOutlined />}
            iconPosition='start'
            label='Pacientes'
            onClick={() => {
              registerTrack('Clica Pacientes Ativos', {
                clinic_id: user.clinicId,
              })
            }}
            {...a11yProps(0)}
          />
          <Tab
            icon={<ForwardToInboxOutlined />}
            iconPosition='start'
            label={`Convites pendentes (${invites.length})`}
            onClick={() => {
              registerTrack('Clica Convites Pendentes', {
                clinic_id: user.clinicId,
              })
            }}
            {...a11yProps(1)}
          />
          <Box display='flex' flexGrow={1} justifyContent='flex-end'>
            <Button
              sx={{ width: 'auto' }}
              startIcon={<Send />}
              onClick={() => {
                registerTrack('Clica Novo Questionario', {
                  clinic_id: user.clinicId,
                  source: 'home',
                })
                navigate('/invite_patient')
              }}
            >
              Convidar paciente
            </Button>
          </Box>
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          {!Boolean(patients.length) && !Boolean(filter) ? (
            <PatientListEmpty />
          ) : (
            <PatientList
              onFilter={onFilter}
              patients={patients}
              fetchPatients={fetchData}
            />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <PendingInviteList invites={invites} onFilter={onFilter} />
        </TabPanel>
      </Box>
    </ClinicLayout>
  )
}

export default MyPatients

import { ChangeEvent } from 'react'
import { Controller } from 'react-hook-form'
import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material'

export default function CheckboxGroup({ label, options, name, control }: any) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value: oldSelectedValues },
        fieldState: { error },
      }: any) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <FormControl
              sx={{ m: 3 }}
              component='fieldset'
              variant='standard'
              error={Boolean(error?.message)}
            >
              {label && <FormLabel component='legend'>{label}</FormLabel>}
              <FormGroup>
                {options.map(({ label, value, alreadySent }: any) => (
                  <FormControlLabel
                    key={value}
                    label={label}
                    control={
                      <Checkbox
                        checked={oldSelectedValues.some(
                          (elem: any) => elem === value
                        )}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const newSelectedValues = event.target.checked
                            ? [...oldSelectedValues, event.target.name]
                            : oldSelectedValues.filter(
                                (elem: string) => elem !== event.target.name
                              )
                          onChange(newSelectedValues)
                        }}
                        name={value}
                        disabled={alreadySent}
                      />
                    }
                  />
                ))}
              </FormGroup>
              {error?.message && (
                <FormHelperText>{error?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
        )
      }}
    ></Controller>
  )
}

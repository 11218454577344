import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
  color: '#FFFFFF',
})

export const Subtitle = styled(Typography)({
  fontWeight: 300,
  fontSize: '22px',
  lineHeight: '144%',
  letterSpacing: '0.04em',
  color: '#FFFFFF',
})

import { useState, useEffect, FC, ComponentProps } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'contexts'
import { signIn, signUp, checkAuth } from 'services'
import { getUserInfos, registerTrack } from 'helpers'

function AuthProvider({ children }: ComponentProps<FC>): JSX.Element {
  const [user, setUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true)
        await checkAuth()
        const userInfos = getUserInfos()
        setUser(userInfos)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signin = async (params: any, callback?: VoidFunction) => {
    try {
      const data = await signIn(params)

      localStorage.setItem('wida:token', data?.accessToken)
      const userInfos = getUserInfos()

      if (!userInfos) throw new Error('Ocorreu um erro ao realizar o login')

      setUser(userInfos)
      if (callback) callback()
    } catch (error: any) {
      registerTrack('Credenciais Invalidas')
      throw new Error(error.message)
    }
  }

  const signup = async (params: any, callback?: VoidFunction) => {
    try {
      const data = await signUp(params)

      localStorage.setItem('wida:token', data?.accessToken)
      const userInfos = getUserInfos()

      if (!userInfos) throw new Error('Ocorreu um erro ao realizar o cadastro')

      setUser(userInfos)
      if (callback) callback()
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  const signout = () => {
    setUser(null)
    localStorage.removeItem('wida:token')
    navigate('/signin')
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signin,
        signup,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

import { Routes, Route } from 'react-router-dom'
import {
  Home,
  SignUp,
  SignUpPatient,
  SignUpInformant,
  SignUpClinic,
  SignIn,
  PasswordReset,
  Questionnaire,
  InviteNewInformant,
  InviteInformant,
  SendInvite,
  MyPatients,
  MyProfile,
  InvitePatient,
  PatientHome,
  ForgotPassword,
  Dashboard,
  PatientProfile,
  Results,
  PatientRecords,
} from 'routes'
import { RequireAuthWrapper } from 'components'
import { CLINIC, PATIENT, CAREGIVER, INFORMANT } from 'helpers/constants'

declare global {
  interface Window {
    analytics: any
  }
}

function App() {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <RequireAuthWrapper
              alowedRoles={[CLINIC, PATIENT, CAREGIVER, INFORMANT]}
            >
              <Home />
            </RequireAuthWrapper>
          }
        />

        <Route path='signup'>
          <Route index element={<SignUp />} />
          <Route path='patient' element={<SignUpPatient type={PATIENT} />} />
          <Route
            path='caregiver'
            element={<SignUpPatient type={CAREGIVER} />}
          />
          <Route path='informant' element={<SignUpInformant />} />
          <Route path='clinic' element={<SignUpClinic />} />
        </Route>

        <Route path='signin' element={<SignIn />} />

        <Route
          path='profile'
          element={
            <RequireAuthWrapper alowedRoles={[CLINIC]}>
              <MyProfile />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='patient'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
              <PatientHome />
            </RequireAuthWrapper>
          }
        />

        <Route path='patients'>
          <Route
            index
            element={
              <RequireAuthWrapper alowedRoles={[CLINIC]}>
                <MyPatients />
              </RequireAuthWrapper>
            }
          />

          <Route path=':patient_id'>
            <Route
              path='dashboard'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <Dashboard />
                </RequireAuthWrapper>
              }
            />

            <Route
              path='results'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <Results />
                </RequireAuthWrapper>
              }
            />

            <Route
              path='profile'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <PatientProfile />
                </RequireAuthWrapper>
              }
            />

            <Route
              path='patient_records'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <PatientRecords />
                </RequireAuthWrapper>
              }
            />
          </Route>
        </Route>

        <Route
          path='questionnaire'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
              <Questionnaire />
            </RequireAuthWrapper>
          }
        />

        <Route path='password_reset' element={<PasswordReset />} />
        <Route path='forgot-password' element={<ForgotPassword />} />

        <Route
          path='invite_informant'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
              <InviteInformant />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='invite_new_informant'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
              <InviteNewInformant />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='send_invite'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
              <SendInvite />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='invite_patient'
          element={
            <RequireAuthWrapper alowedRoles={[CLINIC]}>
              <InvitePatient />
            </RequireAuthWrapper>
          }
        />
      </Routes>
    </>
  )
}

export default App

import { useState } from 'react'
import { format } from 'date-fns'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { ResendInviteModal, FilterTableHeader } from 'components'
import { useAuthContext } from 'contexts'
import { registerTrack } from 'helpers'
import { PatientTableRow } from './pending-invite-list-styles'

type Order = 'asc' | 'desc';

interface HeadCell {
  id: string
  label: string
  order?: boolean
}

interface Data {
  name: string
  createdAt: string
}


const headerFields: HeadCell[] = [
  { id: 'name', label: 'Nome', order: true },
  { id: 'createdAt', label: 'Envio', order: true },
  { id: 'status', label: 'Status' },
  { id: 'resend', label: 'Reenviar' }
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function PendingInviteList({ invites, onFilter }: any): JSX.Element {
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [order, setOrder] = useState<Order>('asc');
  const [resendInviteInfos, setResendInviteInfos] = useState<any>({
    open: false,
    token: '',
    patientName: '',
  })

  const { user } = useAuthContext()

  const handleResendInvite = (invite: any) => {
    registerTrack('Reenvia Convite', {
      clinic_id: user.clinicId,
    })

    setResendInviteInfos({
      open: true,
      token: invite.token,
      patientName: invite.name,
    })
  }

  const onCloseResendInviteModal = () => {
    setResendInviteInfos({ open: false, token: '', patientName: '' })
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      flex={1}
    >
      <TableContainer component={Paper} sx={{ marginTop: '16px', boxShadow: 'none' }}>
        <Table aria-label='tabela de pacientes'>
          <FilterTableHeader
            headerFields={headerFields}
            onFilter={onFilter}
            orderBy={orderBy}
            order={order}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
          />
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ border: 'none', height: '40px' }} />
            </TableRow>
            {invites
              .sort(getComparator(order, orderBy))
              .map((invite: any) => {
                return (
                  <PatientTableRow
                    key={invite.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell width={500}>
                      <Box display='flex' alignItems='center'>
                        <Avatar sx={{ marginRight: '8px' }}>
                          {invite.name[0] || 'W'}
                        </Avatar>
                        {invite.name}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {format(new Date(invite.createdAt), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell width={260}>Aguardando cadastro</TableCell>
                    <TableCell width={200} align='center'>
                      <Tooltip
                        title={'Reenviar convite para cadastro'}
                        placement='top'
                        arrow
                      >
                        <IconButton
                          aria-label='Reenviar convite para cadastro'
                          component='span'
                          sx={{ color: '#657787' }}
                          onClick={() => handleResendInvite(invite)}
                        >
                          <SendIcon fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </PatientTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {resendInviteInfos.open && (
        <ResendInviteModal
          onClose={onCloseResendInviteModal}
          informantion={resendInviteInfos}
        />
      )}
    </Box>
  )
}

export default PendingInviteList

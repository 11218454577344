import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useResultsContext } from 'contexts'
import { answerDict } from 'helpers/constants'

function AnswersTimelineDots({ question, timelineDates }: any): JSX.Element {
  const { timelineVisibleRange } = useResultsContext()

  return (
    <Stack direction='row' justifyContent='center' spacing={23}>
      {question.answers
        .slice(...timelineVisibleRange)
        .map((answer: any, index: number) => {
          return (
            <Box zIndex={1} key={timelineDates[index]} display='flex'>
              {answer !== 'na' ? (
                <img
                  src={answerDict[answer].icon}
                  height='16px'
                  width='16px'
                  alt={answerDict[answer].alt}
                />
              ) : (
                <Box width='16px' />
              )}
            </Box>
          )
        })}
    </Stack>
  )
}
export default AnswersTimelineDots

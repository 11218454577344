import { correctsTagWithDBInfo, nearestEvenInt } from 'helpers'

export const generateQuestionQuantities = (questions: any) => {
  return questions.reduce(
    (acc: any, { answer }: any) => ({
      ...acc,
      [answer]: (acc[answer] || 0) + 1,
    }),
    {}
  )
}

export function correctsTagsInRespondersWithResponses(
  respondersWithResponses: any,
  dbTags: any
) {
  return respondersWithResponses.map((responderWithResponses: any) => {
    return {
      ...responderWithResponses,
      responses: responderWithResponses.responses.map((response: any) => {
        return {
          ...response,
          tags: correctsTagWithDBInfo(response.tags, dbTags),
        }
      }),
    }
  })
}

export function calculateQuestionHeight(
  graphHeight: number,
  tagSize: number
): number {
  return nearestEvenInt(graphHeight / (tagSize * 2))
}

import { useState, MouseEvent } from 'react'
import { Control, Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  FieldContainer,
  Label,
  Input,
  HelperText,
} from './password-field-styles'

interface PasswordFieldProps {
  name: string
  control: Control<any>
  label?: string
  disabled?: boolean
}

function PasswordField({ name, control, label, disabled }: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <Box mb={0.5}>
            <Label error={invalid}>{label}</Label>
          </Box>
          <FieldContainer
            disabled={disabled}
            error={invalid}
            variant='standard'
            fullWidth
          >
            <Input
              onChange={onChange}
              value={value}
              color='secondary'
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {invalid && (
              <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
            )}
          </FieldContainer>
        </Box>
      )}
    />
  )
}

export default PasswordField

import { useMemo } from 'react'
import { Box, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Header, MenuButton, RouteLayout, Button } from 'components'
import { useAuthContext } from 'contexts'
import { getUserQuestionnaire } from 'services'
import { useNotification } from 'hooks'
import {
  isUserInformant,
  getUserRole,
  registerTrack,
  isUserPatient,
  registerPageAccess,
} from 'helpers'
import { Title, Loading } from './finish-questionnaire-styles'

function FinishQuestionnaire({
  patientInfo,
  submitting,
  error,
  resendQuestionnaire,
  isInformantPatient,
}: any): JSX.Element {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { errorToast } = useNotification()

  const userRole = useMemo(() => getUserRole(user), [user])

  const rolesText: any = useMemo(
    () => ({
      patient:
        'Agora você pode convidar outras pessoas para responder, trazendo para o seu clínico mais informações sobre você.',
      caregiver: `Agora você pode convidar outras pessoas para responder, trazendo para o clínico mais informações sobre ${
        patientInfo?.firstName || '_'
      }.`,
      informant: `Muito obrigado por suas informações${
        isInformantPatient ? '.' : ` sobre ${patientInfo?.firstName || '_'}.`
      }`,
    }),
    [patientInfo, isInformantPatient]
  )

  const handleInformantInvite = async () => {
    try {
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      registerPageAccess('Convidar Informantes', {
        user_id: user.userId,
        patient_id: patientInfo.patientId,
        source: isUserPatient(user) ? 'patient' : 'caregiver',
        answered: true,
      })

      const formId = form?.lastFormId || form?.formId

      if (!formId) {
        throw new Error('Ops, algo estranho aconteceu')
      }

      navigate(`/invite_informant?form_id=${formId}`)
    } catch (error: any) {
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  return (
    <RouteLayout bgColor='green'>
      <Header hasProfileButton disabledGoHome={submitting} />
      <Box flexGrow={1} margin={5}>
        {submitting && (
          <Box display='flex' justifyContent='center'>
            <Loading />
          </Box>
        )}
        <Box my={2}>
          <Title>
            {(() => {
              if (submitting)
                return 'Sua resposta está sendo registrada, por favor aguarde e não feche essa tela.'

              if (error)
                return 'Ocorreu um erro ao registrar as resposta, por favor envie novamente'

              return 'Respostas registradas!'
            })()}
          </Title>
        </Box>

        {!(submitting || error) && (
          <Box my={2}>
            <Title>{rolesText[userRole]}</Title>
          </Box>
        )}

        <Divider color='white' />

        {submitting || error ? (
          <Box mt={5}>
            <MenuButton
              onClick={resendQuestionnaire}
              disabled={submitting}
              disableIcon
            >
              Enviar Novamente
            </MenuButton>
          </Box>
        ) : (
          !isUserInformant(user) && (
            <Box mt={5}>
              <MenuButton onClick={handleInformantInvite}>
                Convidar outras pessoas para responder
              </MenuButton>
            </Box>
          )
        )}
      </Box>

      <Box m={5}>
        {!(submitting || error) && (
          <Button
            variant='outlined'
            color='white'
            onClick={() => {
              registerTrack('Clica Voltar Inicio', {
                user_id: user.userId,
                patient_id: patientInfo.patientId,
                source: isUserPatient(user) ? 'patient' : 'caregiver',
              })
              navigate('/')
            }}
          >
            Voltar ao início
          </Button>
        )}
      </Box>
    </RouteLayout>
  )
}

export default FinishQuestionnaire

import { useState, useEffect, KeyboardEvent } from 'react'
import { RouteLayout, Header } from 'components'
import { useNavigate } from 'react-router-dom'
import { FormProvider, SubmitHandler } from 'react-hook-form'
import { Box } from '@mui/material'
import { Button } from 'components'
import { createInviteSteps } from 'components/create-invite-steps'
import { createInformantInvite } from 'services'
import { useNotification, useQuery } from 'hooks'
import useCreateInviteForm from './use-invite-new-informant-form'

function InviteNewInformant(): JSX.Element {
  const [step, setStep] = useState<number>(0)
  const navigate = useNavigate()
  const methods = useCreateInviteForm()
  const { errorToast, warnToast } = useNotification()
  const query = useQuery()

  const formId = query.get('form_id')

  useEffect(() => {
    if (!formId) {
      warnToast(
        'Você ainda não tem um questionário válido para convidar outras pessoas'
      )
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goNextStep = async () => {
    const valid = await methods.trigger(createInviteSteps[step].fields)
    if (valid && step < createInviteSteps.length - 1)
      setStep((step) => step + 1)
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    goNextStep()
  }

  const onSubmit: SubmitHandler<any> = async (params) => {
    try {
      const body: any = {
        name: params.name,
        formId: formId,
      }

      const { invitationToken }: any = await createInformantInvite(body)

      if (!invitationToken)
        throw new Error('Ocorreu um erro ao criar o convite')

      navigate('/send_invite', {
        state: { invitationToken, informantName: params.name },
      })
    } catch (error: any) {
      console.error(error)
      errorToast(error.message || 'Erro desconhecido')
    }
  }

  return (
    <FormProvider {...methods}>
      <RouteLayout bgColor='white'>
        <Box
          display='flex'
          flexDirection='column'
          height={window.innerHeight}
          component='form'
          onKeyDown={checkKeyDown}
        >
          <Header
            color
            hasGoBackButton
            handleBack={() => {
              navigate('/')
            }}
          />
          <Box flexGrow={1} mx={5} mt={3}>
            {createInviteSteps[step].component}
          </Box>
          <Box mb={7} mx={5}>
            <Button onClick={methods.handleSubmit(onSubmit)}>Gerar link</Button>
          </Box>
        </Box>
      </RouteLayout>
    </FormProvider>
  )
}

export default InviteNewInformant

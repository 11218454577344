import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const TagName = styled(Typography)<any>(({ color }: any) => {
  return {
    color: color || '#657787',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '194%',
  }
})

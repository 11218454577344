import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '32px',
  color: '#657787',
  letterSpacing: '0.04',
})

export const Description = styled(Typography)({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#657787',
  letterSpacing: '0.04',
})

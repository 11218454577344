import { useEffect, KeyboardEvent } from 'react'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Button, TextField } from 'components'
import { useQuery } from 'hooks'
import { newQuestionnairePatientStepResolver } from 'resolvers'
import { useInvitePatientContext } from 'contexts'
import { PageTitle } from './styles/steps-styles'

function PatientStep(): JSX.Element {
  const { control, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      patientName: '',
    },
    resolver: newQuestionnairePatientStepResolver,
  })
  const { goNextStep, setNewQuestionnaireInfos, newQuestionnaireInfos } =
    useInvitePatientContext()
  const query = useQuery()

  useEffect(() => {
    setValue(
      'patientName',
      newQuestionnaireInfos.patientName || query.get('patient_name') || ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: any) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      patientName: data.patientName,
    }))
    goNextStep()
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='80%'
      component='form'
      onKeyDown={checkKeyDown}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width='100%' m={5}>
        <Box>
          <PageTitle>Qual o nome do paciente?</PageTitle>
        </Box>
        <Box width='50%' my={5}>
          <TextField name='patientName' control={control} />
        </Box>
      </Box>
      <Box width='200px' height='46px' mt={10} alignSelf='flex-end'>
        <Button type='submit'>Avançar</Button>
      </Box>
    </Box>
  )
}

export default PatientStep

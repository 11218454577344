import { useNavigate, useLocation } from 'react-router-dom'
import { Avatar, IconButton, Divider, Box, Stack } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined'
// import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import { useAuthContext } from 'contexts'
import { formatUserName } from 'helpers'
import logoColor from 'images/wida-logo-color.svg'
import { Container, MenuButton } from './header-clinic-styles'
import ClinicNotifications from './clinic-notifications/clinic-notifications'

const menuOptions = [
  // {
  //   generateIcon: (color?: string) => (
  //     <EventOutlinedIcon sx={{ color: color || '#657787' }} />
  //   ),
  //   label: 'Agenda',
  //   route: '/agenda',
  // },
  {
    generateIcon: (color?: string) => (
      <PersonOutlineOutlinedIcon sx={{ color: color || '#657787' }} />
    ),
    label: 'Pacientes',
    route: '/patients',
  },
  {
    generateIcon: (color?: string) => (
      <PlaylistAddOutlinedIcon sx={{ color: color || '#657787' }} />
    ),

    label: 'Convidar',
    route: '/invite_patient',
  },
  // { icon: settingsIcon, label: 'Configurações', route: '/settings' },
]

function HeaderClinic(): JSX.Element {
  const { user } = useAuthContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const userName = formatUserName(user)

  // TODO imagem vinda do profile do usuário
  // const userAvatarUrl =
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3MMleJTsvxXrWLqg8XEeMcVgRkFu1okrTaQ&usqp=CAU'

  const goToHome = () => navigate('/')
  const goToMyProfile = () => navigate('/profile')

  return (
    <Container minWidth='945px'>
      <Box display='flex' alignItems='center'>
        <img
          src={logoColor}
          height='18px'
          alt='Logo Wida'
          onClick={goToHome}
          style={{ cursor: 'pointer' }}
        />
        <Divider
          orientation='vertical'
          sx={{ height: '38px', marginRight: '32px', marginLeft: '32px' }}
        />
        <Stack direction='row' spacing={1}>
          {menuOptions.map(({ generateIcon, label, route }) => {
            return (
              <MenuButton
                key={label}
                startIcon={generateIcon(
                  pathname.includes(route) ? '#F36F60' : '#657787'
                )}
                variant='text'
                onClick={() => navigate(route)}
                selected={pathname.includes(route)}
              >
                {label}
              </MenuButton>
            )
          })}
        </Stack>
      </Box>
      <Box display='flex' alignItems='center'>
        <ClinicNotifications />
        <Divider
          orientation='vertical'
          sx={{ height: '38px', marginRight: '8px', marginLeft: '8px' }}
        />
        <IconButton onClick={goToMyProfile}>
          <Avatar sx={{ width: 36, height: 36 }}>{userName[0] || 'W'}</Avatar>
        </IconButton>
      </Box>
    </Container>
  )
}

export default HeaderClinic

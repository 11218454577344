import { useForm } from 'react-hook-form'

function useInviteInformantForm() {
  return useForm({
    mode: 'onBlur',
    defaultValues: {
      selectedInformants: [],
    },
  })
}

export default useInviteInformantForm

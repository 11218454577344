import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import SendIcon from '@mui/icons-material/Send'
import { Button } from 'components'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import { Sidebar } from 'components/patient-central'
import {
  fetchPatient,
  getPatientAttentions,
  getPatientResponders,
} from 'services'
import { useAuthContext, PatientCentralContext } from 'contexts'
import { useNotification } from 'hooks'
import { registerTrack } from 'helpers'
import { IPatientCentralLayoutProps } from './patient-central-layout-types'
import { Container, MenuButton } from './patient-central-layout-styles'

const menuOptions: any = [
  {
    generateIcon: (color?: string) => (
      <PersonOutlineOutlinedIcon sx={{ color: color || '#657787' }} />
    ),
    label: 'Perfil',
    route: '/profile',
  },
  {
    generateIcon: (color?: string) => (
      <AssignmentOutlinedIcon sx={{ color: color || '#657787' }} />
    ),

    label: 'Prontuário',
    route: '/patient_records',
  },
  {
    generateIcon: (color?: string) => (
      <SignalCellularAltOutlinedIcon sx={{ color: color || '#657787' }} />
    ),

    label: 'Resultados',
    route: '/results',
  },
]

function PatientCentralLayout({
  children,
  parentPatientAttentions = null,
  refetchPatientAttentions = null,
  sidebarSize = 'normal',
}: IPatientCentralLayoutProps): JSX.Element {
  const [patient, setPatient] = useState<any>(null)
  const [disabledResults, setDisabledResults] = useState(true)
  const [patientAttentions, setPatientAttentions] = useState<any>(
    parentPatientAttentions || []
  )

  useEffect(() => {
    if (!parentPatientAttentions) return

    setPatientAttentions(parentPatientAttentions)
  }, [parentPatientAttentions])

  const { user } = useAuthContext()
  const { pathname } = useLocation()
  const { patient_id } = useParams()
  const navigate = useNavigate()
  const { errorToast } = useNotification()

  const trackUsersInfosPayload = useMemo(
    () => ({
      clinic_id: user.clinicId,
      patient_id: patient_id,
    }),
    [user, patient_id]
  )

  const fetchPatientAttentions = useCallback(async () => {
    try {
      if (!patient_id) return

      const patientAttentions = await getPatientAttentions(patient_id)
      setPatientAttentions(patientAttentions)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [patient_id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const patient = await fetchPatient(patient_id || '')
        const responders = await getPatientResponders(patient_id as string)

        setPatient(patient)
        setDisabledResults(!Boolean(responders.length))
      } catch (error: any) {
        console.error(error.message)
        errorToast(error.message || 'Erro desconhecido')
        navigate('/')
      }
    }

    fetchData()
    fetchPatientAttentions()
  }, [errorToast, navigate, patient_id, fetchPatientAttentions])

  const handleNewQuestionnaire = () => {
    if (!patient) return

    const patientEmail = patient.email
    const patientName = `${patient.firstName} ${patient.lastName}`
    const lastFormId = patient.lastFormId

    registerTrack('Clica Novo Questionario', {
      ...trackUsersInfosPayload,
      source: 'results',
    })

    navigate(
      `/invite_patient?email=${patientEmail
        .replaceAll('#', '%23')
        .replaceAll(
          '+',
          '%2B'
        )}&last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patient_id}`
    )
  }

  return (
    <PatientCentralContext.Provider
      value={{
        patientAttentions,
        fetchPatientAttentions: refetchPatientAttentions
          ? refetchPatientAttentions
          : fetchPatientAttentions,
        patient,
      }}
    >
      <Container display='flex'>
        <Sidebar patient={patient} size={sidebarSize} />
        <Box width='100%'>
          <Box
            width='100%'
            borderBottom='1px solid #D9D9D9'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            height='56px'
            px={2}
          >
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              height='100%'
            >
              {menuOptions.map(({ generateIcon, label, route }: any) => (
                <MenuButton
                  key={label}
                  startIcon={generateIcon(
                    pathname.includes(route) ? '#F36F60' : '#657787'
                  )}
                  variant='text'
                  onClick={() => navigate(`/patients/${patient_id}${route}`)}
                  selected={pathname.includes(route)}
                  disabled={label === 'Resultados' && disabledResults}
                >
                  {label}
                </MenuButton>
              ))}
            </Stack>
            <Box width='220px' display='flex' alignItems='center' height='100%'>
              <Button
                variant='contained'
                style={{ fontSize: '14px', height: '36px' }}
                onClick={handleNewQuestionnaire}
              >
                <SendIcon sx={{ marginRight: '8px', height: '18px' }} />
                Enviar questionário
              </Button>
            </Box>
          </Box>
          <Box py={2} px={2} display='flex' flexDirection='column' flexGrow={1}>
            {children}
          </Box>
        </Box>
      </Container>
    </PatientCentralContext.Provider>
  )
}

export default PatientCentralLayout

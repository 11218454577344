import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const LogoAnimation = styled(Box)({
  background: 'linear-gradient(300deg, #A8ACCF, #A9B1FA, #D28EF2, #8B7CE7)',
  backgroundSize: '500% 500%',
  animation: 'gradient 12s ease infinite',
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  }
})

import { useEffect, useState, useRef } from 'react'
import {
  FilterTextField,
  Question,
  SearchBox,
  TagGroup,
  WarningBox,
} from './tags-filter-styles'
import { InputAdornment, Box } from '@mui/material'
import { getQuestions } from 'services'
import { Search } from '@mui/icons-material'
import { capitalizeWordsOnSentence, findInWord, orderArray } from 'helpers'
import { useDebounce } from 'hooks'

interface TFilterProps {
  setSelectedTag: (arg: any) => void
  tags: any[]
}

// TODO mudar para regra de volta e bloqueio de steps serem definidos por props para ficar genérico para reuso

function TagsFilter({ tags, setSelectedTag }: TFilterProps): JSX.Element {
  const [list, setList] = useState<any>([])
  const [filteredList, setFilteredList] = useState<any>([])
  const [showList, setShowList] = useState<boolean>(false)
  const inputRef = useRef<any>(null)

  // debounce search
  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearch = useDebounce(searchTerm, 500)

  useEffect(() => {
    const fetchData = async () => {
      const questions = await getQuestions()
      const activeQuestions = questions.filter(({ isActive }: any) => isActive)

      // build list
      const tagsWithQuestions = []
      for (const tag of tags) {
        const tagQuestions: any = []
        activeQuestions.forEach((question: any) => {
          if (question.tags.includes(tag.name)) {
            tagQuestions.push(question)
          }
        })
        delete tag.formTags
        tagsWithQuestions.push({ ...tag, questions: tagQuestions })
      }
      setList(orderArray(tagsWithQuestions, 'name'))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  useEffect(() => {
    const filter = (filter: string = '') => {
      if (filter) {
        return setFilteredList(
          list
            .map((tag: any) => {
              const fQuestions = tag.questions.filter((question: any) => {
                return findInWord(question.questionSelfM, filter)
              })
              return { ...tag, questions: fQuestions }
            })
            .filter((tag: any) => {
              return tag.questions.length
            })
        )
      }
      return setFilteredList(list)
    }

    filter(debounceSearch)
  }, [debounceSearch, list])

  const handleTagClick = ({ name, id }: any) => {
    inputRef.current.blur()
    setSelectedTag({ id, name, value: name, expanded: true })
  }

  return (
    <Box width={'350px'}>
      <FilterTextField
        inputRef={inputRef}
        onChange={(e) => setSearchTerm(e.target.value)}
        type='text'
        size='small'
        autoComplete={'off'}
        hiddenLabel
        onFocus={() => setShowList(true)}
        onBlur={() => setShowList(false)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search style={{ fill: '#BDBDBD' }} />
            </InputAdornment>
          ),
          placeholder: 'Pesquisar',
        }}
      />
      {!!searchTerm.length && searchTerm.length < 3 && (
        <WarningBox px={2}>
          <Box py={1}>
            <Question p={'2px'}>Mínimo 3 caracteres</Question>
          </Box>
        </WarningBox>
      )}
      {showList && debounceSearch.length > 2 && (
        <>
          {!!filteredList.length ? (
            <SearchBox px={2}>
              {filteredList.map((tag: any) => {
                return (
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => handleTagClick(tag)}
                    key={tag.id}
                    py={1}
                  >
                    <TagGroup py={'2px'} px={'5px'} my={'10px'}>
                      {capitalizeWordsOnSentence(tag.name)}
                    </TagGroup>
                    {tag.questions.map((question: any) => (
                      <Question key={question.id} p={'2px'}>
                        {question.questionSelfM}
                      </Question>
                    ))}
                  </Box>
                )
              })}
            </SearchBox>
          ) : (
            <WarningBox px={2}>
              <Box py={1}>
                <Question p={'2px'}>Nenhum resultado</Question>
              </Box>
            </WarningBox>
          )}
        </>
      )}
    </Box>
  )
}

export default TagsFilter

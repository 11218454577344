import { Controller, Control } from 'react-hook-form'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'

interface DateFieldProps {
  name: string
  control: Control<any>
  label?: string
  disabled?: boolean
  placeholder?: string
}

function DateField({
  name,
  control,
  label = '',
  disabled,
  placeholder = 'Digite aqui',
}: DateFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <DatePicker
          value={value}
          onChange={onChange}
          cancelText='Cancelar'
          clearText='Limpar'
          toolbarTitle='Selecione uma data'
          todayText='Hoje'
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label={label}
              fullWidth
              placeholder={placeholder}
              color='secondary'
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  )
}

export default DateField

import { Controller, Control } from 'react-hook-form'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import { SwitchContainer, SwitchButton } from './switch-graphic-type-styles'

interface SwitchGraphicTypeProps {
  name: string
  control: Control<any>
}

const SwitchGraphicType = ({ name, control }: SwitchGraphicTypeProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <SwitchContainer>
          <SwitchButton
            isActive={value === 'transv'}
            onClick={() => onChange('transv')}
          >
            <GridViewRoundedIcon sx={{ marginRight: '4px' }} />
            Último
          </SwitchButton>

          <SwitchButton
            isActive={value === 'long'}
            onClick={() => onChange('long')}
          >
            <TimelineRoundedIcon sx={{ marginRight: '4px' }} />
            Histórico
          </SwitchButton>
        </SwitchContainer>
      )}
    />
  )
}

export default SwitchGraphicType

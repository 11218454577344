import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface QuestionButtonOptionType {
  component: JSX.Element
  bgColor: string
  label: string
}

interface OptionsMapType {
  [key: string]: QuestionButtonOptionType
}

const optionsMap: OptionsMapType = {
  no: {
    component: <CloseIcon sx={{ fill: 'white' }} />,
    bgColor: 'primary.main',
    label: 'Não',
  },
  'so-so': {
    component: <></>,
    bgColor: '#9D9CB5',
    label: 'Mais ou menos',
  },
  yes: {
    component: <CheckIcon sx={{ fill: 'white' }} />,
    bgColor: 'secondary.main',
    label: 'Sim',
  },
}

export default optionsMap

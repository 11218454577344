import { Controller, Control } from 'react-hook-form'
import {
  MenuItem,
  FormControl,
  Select as SelectMui,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material'

interface OptionType {
  value: string
  label: string
}

interface SelectProps {
  name: string
  control: Control<any>
  options: OptionType[]
  placeholder?: string
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  label?: string
}

function SelectObj({
  name,
  control,
  placeholder = 'Selecione uma opção',
  options,
  variant = 'standard',
  label = '',
}: SelectProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl
            color='secondary'
            variant={variant}
            fullWidth
            error={invalid}
          >
            {label && <InputLabel id={name}>{label}</InputLabel>}
            <SelectMui
              {...(label && { labelId: name })}
              {...(label && { input: <OutlinedInput label={label} /> })}
              value={value || ''}
              onChange={onChange}
              displayEmpty
              color='secondary'
              renderValue={(selected: string) => {
                if (!selected) return label ? '' : placeholder

                const selectedObj = JSON.parse(selected)
                const findOption = options.find(
                  (option: OptionType) => selectedObj.value === option.value
                )

                return findOption?.label || (label ? '' : placeholder)
              }}
            >
              {options.map((option: any) => {
                return (
                  <MenuItem key={option.value} value={JSON.stringify(option)}>
                    {option.label}
                  </MenuItem>
                )
              })}
            </SelectMui>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

export default SelectObj

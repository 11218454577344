import { TextField, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FilterTextField = styled(TextField)({
  width: '100%',
  '& fieldset': {
    borderRadius: '4px',
    border: '1px solid #ECECEC',
  },
  '& input::placeholder': {
    color: '#BDBDBD',
  },
})

export const SearchBox = styled(Box)({
  fontSize: '14px',
  zIndex: 100,
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  color: '#657787',
  width: '350px',
  height: '500px',
  boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.08)',
  position: 'absolute',
})

export const WarningBox = styled(Box)({
  fontSize: '14px',
  zIndex: 100,
  backgroundColor: '#FFFFFF',
  color: '#657787',
  width: '350px',
  boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.08)',
  position: 'absolute',
})

export const TagGroup = styled(Box)({
  width: 'fit-content',
  color: '#FFF',
  backgroundColor: '#01B3A7',
  borderRadius: '4px',
  cursor: 'pointer',
})

export const Question = styled(Box)({
  cursor: 'pointer',
  borderRadius: '4px',
  transition: '0.6s',
  '&:hover': {
    opacity: 0.7,
    color: '#F36F60',
  },
})

import { useState, useEffect, useRef, useMemo, KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'
import {
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Divider,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { ExpandMore, InfoOutlined } from '@mui/icons-material'
import { Button, SelectQuestions } from 'components'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { useNotification, useQuery } from 'hooks'
import { getQuestions, getLastForm } from 'services'
import { newQuestionnaireQuestionsStepResolver } from 'resolvers'
import {
  capitalizeWordsOnSentence,
  extractTagsFromQuestions,
  filterMultipleTags,
  orderArray,
  registerPageAccess,
  registerTrack,
} from 'helpers'
import { PageTitle } from './styles/steps-styles'

function QuestionStep(): JSX.Element {
  const [tags, setTags] = useState<any>([])
  const [questions, setQuestions] = useState<any>([])
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [shownTags, setShownTags] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [hasDuplicatedQuestions, setHasDuplicatedQuestions] = useState(false)
  const selectedTagsValues = selectedTags?.map((tag: any) => tag.value) || []
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      selectedQuestions: [],
    },
    resolver: newQuestionnaireQuestionsStepResolver,
  })

  const {
    goNextStep,
    setNewQuestionnaireInfos,
    newQuestionnaireInfos,
    selectedTag,
    setSelectedTag,
  } = useInvitePatientContext()
  const tagsRef: any = useRef([])
  const query = useQuery()
  const { errorToast } = useNotification()
  const { user } = useAuthContext()

  const selectedQuestions = watch('selectedQuestions')
  const counter = useMemo(() => selectedQuestions.length, [selectedQuestions])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questions = await getQuestions()

        const activeQuestions = questions.filter(
          ({ isActive }: any) => isActive
        )

        if (!activeQuestions)
          throw new Error('Ocorreu um erro ao buscar as perguntas')

        setTags(orderArray(extractTagsFromQuestions(activeQuestions), 'label'))
        setQuestions(activeQuestions)

        const lastFormId = query.get('last_form_id')

        const hasMemorizedSelectedQuestions = Boolean(
          newQuestionnaireInfos.selectedQuestions.length
        )

        if (hasMemorizedSelectedQuestions) {
          setValue('selectedQuestions', newQuestionnaireInfos.selectedQuestions)
          if (Boolean(newQuestionnaireInfos.selectedTags?.length)) {
            setSelectedTags(newQuestionnaireInfos.selectedTags)
          } else {
            setSelectedTags(
              extractTagsFromQuestions(newQuestionnaireInfos.selectedQuestions)
            )
          }
          return
        }

        if (lastFormId) {
          const { questions: lastFormQuestions, tags: lastFormTags } =
            await getLastForm(lastFormId)

          if (!lastFormQuestions) return

          const activeLastFormQuestions = lastFormQuestions.filter(
            ({ isActive }: any) => isActive
          )

          if (lastFormTags?.length === 0) {
            const tagsFromQuestions = extractTagsFromQuestions(
              activeLastFormQuestions
            )

            const filteredTags = filterMultipleTags(
              tagsFromQuestions,
              activeLastFormQuestions
            )

            setSelectedTags(filteredTags)
          } else {
            const lastSelectedTags = lastFormTags.map((lastFormTag: any) => {
              const total = activeQuestions.filter((question: any) =>
                question.tags.includes(lastFormTag.tag)
              ).length

              return {
                value: lastFormTag.tag,
                label: `${lastFormTag.tag} (${total})`,
                name: lastFormTag.tag,
                total: total,
              }
            })
            const lastSelectedTagsNames = lastSelectedTags?.map(
              (tag: any) => tag.value
            )
            setSelectedTags(lastSelectedTags)

            activeLastFormQuestions.forEach((question: any) => {
              for (const tag of question.tags) {
                if (lastSelectedTagsNames.includes(tag)) {
                  question.tag = lastSelectedTags.find(
                    (t: any) => t.value === tag
                  )
                }
              }
            })
          }
          setValue('selectedQuestions', activeLastFormQuestions)
        }
      } catch (error: any) {
        console.error(error)
        errorToast(error.message)
      } finally {
        setSelectedTags((prevTags: any) =>
          orderArray(prevTags, 'name').map((t: any) => ({
            ...t,
            expanded: false,
          }))
        )
        setLoading(false)
      }
    }
    registerPageAccess('Selecao de Perguntas', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
    })

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errors?.selectedQuestions) errorToast(errors.selectedQuestions.message)
  }, [errors.selectedQuestions, errorToast])

  useEffect(() => {
    setShownTags(orderArray(selectedTags, 'name'))
  }, [selectedTags])

  useEffect(() => {
    if (selectedTag) {
      const tagChecked = selectedTags.some(
        (tag: any) => tag.value === selectedTag.value
      )

      if (tagChecked) {
        const newTags = selectedTags.filter(
          (t: any) => t.value !== selectedTag.value
        )
        setShownTags(orderArray([selectedTag, ...newTags], 'name'))
      } else {
        setShownTags(orderArray([selectedTag, ...selectedTags], 'name'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag])

  useEffect(() => {
    const hasDupQuestions = !!selectedQuestions.filter((q: any) => {
      return (
        q.tags.filter((t: any) => selectedTagsValues.includes(t)).length > 1
      )
    }).length

    return setHasDuplicatedQuestions(hasDupQuestions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestions])

  useEffect(() => {
    if (selectedTag) {
      setTimeout(() => {
        scrollToTag(selectedTag.value)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownTags])

  const onSubmit = (data: any) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      selectedQuestions: data.selectedQuestions,
      selectedTags,
      sendTo: query.get('email'),
    }))

    goNextStep()
  }

  const scrollToTag = (tagValue: string) => {
    const tagIndex = shownTags.map((e: any) => e.value).indexOf(tagValue)
    tagsRef.current[tagIndex]?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const handleTagByQuestion = (question: any, checked: any) => {
    const { tag } = question
    registerTrack(`${checked ? 'Adiciona' : 'Remove'} Pergunta`, {
      identifier: question.identifier,
      patient_id: query.get('patient_id'),
      value: checked,
    })
    const newSelectedQuestions = checked
      ? [...selectedQuestions, question]
      : selectedQuestions.filter(
          (oldQuestion: any) => oldQuestion.id !== question.id
        )

    setValue('selectedQuestions', newSelectedQuestions)

    const isChecked = selectedTags.some((sTag: any) => tag.value === sTag.value)

    const tagQuestions = newSelectedQuestions.filter((question: any) => {
      question.tag ??= tag
      return (
        question.tags.includes(tag.value) && question.tag.value === tag.value
      )
    }).length

    if (isChecked && tagQuestions === 0) {
      const newTags = selectedTags.filter((t: any) => t.value !== tag.value)
      setSelectedTags(newTags)
      return setSelectedTag({ ...selectedTag })
    } else if (!isChecked) {
      setSelectedTags((tags: any) => orderArray([...tags, tag], 'name'))
    }
    if (selectedTag?.value !== tag.value) {
      setSelectedTag(tag)
    }
  }

  const handleSelectTagQuestions = (tag: any) => {
    const tagQuestions = questions
      .filter(({ id, tags }: any) => {
        return (
          tags.some((tagName: any) => tagName === tag.value) &&
          !selectedQuestions.some((question: any) => question.id === id)
        )
      })
      .map((question: any) => ({ ...question, tag }))

    setValue('selectedQuestions', [...selectedQuestions, ...tagQuestions])
  }

  const handleRemoveTag = (tag: any) => {
    const idsToRemove = selectedQuestions
      .filter((question: any) => {
        question.tag ??= tag
        return (
          question.tags.includes(tag.value) && question.tag.value === tag.value
        )
      })
      .map((question: any) => question.id)

    // move duplicated questions to another tag
    const changedQuestions = selectedQuestions
      .filter((question: any) => {
        question.tag ??= tag
        return (
          question.tags.includes(tag.value) && question.tag.value === tag.value
        )
      })
      .filter((q: any) => {
        for (const qT of q.tags) {
          if (qT !== tag.value && selectedTagsValues.includes(qT)) {
            q.tag = tags.find((t: any) => t.value === qT)
            return true
          }
        }
        return false
      })

    const newQuestions = selectedQuestions
      .filter((question: any) => !idsToRemove.includes(question.id))
      .concat(changedQuestions)

    setValue('selectedQuestions', newQuestions)
    if (tag.value === selectedTag?.value) {
      setSelectedTag(null)
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='space-between'
      justifyContent='space-between'
      height='100%'
      mb={5}
      component='form'
      onKeyDown={checkKeyDown}
      onSubmit={handleSubmit(onSubmit)}
    >
      {loading ? (
        <Box
          flexGrow={1}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          {/*
           *
           * Tags
           *
           */}

          <Box py={3} width={{ xs: '100%', md: '25%' }}>
            <PageTitle sx={{ paddingX: 5 }}>Domínios</PageTitle>
            <Box display='flex' flexDirection='column'>
              <Box display={'flex'} flexDirection={'column'}>
                {tags.map((tag: any) => {
                  const isSelected = selectedTag?.value === tag.value

                  const isChecked = selectedTags.some(
                    (sTag: any) => tag.value === sTag.value
                  )

                  function handleTagClick() {
                    setSelectedTag({ ...tag, expanded: true })
                  }

                  return (
                    <Box
                      px={3}
                      key={tag.value}
                      onClick={handleTagClick}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: isSelected ? '#F9F9FB' : 'transparent',
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        sx={{
                          px: 3,
                          py: 1,
                          backgroundColor: isChecked ? '#FFF7F9' : '#F9F9FB',
                          border: `1px solid ${
                            isChecked ? '#F36F60' : '#E3E3E3'
                          }`,
                          borderRadius: '5px',
                          margin: '10px 15px',
                          wordBreak: 'break-word',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Zen Kaku Gothic Antique',
                          }}
                        >
                          {capitalizeWordsOnSentence(tag.name)}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
          <Divider orientation='vertical' sx={{ height: '70vh' }} />

          {/*
           *
           * Questions
           *
           */}

          <Box
            px={5}
            py={3}
            width={{ xs: '100%', md: '75%' }}
            display='flex'
            flexDirection='column'
            height='100%'
            justifyContent={selectedTag ? 'space-between' : 'flex-end'}
          >
            <PageTitle sx={{ paddingX: 2 }}>Seu questionário</PageTitle>
            {!shownTags.length && (
              <Box px={2}>
                <Typography>
                  Utilize o menu à esquerda para ver as perguntas de seu
                  interesse.
                </Typography>
                <Typography>
                  Você pode incluir todas ou selecionar perguntas individuais
                  conforme o perfil de seu paciente.
                </Typography>
              </Box>
            )}
            <Box height='70vh' overflow='auto'>
              {/* Selected Questions */}
              {shownTags?.map((tag: any, i: number) => {
                const tagQuestions = questions.filter((question: any) =>
                  question.tags.includes(tag.value)
                )
                const questionsSelected = selectedQuestions.filter(
                  (question: any) => question.tags.includes(tag.value)
                ).length

                const hasQuestions = questionsSelected > 0
                const isSelected = !!selectedTags.find(
                  (t: any) => t.value === tag.value
                )

                const isIndeterminate =
                  isSelected &&
                  hasQuestions &&
                  tagQuestions.length !== questionsSelected

                const onExpand = (e: any, expanded: boolean) => {
                  setShownTags((prev: any) => {
                    const newTag = prev.find((t: any) => t.value === tag.value)
                    newTag.expanded = expanded
                    return [...prev]
                  })
                }

                const handleSelectClick = (e: any) => {
                  const { checked } = e.target
                  registerTrack(`${checked ? 'Adiciona' : 'Remove'} Dominio`, {
                    clinic_id: user.clinicId,
                    patient_id: query.get('patient_id'),
                    domain_name: tag.value,
                  })
                  if (checked) {
                    setSelectedTag(tag)
                    setSelectedTags((tags: any) =>
                      orderArray([...tags, tag], 'name')
                    )
                    handleSelectTagQuestions(tag)
                  } else {
                    setSelectedTags((tags: any) =>
                      tags.filter((t: any) => t.value !== tag.value)
                    )
                    handleRemoveTag(tag)
                  }
                }

                return (
                  <Box ref={(el) => (tagsRef.current[i] = el)} key={i}>
                    <Box px={1} display={'flex'} alignItems={'start'}>
                      <Checkbox
                        checked={isSelected}
                        indeterminate={isIndeterminate}
                        onChange={handleSelectClick}
                        sx={{ marginY: '3px', p: '8px' }}
                      />
                      <Accordion
                        disableGutters
                        expanded={tag.expanded || false}
                        onChange={onExpand}
                        sx={{
                          boxShadow: 'none',
                          '.MuiCollapse-root': { transition: 'none' },
                          '.MuiAccordionSummary-expandIconWrapper': {},
                          '&::before': { content: 'none' },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          sx={{
                            p: 0,
                            justifyContent: 'start',
                            '.MuiAccordionSummary-content': {
                              flexGrow: 0,
                              mr: 1,
                            },
                            svg: {
                              transform: 'rotate(270deg)',
                            },
                            '.Mui-expanded svg': {
                              transform: 'rotate(180deg)',
                            },
                          }}
                        >
                          <Typography>
                            {`${capitalizeWordsOnSentence(tag.name)} ${
                              selectedTagsValues.includes(tag.value) &&
                              questionsSelected > 0
                                ? `(${questionsSelected})`
                                : ''
                            }`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, pb: 2 }}>
                          <SelectQuestions
                            selectedTag={tag}
                            handleTagByQuestion={handleTagByQuestion}
                            questions={tagQuestions}
                            selectedQuestions={selectedQuestions}
                            name='selectedQuestions'
                            control={control}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='end'
            position={'fixed'}
            right={100}
            bottom={30}
          >
            <Box width='270px' height='46px' mt={4}>
              <Button type='submit' disabled={counter < 1}>
                {`Avançar ${
                  counter
                    ? `com ${counter} pergunta${counter < 2 ? '' : 's'}`
                    : ''
                }`}
                {hasDuplicatedQuestions && (
                  <Tooltip
                    title='Existem perguntas em comum entre os domínios selecionados.'
                    arrow
                  >
                    <InfoOutlined
                      fontSize='small'
                      sx={{ ml: 1, color: '#FFF' }}
                    />
                  </Tooltip>
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default QuestionStep

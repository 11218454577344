import { Fragment } from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { answerOptions } from 'helpers'
import { useDashboardContext } from 'contexts'
import { tagsColor } from 'helpers/constants'
import { TagName } from './list-row-styles'
import ProportionGraphic from './proportion-graphic'

interface IListRowProps {
  tag: string
  tagQuestions: any[]
  pillQuestions: any[]
  filteredForms: any[]
  expandState: boolean
  changeExpandState: () => void
}

function ListRow({
  tag,
  tagQuestions,
  pillQuestions,
  filteredForms,
  expandState,
  changeExpandState,
}: IListRowProps) {
  const { patient } = useDashboardContext()

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={changeExpandState}
          >
            {expandState ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          <TagName as='span' color={tagsColor[tag]}>
            {(tag || '').toUpperCase()}
          </TagName>
        </TableCell>
        {filteredForms.map((form: any) => {
          const formTagQuestions = form.questions.filter(({ tags }: any) =>
            tags.includes(tag)
          )

          return (
            <TableCell
              key={form.formRequestId}
              width='120px'
              sx={{ minWidth: '120px', maxWidth: '120px' }}
            >
              {Boolean(formTagQuestions.length) && (
                <ProportionGraphic
                  barColor={tagsColor[tag]}
                  questions={formTagQuestions}
                  totalTagQuestions={pillQuestions.length}
                />
              )}
            </TableCell>
          )
        })}
      </TableRow>
      {expandState &&
        tagQuestions.map((question: any) => (
          <TableRow key={question.id}>
            <TableCell width='40px' />
            <TableCell width='500px'>
              {(patient.pronoun === 'SHE'
                ? question.shortVersionF
                : question.shortVersionM) ||
                question.ultraShortVersion ||
                ''}
            </TableCell>

            {filteredForms.map((form: any) => {
              const findQuestions = form.questions.find(
                (formQuestion: any) => formQuestion.id === question.id
              )

              return (
                <TableCell
                  key={`${question.id}:${form.formRequestId}`}
                  width='120px'
                  sx={{ minWidth: '120px', maxWidth: '120px' }}
                  align='left'
                >
                  {findQuestions?.answer ? (
                    <img
                      src={answerOptions[findQuestions.answer].svg as string}
                      height='18px'
                      alt={answerOptions[findQuestions.answer].alt}
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
    </Fragment>
  )
}

export default ListRow

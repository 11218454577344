import ButtonMui, { ButtonProps } from '@mui/material/Button'
import { defaultMenuButtonStyle } from './menu-button-styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface IMenuButtonProps {
  disableIcon?: boolean
}

function MenuButton(props: ButtonProps & IMenuButtonProps) {
  return (
    <ButtonMui
      variant={props.variant || 'contained'}
      fullWidth
      sx={{
        ...defaultMenuButtonStyle,
        ...props.sx,
      }}
      {...(!props.disableIcon && { endIcon: <ArrowForwardIosIcon /> })}
      {...props}
    />
  )
}

export default MenuButton

import { styled } from '@mui/material/styles'
import { Box, TableCell, TableRow, TextField } from '@mui/material'

export const BoxCell = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#657787',
  fontFamily: "'DM Sans', sans-serif",
  fontWeight: 400,
  backgroundColor: '#F9F9FB',
  padding: '16px',
  lineHeight: '14px',
  height: '100%',
  width: '100%',
})

export const StartBoxCell = styled(BoxCell)({
  borderBottom: '1px solid #ECECEC',
  borderLeft: '1px solid #ECECEC',
  borderRadius: '0 0 0 4px',
})

export const MiddleBoxCell = styled(BoxCell)({
  borderBottom: '1px solid #ECECEC'
})

export const EndBoxCell = styled(BoxCell)({
  borderBottom: '1px solid #ECECEC',
  borderRight: '1px solid #ECECEC',
  borderRadius: '0 0 4px 0',
})

export const StyledTableCell = styled(TableCell)({
  border: 'none',
  height: '0px',
  padding: '0'
})

export const HeaderTableRow = styled(TableRow)({
  '& th': {
    borderCollapse: 'separate',
    borderSpacing: '0 200px'
  }
})

export const FilterTextField = styled(TextField)({
  width: '100%',
  '& fieldset': {
    borderRadius: '4px 4px 0 0',
    border: '1px solid #ECECEC',
  },
  '& input::placeholder': {
    color: '#BDBDBD',
  }
})

import { useState, MouseEvent } from 'react'
import { IconButton, ClickAwayListener } from '@mui/material'
import Popper from '@mui/material/Popper'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { RespondersMenu } from 'components/results'

function AddResponderMenu({ handleAddResponder, menuOptions }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick: any = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const handleSelectOption = (value: any) => {
    setAnchorEl(null)
    handleAddResponder(value)
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <StyledIconButton onClick={handleClick}>
          <AddIcon sx={{ color: '#FE8353', height: '12px', width: '12px' }} />
        </StyledIconButton>
        <Popper
          id={open ? 'add-responder-menu' : undefined}
          open={open}
          anchorEl={anchorEl}
        >
          <RespondersMenu
            menuOptions={menuOptions}
            handleSelect={handleSelectOption}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

const StyledIconButton = styled(IconButton)({
  width: '18px',
  height: '18px',
  border: '1px solid #FE8353',
  borderRadius: 0,
  marginLeft: '8px',
})

export default AddResponderMenu

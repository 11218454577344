import { useState, MouseEvent } from 'react'
import { ClickAwayListener, ButtonBase, Popper } from '@mui/material'
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded'
import { styled } from '@mui/material/styles'
import { RespondersMenu } from 'components/results'
import { useNotification } from 'hooks'

function NewChangeResponderMenu({
  handleChangeResponder,
  menuOptions,
  abbreviated,
  oldResponder,
  disabled = false,
}: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { infoToast } = useNotification()

  const handleClick: any = (event: MouseEvent<HTMLElement>) => {
    if (disabled) infoToast('Sem resultados de outros informantes')

    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const handleSelectOption = ({ newResponderId }: any) => {
    setAnchorEl(null)

    const findOption = menuOptions.find(
      (menuOption: any) => menuOption.id === newResponderId
    )

    handleChangeResponder({
      newResponderId,
      oldResponderId: oldResponder.id,
      date: findOption?.responseDates.slice(0, 4),
    })
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <MenuButton onClick={handleClick} abbreviated={abbreviated}>
          <SwapHorizRoundedIcon
            sx={{ height: '20px', marginRight: abbreviated ? 0 : '4px' }}
          />
          {!abbreviated && 'Alterar informante'}
        </MenuButton>
        <Popper
          id={open ? 'add-responder-menu' : undefined}
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 3 }}
        >
          <RespondersMenu
            menuOptions={menuOptions.map(
              ({ responseDates, ...rest }: any) => rest
            )}
            handleSelect={handleSelectOption}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

const MenuButton = styled(ButtonBase)<any>(({ abbreviated }) => ({
  background: '#404040',
  color: '#fff',
  height: '26px',
  width: abbreviated ? '40px' : '180px',
  borderRadius: '8px',
}))

export default NewChangeResponderMenu
